import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  label,
  placeholder = "Enter text here...",
  value = "",
  onChange = () => {},
  align = false,
  className,
  containerClassName = "gap-2",
}) => {
  const id = label?.replace(" ", "-").toLowerCase();

  const handleEditorChange = (html) => {
    onChange(html);
  };

  return (
    <div className={`relative flex flex-col ${containerClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className="capitalize font-medium text-sm"
          style={align ? { textAlign: "center" } : {}}
        >
          {label}
        </label>
      )}

      <ReactQuill
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleEditorChange}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        className={`bg-offWhite ${className}`}
      />
    </div>
  );
};

TextEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

TextEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default TextEditor;
