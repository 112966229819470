import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import { RiRefund2Fill } from "react-icons/ri";

const Return = () => {
  return (
    <section className="grid grid-cols-2 gap-4">
      <div className="RETURN-REQUEST bg-white rounded-xl shadow-pop">
        <div className="flex items-center gap-2 py-4 px-6 border-b-2 border-secondary">
          <RiRefund2Fill />
          <p className="font-medium">Return request after order within</p>
        </div>
        <div className="py-4 px-6 space-y-4">
          <InputBox label="days" name="days" />
          <div className="w-max ml-auto">
            <Button>save</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Return;
