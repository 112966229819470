import AppleStore from "./AppleStore";
import GooglePlay from "./GooglePlay";

const AppSettings = () => {
  return (
    <section className="grid grid-cols-2 gap-4">
      <AppleStore />
      <GooglePlay />
    </section>
  );
};

export default AppSettings;
