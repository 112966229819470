import { Link } from "react-router-dom";

const Details = ({ product }) => {
  return (
    <section className="w-full">
      {product?.productGroup?.brand?.name && (
        <Link
          to={`/brand/${product?.productGroup?.brand?._id}`}
          className="BRAND-NAME capitalize font-medium"
        >
          {product?.productGroup?.brand?.name}
        </Link>
      )}

      <h1 className="PRODUCT-NAME text-xl max-sm:text-lg font-secondary font-medium mb-1">
        {product.name}
        {product.quantity <= 0 && (
          <span className="bg-lightGray p-1 ml-4 font-bold text-error">
            SOLD OUT
          </span>
        )}
      </h1>

      <div className="PRICE-TAG mt-4 flex gap-2 items-center font-medium text-lg">
        <p className="line-through text-primary">₹ {product?.unitPrice}</p>
        <p className="font-bold text-2xl">₹ {product?.sellingPrice}</p>
        {product?.discount > 0 && (
          <p className="text-hotPink font-bold">
            {product?.discountType === "percent"
              ? `${product.discount}% OFF`
              : `FLAT ₹${product.discount} OFF`}
          </p>
        )}
      </div>

      <div className="UTSAV-PRICE-TAG mb-4 flex gap-2 items-center font-medium text-lg text-primeRed">
        <p>UTSAV PRICE</p>
        <p className="font-bold text-2xl">₹ {product?.utsavPrice}</p>
      </div>
    </section>
  );
};

export default Details;
