import { useState, useCallback } from "react";
import {
  createTemplate,
  deleteTemplate,
  updateTemplate,
} from "../api/giftCardApis";

const useGiftCard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addTemplate = useCallback(async (templateData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await createTemplate(templateData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("giftCardTemplates");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const editTemplate = useCallback(async (templateId, templateData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateTemplate(templateId, templateData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("giftCardTemplates");
        sessionStorage.removeItem(`giftCardTemplate_${templateId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeTemplate = useCallback(async (templateId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await deleteTemplate(templateId);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("giftCardTemplates");
        sessionStorage.removeItem(`giftCardTemplate_${templateId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    addTemplate,
    editTemplate,
    removeTemplate,
    loading,
    error,
  };
};

export default useGiftCard;
