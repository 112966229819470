import { useCallback, useEffect, useState } from "react";
import { getMembershipPlan, updateMembershipPlan } from "../api/utsavApis";

const useMemberPlan = () => {
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPlan = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const cacheKey = "membershipPlan";
      const cachedData = sessionStorage.getItem(cacheKey);
      if (cachedData) {
        setPlan(JSON.parse(cachedData));
      } else {
        const response = await getMembershipPlan();

        if (response?.status) {
          console.log(response.data);
          sessionStorage.setItem(
            cacheKey,
            JSON.stringify(response.data.planDetails)
          );
          setPlan(response.data.planDetails);
        }
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const editPlan = useCallback(async (planData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateMembershipPlan(planData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("membershipPlan");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  return { plan, editPlan, loading, error };
};

export default useMemberPlan;
