import { keepPreviousData, useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getAllOrders, downloadInvoice } from "../api/orderApis";
import { useSocket } from "../contexts/SocketContext";

const useOrders = (params) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["orders", params],
    queryFn: () => getAllOrders(params),
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  });
};

const useDownloadInvoice = () => {
  return useMutation({
    mutationFn: downloadInvoice,
  });
};

export { useOrders, useDownloadInvoice };
