import RadioInput from "../../../components/RadioInput";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import { FaGooglePlay } from "react-icons/fa";

const GooglePlay = () => {
  return (
    <div className="GOOGLE-PLAY-STATUS bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 p-4 border-b-2 border-secondary">
        <FaGooglePlay />
        <p className="font-medium">Google play store Status</p>
      </div>
      <div className="p-6 space-y-2">
        <RadioInput label="active" name="google_play_status" />
        <RadioInput label="inactive" name="google_play_status" />
        <div className="pt-4">
          <InputBox label="link" name="link" />
        </div>
        <div className="w-max ml-auto pt-4">
          <Button>save</Button>
        </div>
      </div>
    </div>
  );
};

export default GooglePlay;
