import axiosInstance from "./axiosInstance";

const getAllBanners = async () => {
  const response = await axiosInstance.get("/getAllBanners");
  return response.data?.bannerDetails;
};

const getBannerById = async (bannerId) => {
  const response = await axiosInstance.get(`/getBannerById/${bannerId}`);
  return response.data;
};

const createBanner = async (bannerData) => {
  const response = await axiosInstance.post("/createBanner", bannerData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

const updateBanner = async (bannerId, bannerData) => {
  const response = await axiosInstance.post(
    `/editBanner/${bannerId}`,
    bannerData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

const deleteBanner = async (bannerId) => {
  const response = await axiosInstance.delete(`/deleteBanner/${bannerId}`);
  return response.data;
};

const makePublish = async (bannerId, isPublished) => {
  const response = await axiosInstance.post(`/publishBanner/${bannerId}`, {
    is_published: isPublished,
  });
  return response.data;
};

export {
  getAllBanners,
  getBannerById,
  createBanner,
  updateBanner,
  deleteBanner,
  makePublish,
};
