import RadioInput from "../../../components/RadioInput";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import { FaApple } from "react-icons/fa";

const AppleStore = () => {
  return (
    <div className="APPLE-STORE-STATUS bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 p-4 border-b-2 border-secondary">
        <FaApple />
        <p className="font-medium">Apple store Status</p>
      </div>
      <div className="p-6 space-y-2">
        <RadioInput label="active" name="apple_store_status" />
        <RadioInput label="inactive" name="apple_store_status" />
        <div className="pt-4">
          <InputBox label="link" name="link" />
        </div>
        <div className="w-max ml-auto pt-4">
          <Button>save</Button>
        </div>
      </div>
    </div>
  );
};

export default AppleStore;
