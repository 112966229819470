import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBrand,
  deleteBrand,
  featureBrand,
  getAllBrands,
  getBrand,
  updateBrand,
  utsavFeatureBrand,
} from "../api/brandApis";

const useBrands = () => {
  return useQuery({
    queryKey: ["brands"],
    queryFn: () => getAllBrands(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useBrandById = (brandId) => {
  return useQuery({
    queryKey: ["brand", brandId],
    queryFn: () => getBrand(brandId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!brandId,
  });
};

const useCreateBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBrand,
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
    },
  });
};

const useUpdateBrand = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ brandId, brandData }) => updateBrand(brandId, brandData),
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
      queryClient.invalidateQueries(["brand"], brandId);
    },
  });
};

const useDeleteBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBrand,
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
    },
  });
};

const useFeatureBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ brandId, isFeatured }) => featureBrand(brandId, isFeatured),
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
    },
  });
};

const useUtsavFeatureBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ brandId, isFeatured }) =>
      utsavFeatureBrand(brandId, isFeatured),
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
    },
  });
};

export {
  useBrands,
  useBrandById,
  useCreateBrand,
  useUpdateBrand,
  useDeleteBrand,
  useFeatureBrand,
  useUtsavFeatureBrand,
};
