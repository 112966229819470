import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBanner,
  deleteBanner,
  getAllBanners,
  getBannerById,
  makePublish,
  updateBanner,
} from "../api/bannerApis";

const useBanners = () => {
  return useQuery({
    queryKey: ["banners"],
    queryFn: () => getAllBanners(),
    staleTime: 1000 * 60 * 5,
    placeHolderData: [],
  });
};

const useBannerById = (bannerId) => {
  return useQuery({
    queryKey: ["banner", bannerId],
    queryFn: () => getBannerById(bannerId),
    staleTime: 1000 * 60 * 5,
    placeHolderData: null,
    enabled: !!bannerId,
  });
};

const useCreateBanner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBanner,
    onSuccess: () => {
      queryClient.invalidateQueries(["banners"]);
    },
  });
};

const useUpdateBanner = (bannerId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ bannerId, bannerData }) =>
      updateBanner(bannerId, bannerData),
    onSuccess: () => {
      queryClient.invalidateQueries(["banners"]);
      queryClient.invalidateQueries(["banner", bannerId]);
    },
  });
};

const useDeleteBanner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBanner,
    onSuccess: () => {
      queryClient.invalidateQueries(["banners"]);
    },
  });
};

const usePublishBanner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ bannerId, isPublished }) =>
      makePublish(bannerId, isPublished),
    onSuccess: () => {
      queryClient.invalidateQueries(["banners"]);
    },
  });
};

export {
  useBanners,
  useBannerById,
  useCreateBanner,
  useUpdateBanner,
  useDeleteBanner,
  usePublishBanner,
};
