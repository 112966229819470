import { MdOutlineLocalShipping } from "react-icons/md";
import RadioInput from "../../../components/RadioInput";

const ShippingMethod = () => {
  return (
    <section className="grid grid-cols-2 gap-4">
      <div className="SHIPPING-RESPONSIBILITY bg-white rounded-xl shadow-pop">
        <div className="flex items-center gap-2 px-6 py-4 border-b-2 border-secondary">
          <MdOutlineLocalShipping />
          <p className="font-medium">Shipping responsibility</p>
        </div>
        <div className="px-6 py-4 space-y-2">
          <RadioInput label="In house shipping" name="apple_store_status" />
          <RadioInput label="Seller wise shipping" name="apple_store_status" />
        </div>
      </div>
    </section>
  );
};

export default ShippingMethod;
