import React, { useState, useEffect, useRef } from "react";
import { TbProgressCheck } from "react-icons/tb";
import Tab from "../../components/Tab";
import { RiTruckLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { LuPackageCheck } from "react-icons/lu";

const Overview = () => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(1); // Default 1x1 for smallest screens

  const tabs = [
    {
      title: "In Progress",
      icon: <TbProgressCheck />,
      body: "Rs 00000",
      growth: 12,
      accent: "#fcc92d",
    },
    {
      title: "Shipped",
      icon: <RiTruckLine />,
      body: "Rs 00000",
      growth: -12,
      accent: "#69b6f3",
    },
    {
      title: "Delivered",
      icon: <LuPackageCheck />,
      body: "00000",
      growth: 0,
      accent: "#91c936",
    },
    {
      title: "Cancelled",
      icon: <IoMdClose />,
      body: "00000",
      growth: 5,
      accent: "#c6373d",
    },
  ];

  // Adjust visible slides based on screen size
  useEffect(() => {
    const updateVisibleSlides = () => {
      if (window.innerWidth >= 1024) setVisibleSlides(4); // Large screens
      else if (window.innerWidth >= 768) setVisibleSlides(3); // Medium screens
      else if (window.innerWidth >= 640) setVisibleSlides(2); // Small screens
      else setVisibleSlides(1); // Extra-small screens
    };

    updateVisibleSlides();
    window.addEventListener("resize", updateVisibleSlides);
    return () => window.removeEventListener("resize", updateVisibleSlides);
  }, []);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < tabs.length - visibleSlides) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") handlePrev();
      if (event.key === "ArrowRight") handleNext();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentIndex]);

  return (
    <div className="relative w-full overflow-hidden">
      {/* Slider Container */}
      <div
        ref={sliderRef}
        className="flex transition-transform duration-300 ease-in-out"
        style={{
          transform: `translateX(-${(currentIndex * 100) / visibleSlides}%)`,
          
        }}
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            className="flex-shrink-0"
            style={{
              width: `${105 / visibleSlides}%`,
              padding:8,
            }}
          >
            <Tab
              title={tab.title}
              icon={tab.icon}
              body={tab.body}
              growth={tab.growth}
              accent={tab.accent}
            />
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <button
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-100 text-black rounded-full p-2 shadow-md hover:bg-gray-700 z-10"
        onClick={handlePrev}
        disabled={currentIndex === 0}
      >
        &#8249;
      </button>
      <button
        className=" z-0 absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-100 text-black rounded-full p-2 shadow-md hover:bg-gray-700 z-10"
        onClick={handleNext}
        disabled={currentIndex >= tabs.length - visibleSlides}
      >
        &#8250;
      </button>
    </div>
  );
};

export default Overview;
