import InputBox from "../../components/InputBox";
import ToggleSwitch from "../../components/ToggleSwitch";
import Button from "../../components/Button";
import useForm from "../../hooks/useForm";
import useMember from "../../hooks/useMember";
import { RiShieldUserFill } from "react-icons/ri";
import { useEffect } from "react";
import { BiWallet } from "react-icons/bi";
import {
  useAddBalance,
  useCustomerWallet,
} from "../../services/walletServices";
import { useToast } from "../../contexts/toastContext";

const CustomerForm = ({ user }) => {
  const { data: wallet, isLoading: walletLoading } = useCustomerWallet(
    user._id
  );
  const balanceMutation = useAddBalance(user._id);
  const { makeMember } = useMember();
  const toast = useToast();

  const addWalletBalance = () => {
    const { amount } = formData;

    // Client side validations
    if (!amount) {
      toast.warning("Enter an amount");
      return;
    }

    balanceMutation.mutate(
      {
        amount,
        description: "Gift from admin",
        userId: user._id,
      },
      {
        onSuccess: () => {
          toast.success("Balance Added successfully");
          setFormData({ ...formData, amount: "" });
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to add balance");
        },
      }
    );
  };

  const deductWalletBalance = () => {
    const { debitAmount } = formData;

    // Client side validations
    if (!debitAmount) {
      toast.warning("Enter an amount");
      return;
    }

    balanceMutation.mutate(
      {
        amount: -debitAmount,
        description: "Withdraw",
        userId: user._id,
      },
      {
        onSuccess: () => {
          toast.success("Balance deducted successfully");
          setFormData({ ...formData, debitAmount: "" });
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to deduct balance");
        },
      }
    );
  };

  const { formData, setFormData, handleChange } = useForm({});

  const handleIsUtsav = (e) => {
    const isUtsav = e.target.checked;
    handleChange(e);
    makeMember(user._id, isUtsav);
  };

  useEffect(() => {
    if (user) {
      setFormData({ isUtsav: user.is_utsav });
    }
  }, [setFormData, user]);

  return (
    user && (
      <div className="border border-primary rounded-xl bg-white shadow-pop">
        <div className="HEAD flex items-center gap-4 px-4 py-2 border-b border-primary">
          <span className="bg-mainRose p-2 rounded-full text-primeRed">
            <RiShieldUserFill size={14} />
          </span>
          <h2 className="font-medium">Status</h2>
        </div>

        <div className="p-4 space-y-4">
          <div className="flex items-center gap-2 text-xl text-success font-bold">
            <BiWallet />
            {!walletLoading && wallet ? <p>₹ {wallet.balance}</p> : null}
          </div>
          <ToggleSwitch
            label="utsav member"
            name="isUtsav"
            checked={formData.isUtsav}
            onChange={handleIsUtsav}
          />
          <div className="flex items-end justify-between gap-4">
            <InputBox
              type="number"
              label="add wallet balance"
              placeholder="Enter amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              className="h-10 border border-primary rounded-lg text-sm font-medium px-4"
            />
            <Button
              onClick={addWalletBalance}
              className="h-10 w-full rounded-lg px-4"
            >
              add
            </Button>
          </div>
          <div className="flex items-end justify-between gap-4">
            <InputBox
              type="number"
              label="deduct wallet balance"
              placeholder="Enter amount"
              name="debitAmount"
              value={formData.debitAmount}
              onChange={handleChange}
              className="h-10 border border-primary rounded-lg text-sm font-medium px-4"
            />
            <Button
              onClick={deductWalletBalance}
              className="h-10 w-full rounded-lg px-4"
            >
              deduct
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default CustomerForm;
