import { useState, useCallback } from "react";
import {
  createCoupon,
  deleteCoupon,
  updateCoupon,
  updateCouponStatus,
} from "../api/dealApis";

const useCoupon = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addCoupon = useCallback(async (brandData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await createCoupon(brandData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("coupons");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const editCoupon = useCallback(async (couponId, couponData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateCoupon(couponId, couponData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("coupons");
        sessionStorage.removeItem(`coupon_${couponId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeCoupon = useCallback(async (couponId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await deleteCoupon(couponId);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("coupons");
        sessionStorage.removeItem(`coupon_${couponId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateStatus = useCallback(async (couponId, status) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateCouponStatus(couponId, status);
      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("coupons");
        sessionStorage.removeItem(`coupon_${couponId}`);
      }
    } catch (error) {
      console.error("Error updating coupon status", error);
      setError("Failed to update coupon status. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    addCoupon,
    editCoupon,
    updateStatus,
    removeCoupon,
    loading,
    error,
  };
};

export default useCoupon;
