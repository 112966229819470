import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createCategory,
  createProductType,
  createSubcategory,
  deleteCategory,
  deleteProductType,
  deleteSubcategory,
  featureCategory,
  featureProductType,
  featureSubcategory,
  getAllCategories,
  getAllProductTypes,
  getAllSubcategories,
  getCategory,
  getProductType,
  getProductTypes,
  getSubcategories,
  getSubcategory,
  updateCategory,
  updateProductType,
  updateSubcategory,
} from "../api/categoryApis";

const useCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: getAllCategories,
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useCategoryById = (categoryId) => {
  return useQuery({
    queryKey: ["category", categoryId],
    queryFn: () => getCategory(categoryId),
    enabled: !!categoryId,
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
  });
};

const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
    },
  });
};

const useUpdateCategory = (categoryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ categoryId, categoryData }) =>
      updateCategory(categoryId, categoryData),
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
      queryClient.invalidateQueries(["category", categoryId]);
    },
  });
};

const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
    },
  });
};

const useFeatureCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ categoryId, isFeatured }) =>
      featureCategory(categoryId, isFeatured),
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
    },
  });
};

const useSubcategories = () => {
  return useQuery({
    queryKey: ["subcategories"],
    queryFn: getAllSubcategories,
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useSubcategoriesByCategory = (categoryId) => {
  return useQuery({
    queryKey: ["subcategories", categoryId],
    queryFn: () => getSubcategories(categoryId),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
    enabled: !!categoryId,
  });
};

const useSubcategoryById = (subcategoryId) => {
  return useQuery({
    queryKey: ["subcategory", subcategoryId],
    queryFn: () => getSubcategory(subcategoryId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!subcategoryId,
  });
};

const useCreateSubcategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSubcategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["subcategories"]);
    },
  });
};

const useUpdateSubcategory = (subcategoryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ subcategoryId, subcategoryData }) =>
      updateSubcategory(subcategoryId, subcategoryData),
    onSuccess: () => {
      queryClient.invalidateQueries(["subcategories"]);
      queryClient.invalidateQueries(["subcategory", subcategoryId]);
    },
  });
};

const useDeleteSubcategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSubcategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["subcategories"]);
    },
  });
};

const useFeatureSubcategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ subcategoryId, isFeatured }) =>
      featureSubcategory(subcategoryId, isFeatured),
    onSuccess: () => {
      queryClient.invalidateQueries(["subcategories"]);
    },
  });
};

const useProductTypes = () => {
  return useQuery({
    queryKey: ["productTypes"],
    queryFn: getAllProductTypes,
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useProductTypesBySubcategory = (subcategoryId) => {
  return useQuery({
    queryKey: ["productTypes", subcategoryId],
    queryFn: () => getProductTypes(subcategoryId),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
    enabled: !!subcategoryId,
  });
};

const useProductTypeById = (productTypeId) => {
  return useQuery({
    queryKey: ["productType", productTypeId],
    queryFn: () => getProductType(productTypeId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!productTypeId,
  });
};

const useCreateProductType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createProductType,
    onSuccess: () => {
      queryClient.invalidateQueries(["productTypes"]);
    },
  });
};

const useUpdateProductType = (productTypeId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ productTypeId, productTypeData }) =>
      updateProductType(productTypeId, productTypeData),
    onSuccess: () => {
      queryClient.invalidateQueries(["productTypes"]);
      queryClient.invalidateQueries(["productType", productTypeId]);
    },
  });
};

const useDeleteProductType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProductType,
    onSuccess: () => {
      queryClient.invalidateQueries(["productTypes"]);
    },
  });
};

const useFeatureProductType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ productTypeId, isFeatured }) =>
      featureProductType(productTypeId, isFeatured),
    onSuccess: () => {
      queryClient.invalidateQueries(["productTypes"]);
    },
  });
};

export {
  useCategories,
  useCategoryById,
  useCreateCategory,
  useUpdateCategory,
  useDeleteCategory,
  useFeatureCategory,
  useSubcategories,
  useSubcategoriesByCategory,
  useSubcategoryById,
  useCreateSubcategory,
  useUpdateSubcategory,
  useDeleteSubcategory,
  useFeatureSubcategory,
  useProductTypes,
  useProductTypesBySubcategory,
  useProductTypeById,
  useCreateProductType,
  useUpdateProductType,
  useDeleteProductType,
  useFeatureProductType,
};
