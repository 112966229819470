import { useEffect, useState } from "react";
import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import TextareaBox from "../../components/TextareaBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import {
  useBrandById,
  useCreateBrand,
  useUpdateBrand,
} from "../../services/brandServices";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../contexts/toastContext";
import { BsImageAlt } from "react-icons/bs";
import SelectBox from "../../components/SelectBox";
import {
  useCategories,
  useProductTypes,
} from "../../services/categoryServices";

const BrandForm = ({ edit = false }) => {
  const { brandId } = useParams();
  const { data: brand, isLoading, error } = useBrandById(brandId);
  const { data: categories, isLoading: categoriesLoading } = useCategories();
  const { data: productTypes, isLoading: productTypesLoading } =
    useProductTypes();
  const createMutation = useCreateBrand();
  const updateMutation = useUpdateBrand(brandId);
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitHandler = (categoryData) => {
    const { name, categoryList, productTypeList, description, logo } =
      categoryData;
    const payload = {
      name,
      categoryList,
      productTypeList,
      description,
      logo: logo ? logo[0] : brand?.logoUrl,
    };

    if (edit) {
      updateMutation.mutate(
        { brandId, brandData: payload },
        {
          onSuccess: () => {
            toast.success("Brand updated successfully");
            navigate(-1);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Failed to update brand");
          },
        }
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Brand created successfully");
          navigate(-1);
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to create brand");
        },
      });
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && brand) {
      setFormData({
        name: brand.name,
        categoryList: brand.categoryList,
        productTypeList: brand.productTypeList,
        description: brand.description,
        logo: null,
      });
      if (brand.logoUrl) {
        setSelectedImage(brand.logoUrl);
      }
    }
  }, [edit, brand, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  if (isLoading || (edit && !brand)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Brand" : "Add Brand"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[65%] space-y-4">
          <InputBox
            label="brand name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <SelectBox
            label="category list"
            name="categoryList"
            value={formData.categoryList}
            onChange={handleChange}
            options={categories.map((cat) => ({
              label: cat.name,
              value: cat._id,
            }))}
            multi
            disabled={categoriesLoading}
          />
          <SelectBox
            label="product-type list"
            name="productTypeList"
            value={formData.productTypeList}
            onChange={handleChange}
            options={productTypes.map((prod) => ({
              label: prod.name,
              value: prod._id,
            }))}
            multi
            disabled={productTypesLoading}
          />
          <FileInput
            label="brand logo ratio (1:1)"
            name="logo"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2"
          />
          <TextareaBox
            label="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="w-[30%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div className="size-60 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="brand-logo"
                  className="size-full object-cover object-center"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>

            <p className="text-sm font-medium">Dimensions: 900 x 900 pixels</p>
          </div>

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              Reset
            </Button>
            <Button
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              submit
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default BrandForm;
