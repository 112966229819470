import { useState } from "react";
import { IoMdClose } from "react-icons/io";

const TagsInput = ({
  label,
  name,
  placeholder,
  value = [],
  onChange = () => {},
  align = false,
  readOnly = false,
  disabled = false,
  className = "w-full px-4 py-2 rounded-md text-xs border border-secondary bg-offWhite",
  containerClassName = "gap-2",
}) => {
  const id = label?.replace(" ", "-").toLowerCase();
  const [inputValue, setInputValue] = useState("");

  const handleAddTag = () => {
    const newTag = inputValue.trim();
    if (newTag && !value.includes(newTag)) {
      onChange({ target: { name, value: [...value, newTag] } });
      setInputValue("");
    } else {
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddTag();
    } else if (e.key === "Backspace" && inputValue === "" && value.length > 0) {
      e.preventDefault();
      onChange({ target: { name, value: value.slice(0, value.length - 1) } });
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRemoveTag = (index) => {
    onChange({ target: { name, value: value.filter((_, i) => i !== index) } });
  };

  const handleBlur = () => {
    handleAddTag();
  };

  return (
    <div className={`relative flex flex-col ${containerClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className="capitalize font-medium text-sm"
          style={align ? { textAlign: "center" } : {}}
        >
          {label}
        </label>
      )}

      <div
        className={`flex flex-wrap items-center gap-2 select-none ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${className}`}
      >
        {value.map((tag, index) => (
          <div
            key={`${tag}-${index}`}
            className="flex gap-1 items-center bg-primeRed text-white text-xs font-medium px-2 py-1 rounded"
          >
            {tag}
            {!disabled && (
              <button type="button" onClick={() => handleRemoveTag(index)}>
                <IoMdClose size={14} />
              </button>
            )}
          </div>
        ))}
        <input
          id={id}
          type="text"
          name="temp"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder || label}
          className={`flex-1 border-none outline-none bg-transparent placeholder:capitalize ${
            disabled && "opacity-50 cursor-not-allowed"
          }`}
        />
      </div>
    </div>
  );
};

export default TagsInput;
