import axiosInstance from "./axiosInstance";

const getAllOrders = async (params) => {
  const response = await axiosInstance.get("/getAllOrder", { params });
  return response.data;
};

const getOrderById = (orderId) => {
  return axiosInstance.get(`/getOrderByIdAdmin/${orderId}`);
};

const updateOrderStatus = (orderId, status) => {
  return axiosInstance.post(`/updateStatusAdmin/${orderId}`, { status });
};

const getStatusHistory = (orderId) => {
  return axiosInstance.get(`/orderStatusDetails/${orderId}`);
};

const downloadInvoice = async (orderId) => {
  const response = await axiosInstance.get(`/downloadInvoice/${orderId}`);
  return response.data;
};

export {
  getAllOrders,
  getOrderById,
  updateOrderStatus,
  getStatusHistory,
  downloadInvoice,
};
