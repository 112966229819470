import { useState, useCallback } from "react";
import {
  activeProduct,
  createProduct,
  createVariant,
  deleteVariant,
  featureProduct,
  makeAsNewArrival,
  updateProduct,
  updateVariant,
} from "../api/productApis";
import { useToast } from "../contexts/toastContext";
import { useNavigate } from "react-router-dom";

const useProduct = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const addProduct = useCallback(async (productData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await createProduct(productData);

      if (response?.status) {
        toast.success("Product Created successfully");
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      setError(error);
      toast.error("Error creating Product");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const editProduct = useCallback(async (productId, productData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateProduct(productId, productData);

      if (response?.status) {
        toast.success("Product edited successfully");
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      setError(error);
      toast.error("Error updating product");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const addVariant = useCallback(async (variantData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await createVariant(variantData);

      if (response?.status) {
        toast.success("Variant Created successfully");
      }
    } catch (error) {
      console.error(error);
      setError(error);
      toast.error("Error adding variant");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const editVariant = useCallback(async (variantId, variantData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateVariant(variantId, variantData);

      if (response?.status) {
        toast.success("Variant updated successfully");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
      toast.error("Error updating variant");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const removeVariant = useCallback(async (variantId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await deleteVariant(variantId);

      if (response.status) {
        toast.success("Variant removed successfully");
      }
    } catch (error) {
      console.error(error);
      setError(error);
      toast.error("Error removing variant");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const updateFeature = useCallback(async (variantId, featured) => {
    setLoading(true);
    setError(null);
    try {
      const response = await featureProduct(variantId, featured);

      if (response?.status) {
        console.log(response.data);
        toast.success(response.data.message)
        return response.data.is_featured
      }
      
    } catch (error) {
      console.error("Error updating feature", error);
      setError("Failed to update feature. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  const setNewArrivals = useCallback(async (variantId, isNewArrival) => {
    setLoading(true);
    setError(null);
    try {
      const response = await makeAsNewArrival(variantId, isNewArrival);

      if (response?.status) {
        // console.log(response.data);
        toast.success(response.data.message)
        return response.data.newArrival
      }
    } catch (error) {
      console.error("Error updating feature", error);
      setError("Failed to update feature. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  const setIsActive = useCallback(async (variantId, isActive) => {
    setLoading(true);
    setError(null);
    try {
      const response = await activeProduct(variantId, isActive);
      if (response?.status === 200) {
        toast.success(response.data.message);
        return response.data.is_active;
      } else {
        setError("Failed to update the active status. Please try again.");
      }
    } catch (error) {
      console.error("Error updating active status:", error);
      setError("Failed to update feature. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);
  

  return {
    addProduct,
    editProduct,
    addVariant,
    editVariant,
    removeVariant,
    updateFeature,
    setNewArrivals,
    setIsActive,
    loading,
    error,
  };
};

export default useProduct;
