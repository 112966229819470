import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import { LuSettings2 } from "react-icons/lu";

const Units = () => {
  return (
    <div className="VARIANT-ATTRIBUTES bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 py-4 px-6 border-b-2 border-secondary">
        <LuSettings2 />
        <p className="font-medium">Units</p>
      </div>
      <div className="py-4 px-6 space-y-4">
        <InputBox label="Unit Name" name="unitName" />
        <div className="w-max ml-auto">
          <Button>create</Button>
        </div>
      </div>
      <div className="px-6 py-4">
        <table className="w-full">
          <thead className="bg-mainGray">
            <tr className="text-sm">
              <th scope="col" className="px-4 py-2 font-medium text-left">
                SL
              </th>
              <th scope="col" className="px-4 py-2 font-medium">
                Unit Name
              </th>
              <th scope="col" className="px-4 py-2 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-white">
              <td className="px-5 py-2">
                <p className="text-sm">1</p>
              </td>
              <td className="px-4 py-2 text-center">
                <p className="text-sm">Kg</p>
              </td>
              <td className="px-4 py-2">
                <div className="flex justify-center items-center gap-4">
                  <button>
                    <FaRegEdit size={12} className="text-success" />
                  </button>
                  <button>
                    <FaRegTrashAlt size={12} className="text-error" />
                  </button>
                </div>
              </td>
            </tr>
            <tr className="even:bg-offWhite">
              <td className="px-5 py-2">
                <p className="text-sm">2</p>
              </td>
              <td className="px-4 py-2 text-center">
                <p className="text-sm">Litre</p>
              </td>
              <td className="px-4 py-2">
                <div className="flex justify-center items-center gap-4">
                  <button>
                    <FaRegEdit size={12} className="text-success" />
                  </button>
                  <button>
                    <FaRegTrashAlt size={12} className="text-error" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Units;
