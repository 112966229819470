const Legend = ({ fill, title }) => {
  return (
    <div className="flex items-center gap-1">
      <span
        className="block size-3 rounded-full"
        style={{ backgroundColor: fill }}
      ></span>
      <p className="capitalize text-sm font-medium">{title}</p>
    </div>
  );
};

export default Legend;
