import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { IoChevronDown } from "react-icons/io5";

const Info = ({ product }) => {
  const tabs = ["description", "details"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const infoRef = useRef(null);
  const THRESHOLD_HEIGHT = 75;

  const toggleReadMore = () => setReadMore(!readMore);

  useEffect(() => {
    if (infoRef.current) {
      setShowReadMore(infoRef.current.scrollHeight > THRESHOLD_HEIGHT);
    }
  }, [product, selectedTab]);

  return (
    <section>
      <nav className="border-t-4 border-secondary">
        <ul className="flex justify-between gap-6">
          {tabs.map((tab) => (
            <li
              key={tab}
              className="relative w-1/2 py-2 px-6 text-center capitalize cursor-pointer"
              onClick={() => setSelectedTab(tab)}
            >
              <h3 className="uppercase text-primeRed text-lg max-sm:text-base font-medium">
                {tab}
              </h3>
              {tab === selectedTab && (
                <motion.span
                  layoutId="description-underline"
                  className="block h-1 rounded-full bg-darkBlue absolute -top-1 right-0 left-0"
                />
              )}
            </li>
          ))}
        </ul>
      </nav>

      <div className="space-y-4 my-4">
        <motion.div
          ref={infoRef}
          initial={{ height: `${THRESHOLD_HEIGHT}px` }}
          animate={{ height: readMore ? "auto" : `${THRESHOLD_HEIGHT}px` }}
          transition={{ duration: 0.3 }}
          className="max-sm:text-sm overflow-y-hidden"
        >
          {selectedTab === tabs[0] ? (
            parse(product?.productGroup?.description)
          ) : (
            <ul className="space-y-1 max-sm:text-sm">
              {product?.variantDetails &&
                Object.keys(product?.variantDetails).map((key) => (
                  <li key={key} className="flex gap-2 items-center">
                    <p className="uppercase font-bold">{key} :</p>
                    <p>{product?.variantDetails?.[key]}</p>
                  </li>
                ))}
            </ul>
          )}
        </motion.div>

        {showReadMore && (
          <button
            onClick={toggleReadMore}
            className="text-primeRed font-medium max-sm:text-sm flex items-center justify-center gap-1"
          >
            {readMore ? "Show Less" : "Read More"}
            <IoChevronDown
              size={18}
              className={`${
                readMore && "rotate-180"
              } transition-transform duration-200 ease-in-out`}
            />
          </button>
        )}
      </div>
    </section>
  );
};

export default Info;
