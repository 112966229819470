import { useEffect, useState } from "react";
import Tab from "../../components/Tab";
import { useAnalytics } from "../../hooks/useAnalytics";

import { useCustomers } from "../../services/customerServices";
import { useOrders } from "../../services/orderServices";

const Overview = () => {
  const { totalIncome, totalOrder, totalSale, totalCustomer, loading } =
    useAnalytics();
    const {
      data: { totalCount = 0 } = {}
    } = useCustomers({});
  const { data, isFetching } = useOrders();
  const [totalsell, settotalsell] = useState(null);

  console.log(totalIncome);

  useEffect(() => {}, [data]);
  return (
    <div className="flex justify-between gap-4">
      <Tab
        title="Total Sale"
        body={!loading && `Rs ${data?.totalIncome ? data?.totalIncome : 0.00}`}
        growth={!loading && totalIncome.percentageChange}
        accent="#91c936"
      />
      <Tab
        title="Total Order"
        body={!loading && data?.totalOrders}
        growth={!loading && totalOrder.changeInPercentage}
        accent="#69b6f3"
        url={"/orders"}
      />
      <Tab
        title="Product sale"
        body={!loading && `Total ${data?.totalSales ? data?.totalSales : 0}`}
        growth={0}
        accent="#fcc92d"
      />
      <Tab
        title="Total Customer"
        body={!loading && totalCount}
        growth={5}
        accent="#c6373d"
        url={"/customers"}
      />
    </div>
  );
};

export default Overview;
