import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { useMemo } from "react";
import {
  useDeleteFashionBlog,
  useFashionBlogs,
} from "../../services/fashionServices";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { useToast } from "../../contexts/toastContext";
import { useModal } from "../../contexts/modalContext";
import Pagination from "../../components/Pagination";
import { isVideoUrl } from "../../utils/fileUtils";
import DeleteFashion from "./DeleteFashion";

const BlogList = () => {
  const { data: blogs = [], isLoading, error } = useFashionBlogs();
  const deleteMutation = useDeleteFashionBlog();
  const { openModal, closeModal } = useModal();
  const [page, setPage] = useSearchParamsState("page", 1);
  const navigate = useNavigate();
  const toast = useToast();
  const limit = 2;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return blogs.slice(firstPageIndex, lastPageIndex);
  }, [page, blogs]);

  const handleDelete = (fashionBlogId) => {
    deleteMutation.mutate(fashionBlogId, {
      onSuccess: () => {
        toast.success("Blog deleted successfully");
        closeModal();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete blog");
      },
    });
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Blogs
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return blogs.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Fashion Category Found
      </h2>
    </div>
  ) : (
    <section className="space-y-4">
      <h3 className="text-3xl font-medium">Blog List</h3>

      <div className="bg-white rounded-xl shadow-pop overflow-hidden">
        <div className="w-full flex flex-col justify-between">
          <table className="w-full">
            <thead className="bg-lightGray">
              <tr>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  SL
                </th>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  Post
                </th>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  Info
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((blog, index) => (
                <tr key={index}>
                  <td className="px-6 py-3">{index + 1}</td>
                  <td className="px-6 py-3">
                    <div className="size-48">
                      {blog.logoUrls?.slice(0, 1).map((post, index) =>
                        isVideoUrl(post) ? (
                          <video
                            key={index}
                            controls
                            controlsList="nodownload"
                            autoPlay
                            loop
                            className="size-full object-contain object-center"
                          >
                            <source src={post} type="video/mp4" />
                            <source src={post} type="video/mkv" />
                            <source src={post} type="video/avi" />
                            <source src={post} type="video/webm" />
                            <source src={post} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            key={index}
                            src={post}
                            alt={blog._id}
                            className="size-full object-contain object-center"
                          />
                        )
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-3">
                    <div className="space-y-2">
                      <h4 className="font-medium line-clamp-2">
                        {blog.caption}
                      </h4>
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium">
                          Similar Products
                        </h4>
                        <div className="flex gap-4">
                          {blog.productList?.slice(0, 2)?.map((product) =>
                            product ? (
                              <div
                                key={product._id}
                                className="PRODUCT-BOX w-60 flex border rounded overflow-hidden"
                              >
                                <div className="size-16">
                                  <img
                                    src={product.images[0]}
                                    alt={product.name || product._id}
                                    className="size-full object-contain object-center"
                                  />
                                </div>
                                <div className="text-xs p-2">
                                  <h3 className="font-medium truncate">
                                    {product?.name}
                                  </h3>
                                  {product?.attributes &&
                                    Object.keys(product?.attributes)
                                      .slice(0, 3)
                                      .map((key) => (
                                        <p key={key} className="capitalize">
                                          {key}: {product?.attributes[key]}
                                        </p>
                                      ))}
                                  <div className="flex items-center gap-1">
                                    <p className="text-primary line-through font-medium leading-none">
                                      ₹ {Math.round(product?.unitPrice)}
                                    </p>
                                    <p className="text-base text-success font-semibold leading-none">
                                      ₹ {Math.round(product?.sellingPrice)}
                                    </p>
                                    {product?.discount > 0 && (
                                      <p className="text-sm font-medium text-blue-600 leading-none">
                                        {product?.discountType === "percent"
                                          ? `${product.discount}%`
                                          : `₹${product.discount} OFF`}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <p>Invalid Product</p>
                            )
                          )}
                          {blog.productList?.length > 2 && (
                            <button className="text-mainBlue">see more</button>
                          )}
                        </div>
                      </div>
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium">Category</h4>
                        <div className="bg-mainGray w-max text-sm px-6 py-2 rounded-full">
                          {blog.fashionCategory?.name}
                        </div>
                      </div>
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium">Uploaded By</h4>
                        <div className="flex items-center gap-2">
                          <div className="USER-LOGO size-8 rounded-full">
                            <img
                              src={blog.userLogo}
                              alt={blog.userName}
                              className="object-cover object-center size-full rounded-full"
                            />
                          </div>
                          <p className="leading-none text-sm">
                            {blog.userName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex justify-center items-center gap-4">
                      <button onClick={() => navigate(`edit/${blog._id}`)}>
                        <FaRegEdit className="text-success" />
                      </button>
                      <button
                        onClick={() =>
                          openModal(
                            <DeleteFashion
                              onDelete={() => handleDelete(blog._id)}
                            />
                          )
                        }
                      >
                        <FaRegTrashAlt className="text-error" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={parseInt(page, 10)}
            totalCount={blogs.length}
            pageSize={limit}
            onPageChange={(page) => setPage(page.toString())}
          />
        </div>
      </div>
    </section>
  );
};

export default BlogList;
