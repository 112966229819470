import GiftCardList from "./GiftCardList";
import Templates from "./Templates";

const GiftCards = () => {
  return (
    <section className="p-8 space-y-16">
      <Templates />
      <GiftCardList />
    </section>
  );
};

export default GiftCards;
