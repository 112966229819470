import DealOfTheDayList from "./DealOfTheDayList";
import DealOfTheDayForm from "./DealOfTheDayForm";

const DealOfTheDay = () => {
  return (
    <section className="p-4 space-y-16">
      <DealOfTheDayForm />
      <DealOfTheDayList />
    </section>
  );
};

export default DealOfTheDay;
