import { Line, LineChart, ResponsiveContainer } from "recharts";

const TinyLineChart = ({ data }) => {
  return (
    <ResponsiveContainer className="size-full">
      <LineChart data={data}>
        <Line
          type="monotone"
          dataKey="rating"
          stroke="green"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TinyLineChart;
