import CouponList from "./CouponList";
import CouponForm from "./CouponForm";

const Coupons = () => {
  return (
    <section className="p-4 space-y-16">
      <CouponForm />
      <CouponList />
    </section>
  );
};

export default Coupons;
