import DigitalProduct from "./DigitalProduct";
import ProductBrand from "./ProductBrand";
import StockWarning from "./StockWarning";

const Product = () => {
  return (
    <section className="space-y-4">
      <StockWarning />
      <section className="grid grid-cols-2 gap-4">
        <DigitalProduct />
        <ProductBrand />
      </section>
    </section>
  );
};

export default Product;
