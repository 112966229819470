import { useEffect } from "react";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import { useToast } from "../../contexts/toastContext";
import useCoupon from "../../hooks/useCoupon";
import useForm from "../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { useCouponById } from "../../services/dealServices";

const CouponForm = ({ edit = false }) => {
  const { couponId } = useParams();
  const { data: coupon, isLoading, error } = useCouponById(couponId);
  const { addCoupon, editCoupon, loading } = useCoupon();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmitHandler = async (couponData) => {
    try {
      if (edit) {
        editCoupon(couponId, couponData).then(() => {
          toast.success("Coupon updated successfully");
          navigate(-1);
        });
      } else {
        addCoupon(couponData).then(() => {
          toast.success("Coupon created successfully");
          navigate(-1);
        });
      }
    } catch (error) {}
  };

  const { formData, setFormData, handleChange, handleSubmit } = useForm(
    {},
    onSubmitHandler
  );

  useEffect(() => {
    if (edit && coupon) {
      setFormData({
        ...coupon,
        Start_Date: format(new Date(coupon.Start_Date), "yyyy-MM-dd"),
        Expire_Date: format(new Date(coupon.Expire_Date), "yyyy-MM-dd"),
      });
    }
  }, [edit, coupon, setFormData]);

  if (isLoading || (edit && !coupon)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className={`space-y-4 ${edit && "p-4"}`}>
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Coupon" : "Coupon Setup"}
      </h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-xl shadow-pop space-y-8"
      >
        <div className="grid grid-cols-3 gap-x-16 gap-y-3">
          <SelectBox
            label="coupon type"
            name="couponType"
            value={formData.couponType}
            onChange={handleChange}
            options={[
              "Discount on Purchase",
              "Free Delivery",
              "First Order",
            ].map((value) => ({
              label: value,
              value,
            }))}
          />
          <InputBox
            label="coupon title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          <InputBox
            label="coupon code"
            name="code"
            value={formData.code}
            onChange={handleChange}
          />
          <SelectBox
            label="coupon bearer"
            name="Coupon_Bearer"
            value={formData.Coupon_Bearer}
            onChange={handleChange}
            options={["Admin", "Seller"].map((value) => ({
              label: value,
              value,
            }))}
          />
          <SelectBox
            label="customer"
            name="Customer"
            value={formData.Customer}
            onChange={handleChange}
            options={["All Customers"].map((value) => ({
              label: value,
              value,
            }))}
          />
          <InputBox
            label="limit for same user"
            name="Limit_For_Same_User"
            value={formData.Limit_For_Same_User}
            onChange={handleChange}
          />
          <SelectBox
            label="discount type"
            name="Discount_Type"
            value={formData.Discount_Type}
            onChange={handleChange}
            options={["Percent", "Flat"].map((value) => ({
              label: value,
              value,
            }))}
          />
          <InputBox
            label="discount"
            name="Discount_Value"
            value={formData.Discount_Value}
            onChange={handleChange}
          />
          <InputBox
            label="minimum purchase"
            name="Minimum_Purchase"
            value={formData.Minimum_Purchase}
            onChange={handleChange}
          />
          {formData.Discount_Type === "Percent" && (
            <InputBox
              label="maximum purchase"
              name="Maximum_Purchase"
              value={formData.Maximum_Purchase}
              onChange={handleChange}
            />
          )}
          <InputBox
            type="date"
            label="start date"
            name="Start_Date"
            value={formData.Start_Date}
            onChange={handleChange}
          />
          <InputBox
            type="date"
            label="expire date"
            name="Expire_Date"
            value={formData.Expire_Date}
            onChange={handleChange}
          />
        </div>
        <div className="flex gap-8 justify-end">
          <Button type="button" onClick={null} reverse disabled={loading}>
            Reset
          </Button>
          <Button disabled={loading}>submit</Button>
        </div>
      </form>
    </section>
  );
};

export default CouponForm;
