const CheckBox = ({
  onChange,
  name,
  label,
  checked = false,
  disabled = false,
  align = "right",
  required = false,
  className,
  containerClassName,
}) => {
  const id = label?.replace(" ", "-").toLowerCase();

  return (
    <div className="relative flex gap-2 items-start">
      {label && align === "left" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}

      <div className="grid place-items-center mt-[2px]">
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          required={required}
          className="peer col-start-1 row-start-1 appearance-none shrink-0 size-4 border border-black  disabled:border-primary hover:cursor-pointer"
        />
        <span className="pointer-events-none col-start-1 row-start-1 size-2 peer-checked:bg-primeRed peer-checked:peer-disabled:bg-mainGray" />
      </div>

      {label && align === "right" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
