import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useQueryClient } from "@tanstack/react-query";
import NewOrder from "../components/NewOrder";
import { useNavigate } from "react-router-dom";

const SOCKET_URL_1 = process.env.REACT_APP_WS_1;
const SOCKET_URL_2 = process.env.REACT_APP_WS_2;
const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const socketRef1 = useRef(null);
  const socketRef2 = useRef(null);
  const queryClient = useQueryClient();
  const audioRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    audioRef.current = new Audio("/sound/notification.mp3");

    // Initialize socket connections
    socketRef1.current = io(SOCKET_URL_1, { transports: ["websocket"] });
    socketRef2.current = io(SOCKET_URL_2, { transports: ["websocket"] });

    // Connect and listen for events from each server
    const initializeSocket = (socket, name) => {
      socket.on("connect", () => {
        console.log(`Connected to ${name} server`);
      });

      socket.on("disconnect", () => {
        console.log(`Disconnected from ${name} server`);
      });

      socket.on("connect_error", (error) => {
        console.error(`Socket connection error on ${name} server:`, error);
      });

      socket.on("orderStatusUpdated", (response) => {
        console.log(`Order status updated on ${name}:`, response.orderId);
        if (response.status === "Confirmed") {
          audioRef.current?.play().catch((error) => console.error("Failed to play sound:", error));
          setOrderDetails(response);
          setShowPopup(true);
        }
        queryClient.invalidateQueries("orders");
      });
    };

    initializeSocket(socketRef1.current, "Server 1");
    initializeSocket(socketRef2.current, "Server 2");

    // Clean up connections
    return () => {
      socketRef1.current.disconnect();
      socketRef2.current.disconnect();
    };
  }, [queryClient]);

  return (
    <SocketContext.Provider value={{ socket1: socketRef1.current, socket2: socketRef2.current }}>
      {children}

      {showPopup && (
        <NewOrder
          orderDetails={orderDetails}
          onClose={() => {
            setShowPopup(false);
            setOrderDetails(null);
          }}
          navigate={navigate}
        />
      )}
    </SocketContext.Provider>
  );
};
