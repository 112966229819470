import { createPortal } from "react-dom";
import { IoClose } from "react-icons/io5";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext"; // Correct usage of useAuth

import { useAdmin } from "../../contexts/adminContext";

const AccountModal = ({ onClose }) => {
  const { logout, auth } = useAuth(); // Using useAuth hook here
  const navigate = useNavigate();
  const user = useAdmin()
  const handleLogout = async () => {
    try {
      await logout(); // Calls the logout function from context
      onClose();
      navigate("/auth"); // Navigate to the login page after logout
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return createPortal(
    <div
      className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-2xl p-6 w-96 shadow-xl transform transition-all duration-300 ease-in-out scale-100 hover:scale-105 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-primary-dark transition-colors"
          onClick={onClose}
        >
          <IoClose className="text-2xl" />
        </button>

        <div className="flex flex-col items-center mb-6">
          <div className="w-20 h-20 bg-mainGray rounded-full flex items-center justify-center mb-4">
            <span className="text-white font-bold text-2xl">AS</span>
          </div>
          <h2 className="text-xl font-bold text-primary mb-2">{user?.admin?.fullName}</h2>
          <p className="text-sm text-gray-500">{user?.admin?.email}</p>
        </div>

        <div className="space-y-4 text-center">
        
          <p className="text-lg">
            <strong>Role:</strong> {user?.admin?.userLevel === 0 ? 'Admin':'Devloper'}
          </p>
        
        </div>

        <div className="mt-8 flex flex-col gap-4">
          <button
            className="w-full py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-200"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="w-full py-2 bg-red-500 text-white rounded-lg flex items-center justify-center gap-2 hover:bg-red-600 transition-colors duration-200"
            onClick={handleLogout}
          >
            <FiLogOut className="text-lg" />
            Logout
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default AccountModal;
