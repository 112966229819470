import { useState, useEffect } from "react";

const useCaptcha = (length = 4) => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaCode, setCaptchaCode] = useState([]);

  const generateCaptchaCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = [];
    for (let i = 0; i < length; i++) {
      let character = characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
      result.push({
        value: character,
        rotate: getRandomRotation(),
        lineThrough: Math.random() < 0.5 ? "line-through" : "none",
      });
    }
    return result;
  };

  const getRandomRotation = () => {
    return Math.floor(Math.random() * 20 - 10);
  };

  const regenerateCaptcha = () => {
    const newCaptchaCode = generateCaptchaCode();
    setCaptchaCode(newCaptchaCode);
    setCaptchaValue(newCaptchaCode.map((item) => item.value).join(""));
  };

  const validateCaptcha = (input) => {
    return input === captchaValue;
  };

  useEffect(() => {
    regenerateCaptcha();
    // eslint-disable-next-line
  }, [length]);

  return {
    captchaCode,
    regenerateCaptcha,
    validateCaptcha,
  };
};

export default useCaptcha;
