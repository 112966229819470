import { useSearchParams } from "react-router-dom";
import toKebabCase from "../../utils/kebabize";
import Nav from "./Nav";
import General from "./General";
import AppSettings from "./AppSettings";
import Return from "./Return";
import ShippingMethod from "./ShippingMethod";
import Product from "./Product";
import OTPSetup from "./OTPSetup";
import ProductAttributes from "./ProductAttributes";

const Tabs = [
  { title: "General", component: <General key="general" /> },
  { title: "App Settings", component: <AppSettings key="app-settings" /> },
  // { title: "Finafid", component: null },
  // { title: "Seller", component: null },
  // { title: "Customer", component: null },
  { title: "Return", component: <Return key="return" /> },
  {
    title: "Shipping Method",
    component: <ShippingMethod key="shipping-method" />,
  },
  // { title: "Order", component: null },
  { title: "Product", component: <Product key="product" /> },
  // { title: "Delivery Restriction", component: null },
  {
    title: "Product Attributes",
    component: <ProductAttributes key="product-attributes" />,
  },
  { title: "OTP and Login Setup", component: <OTPSetup key="otp-setup" /> },
  // { title: "Cookie Settings", component: null },
];

const BusinessSetup = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Business Setup</h1>
      <Nav tabs={Tabs} />
      {Tabs.map((item) => toKebabCase(item.title) === tab && item.component)}
    </section>
  );
};

export default BusinessSetup;
