import Button from "../../components/Button";
import DeleteTemplate from "./DeleteTemplate";
import { useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { motion } from "framer-motion";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { useModal } from "../../contexts/modalContext";
import useGiftCard from "../../hooks/useGiftCard";
import { useToast } from "../../contexts/toastContext";
import { useTemplates } from "../../services/giftCardServices";

const Card = ({ data }) => {
  const { removeTemplate } = useGiftCard();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const toast = useToast();

  const handleDelete = (templateId) => {
    removeTemplate(templateId).then(() => {
      toast.success("Deal deleted successfully");
      closeModal();
    });
  };

  return (
    <li className="relative w-60 h-40 bg-mainGray rounded-lg overflow-hidden">
      <img src={data.template} alt={data.title} className="size-full" />
      <motion.div
        whileHover={{ opacity: 1 }}
        className="absolute inset-0 opacity-0 bg-black bg-opacity-50 flex flex-col justify-center items-center gap-4"
      >
        <p className="text-white text-xl">{data.title}</p>
        <div className="space-x-4">
          <button
            onClick={() => navigate(`/gift-card/edit/${data._id}`)}
            className="bg-white p-1 rounded-full"
          >
            <FaRegEdit className="text-success text-xs" />
          </button>
          <button
            onClick={() =>
              openModal(
                <DeleteTemplate onDelete={() => handleDelete(data._id)} />
              )
            }
            className="bg-white p-1 rounded-full text-xs"
          >
            <FaRegTrashAlt className="text-error" />
          </button>
        </div>
      </motion.div>
    </li>
  );
};

const Templates = () => {
  const { data: templates, isLoading, error } = useTemplates();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Templates
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return templates.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Templates Found
      </h2>

      <div className="HEADING flex justify-between items-center px-24">
        <h1 className="text-2xl font-medium">All Templates</h1>
        <div className="flex gap-4">
          <Button
            onClick={() => navigate("/gift-card/add")}
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <FiPlus />
            <p className="text-sm">create template</p>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <section className="space-y-8">
      <div className="HEADING flex justify-between items-center px-24">
        <h1 className="text-2xl font-medium">All Templates</h1>
        <div className="flex gap-4">
          <Button
            onClick={() => navigate("/gift-card/add")}
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <FiPlus />
            <p className="text-sm">create template</p>
          </Button>
        </div>
      </div>

      <ul
        id="GIFT-CARDS-CONTAINER"
        className="flex flex-wrap justify-center gap-8 max-h-[350px] overflow-y-scroll"
      >
        {templates.length === 0 ? (
          <div className="w-full">
            <h2 className="text-center font-secondary font-medium text-xl mt-8">
              No Templates created
            </h2>
          </div>
        ) : (
          templates.map((template, index) => (
            <Card key={index} data={template} />
          ))
        )}
      </ul>
    </section>
  );
};

export default Templates;
