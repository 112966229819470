import {
  PieChart as Chart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize="0.75rem"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Chart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius="40%"
          outerRadius="70%"
          labelLine={false}
          label={renderCustomizedLabel}
          dataKey="product sold"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`url(#color-${index})`} />
          ))}
        </Pie>
        <Tooltip />
        <defs>
          {data.map((entry, index) => (
            <linearGradient
              id={`color-${index}`}
              key={index}
              x1="0"
              y1="0"
              x2="1"
              y2="1"
            >
              <stop offset="0%" stopColor={entry.color} stopOpacity={0.7} />
              <stop offset="100%" stopColor={entry.color} stopOpacity={1} />
            </linearGradient>
          ))}
        </defs>
      </Chart>
    </ResponsiveContainer>
  );
};

export default PieChart;
