import { FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useModal } from "../../contexts/modalContext";
import DeleteCustomer from "./DeleteCustomer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { useToast } from "../../contexts/toastContext";
import useForm from "../../hooks/useForm";
import useCustomer from "../../hooks/useCustomer";
import { formatDate } from "../../utils/dateUtils";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { useCustomers } from "../../services/customerServices";
import Loading from "../../components/Loading";
const CustomerList = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const [page, setPage] = useSearchParamsState("page", 1);
  const limit = 10; // Number of customers per page
  const toast = useToast();

  const {
    data: { customers = [], totalCount = 0 } = {},
    isLoading,
    isFetching,
    error,
    refetch,
  } = useCustomers({ query, page, limit }); // Fetch customers with pagination and query

  const { removeCustomer, blockCustomer } = useCustomer();
  const { formData, setFormData } = useForm({});
 
  const handleDelete = async (userId) => {
    try {
      await removeCustomer(userId);
      toast.error("Customer deleted successfully");
      closeModal();
      refetch(); // Refresh customer list
    } catch (err) {
      toast.error("Failed to delete customer. Please try again.");
    }
  };

  const handleIsBlocked = async (userId, e) => {
    const isBlocked = e.target.checked;
    try {
      setFormData((prevData) => ({
        ...prevData,
        [userId]: { ...prevData[userId], blocking: isBlocked },
      }));
      await blockCustomer(userId, isBlocked);
      toast.success(
        `Customer ${isBlocked ? "blocked" : "unblocked"} successfully`
      );
      refetch(); // Refresh customer list
    } catch (err) {
      toast.error("Failed to update customer status. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching All Customers
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return customers.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Customers Available
      </h2>
    </div>
  ) : (
    <div className="bg-white rounded-xl shadow-pop overflow-hidden">
      <div className="relative custom-scroll overflow-x-auto">
        <table className="w-max min-w-full">
          <thead className="bg-mainGray">
            <tr>
              <th scope="col" className="px-6 py-4 font-medium">
                SL
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-start">
                Customer Name
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Contact Info
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Join Date
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Total Order
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Unblock / Block
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-primary">
            {customers.map((customer, index) => (
              <tr key={index}>
                <td className="px-6 py-3 text-center">{index + 1}</td>
                <td className="px-6 py-3">
                  <div className="USER flex items-center justify-start gap-4">
                    <div className="size-10 rounded-full">
                      <img
                        src={customer.imgUrl}
                        alt={customer.fullName}
                        className="size-full object-cover object-center rounded-full"
                      />
                    </div>
                    <p className="font-medium">{customer.fullName}</p>
                  </div>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{customer.phone}</p>
                  <p className="text-sm">{customer.email}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    {formatDate(new Date(customer.createdAt))}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{customer.orderCount}</p>
                </td>
                <td className="px-4 py-3">
                  <ToggleSwitch
                    name="blocking"
                    checked={
                      formData[customer._id]?.blocking || customer?.blocking
                    }
                    onChange={(e) => handleIsBlocked(customer._id, e)}
                    className="mx-auto w-10 h-5"
                  />
                </td>
                <td className="px-4 py-3">
                  <div className="flex justify-center items-center gap-4">
                    <button
                      onClick={() => navigate(`/customer/${customer._id}`)}
                    >
                      <FaRegEye className="text-info" />
                    </button>
                    <button
                      onClick={() =>
                        openModal(
                          <DeleteCustomer
                            onDelete={() => handleDelete(customer._id)}
                          />
                        )
                      }
                    >
                      <FaRegTrashAlt className="text-error" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {isFetching && <Loading />}
      </div>
       
      <Pagination
        currentPage={parseInt(page, 10)}
        totalCount={totalCount}
        pageSize={limit}
        onPageChange={(page) => setPage(page.toString())}
      />
    </div>
  );
};

export default CustomerList;


