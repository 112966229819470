import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const LineChart = ({ data }) => {
  // Prepare the data and options for Chart.js
  const chartData = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        label: "Total Orders",
        data: data.map((item) => item["total order"]),
        borderColor: "rgba(135, 206, 250, 1)", // Sky blue
        backgroundColor: "rgba(135, 206, 250, 0.3)", // Light sky blue
        borderWidth: 4, // Increased border width for bolder lines
        pointRadius: 6, // Slightly larger points
        pointBackgroundColor: "rgba(135, 206, 250, 1)", // Sky blue points
        fill: true, // Fill the area under the line
        tension: 0.4, // Smooth curves
      },
      {
        label: "Products Sold",
        data: data.map((item) => item["product sold"]),
        borderColor: "rgba(34, 197, 94, 1)", // Green
        backgroundColor: "rgba(34, 197, 94, 0.3)", // Light green
        borderWidth: 4, // Increased border width for bolder lines
        pointRadius: 6, // Slightly larger points
        pointBackgroundColor: "rgba(34, 197, 94, 1)", // Green points
        fill: true, // Fill the area under the line
        tension: 0.4, // Smooth curves
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "#000000",
        bodyColor: "#000000",
        borderColor: "#ccc",
        borderWidth: 1,
        borderRadius: 10,
        displayColors: false, // Hide color boxes in tooltips
      },
      legend: {
        labels: {
          color: "#000000", // Color for legend text
          boxWidth: 12,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#000000", // X-axis ticks color
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)", // Light grid color
        },
        ticks: {
          color: "#000000", // Y-axis ticks color
        },
      },
    },
  };

  return (
    <div className="w-full h-80 bg-white p-4 rounded-xl mt-3">
      <div className="bg-gradient-to-r from-sky-100 to-green-100 p-4 rounded-lg h-full">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default LineChart;
