import toKebabCase from "../../utils/kebabize";
import { motion } from "framer-motion";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";

const Nav = ({ tabs }) => {
  const [tab, setTab] = useSearchParamsState("tab", "general");

  return (
    <nav
      id="BUSINESS-SETUP-NAV"
      className="w-full pb-2 overflow-x-auto scroll-smooth"
    >
      <ul className="flex gap-6 w-max">
        {tabs.map((item) => {
          const urlString = toKebabCase(item.title);

          return (
            <li
              key={item.title}
              onClick={() => setTab(urlString)}
              className={`relative whitespace-nowrap px-2 py-1 text-sm font-medium cursor-pointer ${
                urlString === tab ? "text-primeRed" : "text-secondary"
              }`}
            >
              {item.title}
              {urlString === tab && (
                <motion.span
                  initial={{ opacity: 0, transitionDuration: 2 }}
                  animate={{ opacity: 1 }}
                  layoutId="business-setup-tab"
                  className="absolute inset-0 border border-primeRed rounded-full"
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
