import { useCallback, useEffect, useState } from "react";
import {
  getAllOrders,
  getOrderById,
  getStatusHistory,
  updateOrderStatus,
} from "../api/orderApis";

const useOrder = (orderId, status, page, limit, startDate, endDate) => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [statusCount, setStatusCount] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const fetchAllOrders = useCallback(async () => {
    if (orderId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getAllOrders({
        status,
        page,
        limit,
        startDate,
        endDate,
      });
      if (response?.status) {
        console.log(response.data);
        setOrders(response.data.orderDetails);
        setTotalOrders(response.data.totalOrders);
        setStatusCount(response.data.statusCount);
      }
    } catch (error) {
      console.error("Error fetching orders", error);
      setError("Failed to fetch orders. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [orderId, status, page, limit, startDate, endDate]);

  const fetchOrder = useCallback(async () => {
    if (!orderId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getOrderById(orderId);
      if (response?.status) {
        console.log(response.data);
        setOrder(response.data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
      setError("Failed to fetch order details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  const fetchStatusHistory = useCallback(async () => {
    if (!orderId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getStatusHistory(orderId);
      if (response.status) {
        console.log(response.data);
        setStatusHistory(response.data.statusDetails.orderStatusDetails);
      }
    } catch (error) {
      console.error("Error fetching status history", error);
      setError("Failed to fetch status history. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  const updateStatus = useCallback(async (orderId, status) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateOrderStatus(orderId, status);
      if (response?.status) {
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error updating order status", error);
      setError("Failed to update order status. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Listen for the 'orderStatusUpdated' event from the socket
  

  useEffect(() => {
    fetchAllOrders();
  }, [fetchAllOrders]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  useEffect(() => {
    fetchStatusHistory();
  }, [fetchStatusHistory]);

  return {
    orders,
    order,
    totalOrders,
    statusCount,
    statusHistory,
    updateStatus,
    loading,
    error,
  };
};

export default useOrder;
