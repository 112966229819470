import { useState } from "react";

const ColorInput = ({
  name,
  label,
  value = "",
  onChange = () => {},
  required,
  align = "top",
  className = "size-20",
  containerClassName = "gap-2",
}) => {
  const id = name?.replace(" ", "-").toLowerCase();
  const [selectedColor, setSelectedColor] = useState("#000000");

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className={`w-max flex flex-col items-center ${containerClassName}`}>
      {label && align === "top" ? (
        <label htmlFor={id} className="text-sm font-medium">
          {label}
        </label>
      ) : (
        <></>
      )}

      <input
        id={id}
        type="color"
        name={name}
        value={value}
        onChange={handleColorChange}
        required={required}
        className={`border border-secondary [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none ${className}`}
      />
      <p className="text-xs text-secondary font-medium text-center uppercase">
        {selectedColor}
      </p>

      {label && align === "bottom" ? (
        <label htmlFor={id} className="text-sm font-medium">
          {label}
        </label>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ColorInput;
