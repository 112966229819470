import axiosInstance from "./axiosInstance";

const getAllGiftCards = async () => {
  const response = await axiosInstance.get("/getAllGiftCard");
  return response.data?.newGiftCardTemplate;
};

const getAllTemplates = async () => {
  const response = await axiosInstance.get("/getAllTemplates");
  return response.data?.allTemplates;
};

const getTemplateById = async (templateId) => {
  const response = await axiosInstance.get(`/getTemplateId/${templateId}`);
  return response.data?.templatesDetails;
};

const createTemplate = (templateData) => {
  return axiosInstance.post("/createGiftCardTemplate", templateData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateTemplate = (templateId, templateData) => {
  return axiosInstance.post(`/editTemplateId/${templateId}`, templateData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteTemplate = (templateId) => {
  return axiosInstance.delete(`/deleteTemplate/${templateId}`);
};

export {
  getAllGiftCards,
  getAllTemplates,
  getTemplateById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};
