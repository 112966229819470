import { useState } from "react";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";

const SubMenu = ({ title, data, activeItem, setActiveItem, isCollapsed }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Handle main menu item click
  const handleClick = () => {
    navigate(data.url);
    setSubMenuOpen(!subMenuOpen);
    setActiveItem(title);
  };

  return (
    <>
      {/* Main Menu Item */}
      <li
        className={`flex items-center justify-between cursor-pointer m-1 ml-2 rounded-md ${activeItem === title ? "bg-primeRed text-white pr-4" : "text-gray-700 dark:text-gray-300"
          }`}
        onClick={handleClick}
      >
        <div
          className={`flex items-center gap-2 p-2 rounded-md ${activeItem === title ? "bg-primeRed text-white" : "hover:bg-slate-200 dark:hover:bg-slate-700"
            }`}
          style={{
            paddingLeft: isCollapsed ? "1rem" : "1rem",
            paddingRight: isCollapsed ? "1rem" : "1rem",
            justifyContent: isCollapsed ? "center" : "flex-start",
            width: isCollapsed ? "100%" : "auto",
          }}
        >
          <p className="text-lg flex flex-row items-center">
            {data.icon}
            {/* {isCollapsed &&
              <p className={`text-sm flex flex-row items-center absolute opacity-0 left-5 pl-2 p-2 pr-10 bg-white-100 rounded-md shadow-sm transition-opacity duration-300 hover:opacity-100 ${activeItem === title ? 'bg-primeRed text-white' : 'bg-white text-black'}`}>
                <p className="text-lg pr-2 pl-1">{data.icon}</p>
                {title}
              </p>
            } */}

          </p>
          {/* Render title only if not collapsed */}
          
            <NavLink
              to={data.url}
              className="text-sm capitalize font-secondary font-medium"
              onClick={() => {
                setActiveItem(title);
                navigate(data.url);
              }}
            >
              {title}
            </NavLink>
          
        </div>

        {/* Dropdown Arrow Icon */}
        {data.subLinks &&  (
          <IoIosArrowDown
            onClick={() => setSubMenuOpen(!subMenuOpen)}
            className={`transform ${subMenuOpen ? "rotate-180" : ""} ${activeItem === title && "text-white"} duration-200 text-gray-600 dark:text-gray-400`}
          />
        )}
      </li>

      {/* Collapsible Submenu */}
      <motion.ul
        initial={{ height: 0 }}
        animate={{ height: subMenuOpen ? "auto" : 0 }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden pl-8 list-disc text-gray-500 dark:text-gray-400"
        style={{
          paddingLeft: isCollapsed ? "0.5rem" : "2rem",
        }}
      >
        {data.subLinks?.map((subLink, index) => (
          <li key={index} className="py-1">
            <NavLink
              to={subLink.url}
              className={({ isActive }) =>
                `capitalize text-sm font-secondary px-2 py-1 rounded-md block ${isActive ? "bg-primeRed text-white" : "text-gray-600 dark:text-gray-400"
                }`
              }
            >
              {subLink.title}
            </NavLink>
          </li>
        ))}
      </motion.ul>
    </>
  );
};

export default SubMenu;
