import { MdColorLens } from "react-icons/md";
import ColorInput from "../../../components/ColorInput";

const WebsiteColor = () => {
  return (
    <div className="WEBSITE-COLOR bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 p-4 border-b-2 border-secondary">
        <MdColorLens />
        <p className="font-medium">Website Color</p>
      </div>
      <div className="flex items-center justify-center gap-8 p-4">
        <ColorInput
          label="Primary Color"
          align="bottom"
          className="w-32 h-20"
        />
        <ColorInput
          label="Secondary Color"
          align="bottom"
          className="w-32 h-20"
        />
      </div>
    </div>
  );
};

export default WebsiteColor;
