import { Fragment, useEffect, useRef, useState } from "react";
import { FiSun, FiMoon, FiMenu } from "react-icons/fi"; // Hamburger menu icon for collapse toggle
import SubMenu from "./SubMenu";
import { sidebarLinks,sidebarDesigner } from "../../utils/constants";
import Logo from "../../components/Logo";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useAdmin } from "../../contexts/adminContext";
import Drawer from "./Drawer";

const Sidebar = ({setIsCollapsed,isCollapsed,isOpen,setIsOpen}) => {
  const navRef = useRef(null);
  const [scroll, setScroll] = useState(0);
  const [activeItem, setActiveItem] = useState("");
  const [sidebar, setSidebar] = useState(null);
  
  // const [isCollapsed, setIsCollapsed] = useState(false); // Collapsed state
  const [darkMode, setDarkMode] = useState(false); // Dark mode state
  const { scrollY } = useScroll({ container: navRef });
  const admin = useAdmin()
  // Update scroll position
  useMotionValueEvent(scrollY, "change", (latest) => {
    setScroll(latest);
  });

  useEffect(()=>{
   if(admin.admin.userLevel <= 1){
    setSidebar(sidebarLinks)

   }else{
    setSidebar(sidebarDesigner)
   }
  },[])


  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
    document.documentElement.classList.toggle("dark"); // Toggle dark mode in the document
  };

  // Toggle collapse
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <section
      className={`${
        isCollapsed ? "w-0" : "w-64"
      } flex-shrink-0 h-screen flex flex-col border-r bg-gray-100 dark:bg-gray-900 border-primary transition-all duration-300 z-10`}
    >
      {/* Top Section with Logo and Collapse Button */}
      {/* <div className={`p-6 ${scroll > 0 ? "shadow-md" : ""}`}>
        <div className="flex justify-between items-center">
          {!isCollapsed && <Logo />}
          <button onClick={toggleCollapse} className="text-xl">
            <FiMenu className={`transform ${isCollapsed ? "rotate-180" : ""}`} />
          </button>
        </div>
      </div> */}

      {/* Sidebar Navigation Links */}
      { isCollapsed ?
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <nav
        ref={navRef}
        style={{
          scrollbarWidth: "none", // Firefox
          msOverflowStyle: "none", // IE and Edge
        }}
        className="flex-1 overflow-y-scroll"
      >
       
        <ul className="px-2 my-2 space-y-3 shadow-lg pt-10 rounded-sm">
          {sidebar && Object.entries(sidebar).map(([heading, links], index) => (
            <Fragment key={index}>
              <div className="pb-3 pt-1 rounded-md bg-white shadow-sm pr-3 m-2">
                {!isCollapsed && (
                  <h4 className="capitalize text-xs text-gray-500 mb-1 dark:text-gray-400 font-secondary mt-4 ml-5">
                    {heading}
                  </h4>
                )}
                {Object.entries(links).map(([link, linkData], index) => (
                  <SubMenu
                    key={index}
                    title={link}
                    data={linkData}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    isCollapsed={isCollapsed} // Pass collapsed state to SubMenu
                  />
                ))}
              </div>
            </Fragment>
          ))}
        </ul>
        
      </nav>
      </Drawer> :
      <nav
      ref={navRef}
      style={{
        scrollbarWidth: "none", // Firefox
        msOverflowStyle: "none", // IE and Edge
      }}
      className="flex-1 overflow-y-scroll"
    >
     
      <ul className="px-2 my-2 space-y-3 shadow-lg pt-20 rounded-sm">
        {sidebar && Object.entries(sidebar).map(([heading, links], index) => (
          <Fragment key={index}>
            <div className="pb-3 pt-1 rounded-md bg-white shadow-sm pr-3 m-2">
              {!isCollapsed && (
                <h4 className="capitalize text-xs text-gray-500 mb-1 dark:text-gray-400 font-secondary mt-4 ml-5">
                  {heading}
                </h4>
              )}
              {Object.entries(links).map(([link, linkData], index) => (
                <SubMenu
                  key={index}
                  title={link}
                  data={linkData}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  isCollapsed={isCollapsed} // Pass collapsed state to SubMenu
                />
              ))}
            </div>
          </Fragment>
        ))}
      </ul>
      
    </nav>
      }

      {/* Light/Dark Mode Toggle */}
      <div className="p-4 flex justify-between items-center">
        {!isCollapsed && (
          <span className="text-xs text-gray-500 dark:text-gray-400 font-secondary">
            Theme
          </span>
        )}
        <div className="flex items-center gap-2">
          <button
            onClick={() => setDarkMode(false)}
            className={`p-1 rounded-full ${
              !darkMode ? "bg-blue-500 text-white" : "bg-gray-300 dark:bg-gray-700"
            }`}
          >
            <FiSun />
          </button>
          <button
            onClick={() => setDarkMode(true)}
            className={`p-1 rounded-full ${
              darkMode ? "bg-blue-500 text-white" : "bg-gray-300 dark:bg-gray-700"
            }`}
          >
            <FiMoon />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
