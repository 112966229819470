import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getProduct, getVariant, getVariants } from "../api/productApis";

const useProductById = (productId) => {
  return useQuery({
    queryKey: ["product", productId],
    queryFn: () => getProduct(productId),
    staleTime: 1000 * 60 * 5,
    enabled: !!productId,
  });
};

const useVariants = (params) => {
  return useQuery({
    queryKey: ["variants", params],
    queryFn: () => getVariants(params),
    staleTime: 1000 * 60 * 5, // Cache the data for 5 minutes
    keepPreviousData: true, // Retain previous data during query transitions
    select: (data) => {
      // Ensure proper structure and default values
      return {
        variants: data.variants || [],
        page: data.page || 1,
        limit: data.limit || 10,
        totalCount: data.total || 0,
      };
    },
  });
};

const useVariantById = (variantId) => {
  return useQuery({
    queryKey: ["variant", variantId],
    queryFn: () => getVariant(variantId),
    staleTime: 1000 * 60 * 5,
    enabled: !!variantId,
  });
};

export { useProductById, useVariants, useVariantById };
