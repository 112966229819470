import { IoBusiness } from "react-icons/io5";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";

const CompanyInfo = () => {
  return (
    <div className="COMPANY-INFORMATION bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 p-4 border-b-2 border-secondary">
        <IoBusiness />
        <p className="font-medium">Company Information</p>
      </div>
      <div className="grid grid-cols-3 gap-x-16 gap-y-3 p-4">
        <InputBox label="company name" name="companyName" value="" />
        <InputBox label="phone" />
        <InputBox label="email" />
        <InputBox label="company address" />
        <InputBox label="latitude" />
        <InputBox label="longitude" />
        <SelectBox label="timezone" />
        <SelectBox label="country" />
      </div>
    </div>
  );
};

export default CompanyInfo;
