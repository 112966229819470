import { MdOutlineCalendarToday } from "react-icons/md";
import { formatDate } from "../../utils/dateUtils";
import { getColorForStatus } from "../../utils/constants";
import { Link } from "react-router-dom";

const PurchaseTable = ({ order }) => {
  return (
    <div className="DETAILS bg-white border border-darkGray rounded-xl shadow-pop">
      <div className="px-6 py-2 flex justify-between items-center border-b border-primary">
        <p className="text-lg font-medium">Product</p>

        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <MdOutlineCalendarToday />
            <p className="font-medium text-sm">
              {formatDate(new Date(order.createdAt))}
            </p>
          </div>
          <div
            className={`${getColorForStatus(
              order.status
            )} rounded-full text-white font-secondary text-xxs py-[2px] px-2 w-max`}
          >
            {order.status}
          </div>
        </div>
      </div>
      <div className="w-full overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-lightGray font-secondary">
              <th scope="col" className="px-6 py-4 text-left font-medium">
                SL.
              </th>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                Product
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                SKU
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                QTY
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Price
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Discount
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {order.orderItem?.map((item, index) => (
              <tr key={index} className="border-b border-secondary">
                <td className="px-6">{index + 1}</td>
                <td className="px-4 py-3">
                  {item.productId ? (
                    <Link
                      to={`/product/${item.productId._id}`}
                      className="PRODUCT-MAIN flex gap-4"
                    >
                      <div className="size-12 flex-shrink-0">
                        <img
                          src={item.productId?.images[0]}
                          alt={item.productId?.productGroup?.name}
                          className="size-full object-contain object-center"
                        />
                      </div>
                      <div className="text-xs">
                        <h3 className="font-medium">{item.productId?.name}</h3>
                        {item?.productId?.attributes &&
                          Object.keys(item?.productId?.attributes)
                            .slice(0, 3)
                            .map((key) => (
                              <p key={key} className="capitalize">
                                {key}: {item?.productId?.attributes[key]}
                              </p>
                            ))}
                      </div>
                    </Link>
                  ) : (
                    <div className="size-12 bg-lightGray flex items-center justify-center text-primary rounded text-xxs text-center">
                      Invalid Product
                    </div>
                  )}
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{item.productId?.sku}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{item.itemQuantity}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    ₹ {item.productId?.unitPrice * item.itemQuantity}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    ₹{" "}
                    {(item.productId?.unitPrice -
                      item.productId?.sellingPrice) *
                      item.itemQuantity}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    ₹ {item.productId?.sellingPrice * item.itemQuantity}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul className="TABLE-FOOTER space-y-1 ml-auto w-80 px-6 py-2 text-sm">
          <li className="flex justify-between gap-12">
            <p className="text-secondary">Subtotal</p>
            <p>₹ {order.subtotal}</p>
          </li>
          <li className="flex justify-between gap-12">
            <p className="text-secondary">Discount</p>
            <p className="text-success">- ₹ {order.discount}</p>
          </li>
          <li className="flex justify-between gap-12">
            <p className="text-secondary">Utsav Discount</p>
            <p className="text-success">- ₹ {order.utsavDiscount}</p>
          </li>
          <li className="flex justify-between gap-12">
            <p className="text-secondary">Coupon Discount</p>
            <p className="text-success">- ₹ {order.couponDiscount}</p>
          </li>
          <li className="flex justify-between gap-12">
            <p className="text-secondary">Wallet Balance Used</p>
            <p className="text-success">- ₹ {order.walletBalanceUsed}</p>
          </li>
          <li className="flex justify-between gap-12">
            <p className="text-secondary">GST (incl)</p>
            <p className="text-primary">₹ {order.tax.toFixed(2)}</p>
          </li>
          <li className="flex justify-between gap-12">
            <p className="text-secondary">Shipping</p>
            <p>
              {order.shippingCost > 0 ? (
                `₹ ${order.shippingCost}`
              ) : (
                <span className="text-xs text-green-500">Free delivery</span>
              )}
            </p>
          </li>
          <li className="flex justify-between gap-6 text-base font-medium">
            <p>Total</p>
            <p>₹ {order.totalPrice}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PurchaseTable;
