import { useState } from "react";
import { trimFilename } from "../utils/textTrimmer";

const FileInput = ({
  name,
  label,
  onChange,
  accept = ".jpg, .jpeg, .png",
  align = "left",
  required = false,
  multiple = false,
  dynamic = false,
  placeholder = "Select File",
  button = false,
  className = "px-4 py-2 bg-offWhite",
  outerClassName = "border border-secondary rounded-md",
  containerClassName = "gap-4",
}) => {
  const id = name?.replace(" ", "-").toLowerCase();
  const [selectedName, setSelectedName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedName("");
    } else {
      setSelectedName(file.name);
    }
    onChange(event);
  };

  return (
    <div
      className={`flex ${
        align === "top" ? "flex-col items-start" : "items-center"
      } ${containerClassName}`}
    >
      {label && align === "top" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}

      {label && align === "left" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}

      <div
        className={`FILE-UPLOAD w-full relative flex items-center justify-between overflow-hidden ${outerClassName}`}
      >
        <div
          className={`FIELD overflow-hidden truncate flex-grow ${className}`}
        >
          {dynamic && selectedName ? (
            <p className="text-xs">{trimFilename(selectedName, 13)}</p>
          ) : (
            <p className="text-xs text-primary">{placeholder}</p>
          )}

          <input
            id={id}
            type="file"
            name={name}
            onChange={handleFileChange}
            accept={accept}
            multiple={multiple}
            required={required}
            className="block absolute inset-0 opacity-0 cursor-pointer file:cursor-pointer"
          />
        </div>
        {button && (
          <button
            type="button"
            className={`border-l border-primary text-xs ${className}`}
          >
            Browse
          </button>
        )}
      </div>

      {label && align === "right" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}
    </div>
  );
};

export default FileInput;
