import { MdFileDownload } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import GalleryList from "./GalleryList";
import SearchBox from "../../../components/SearchBox";
import Button from "../../../components/Button";

const Gallery = () => {
  const navigate = useNavigate();

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Gallery</h1>
      <div className="HEADING flex justify-between items-center">
        <SearchBox name="search" placeHolder="search by name" />
        <div className="flex gap-4">
          <Button
            reverse
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <MdFileDownload className="mt-[3px]" />
            <p className="text-sm">export</p>
          </Button>
          <Button
            onClick={() => navigate("/gallery/add")}
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <FiPlus />
            <p className="text-sm">add new</p>
          </Button>
        </div>
      </div>
      <GalleryList />
    </section>
  );
};

export default Gallery;
