import SearchBox from "../../components/SearchBox";
import { FaRegTrashAlt } from "react-icons/fa";
import SelectBox from "../../components/SelectBox";
import Button from "../../components/Button";
import useDeal from "../../hooks/useDeal";
import { useParams } from "react-router-dom";
import { useToast } from "../../contexts/toastContext";
import useForm from "../../hooks/useForm";
import { useVariants } from "../../services/productServices";
import { useFlashDealById } from "../../services/dealServices";

const AddProductOnFlashDeal = () => {
  const { dealId } = useParams();
  const { data: deal, isLoading, error } = useFlashDealById(dealId);
  const { addProductOnDeal, removeProductOnDeal, loading } = useDeal(
    "flash",
    dealId
  );
  const { data: variants, isLoading: productsLoading } = useVariants();
  const toast = useToast();

  const onSubmitHandler = (dealData) => {
    addProductOnDeal(dealId, dealData).then(() => {
      toast.success("Product added successfully");
      clearForm();
    });
  };

  const { formData, handleChange, handleSubmit, clearForm } = useForm(
    {},
    onSubmitHandler
  );

  // console.log(deal)

  const handleDelete = (productId) => {
    removeProductOnDeal(dealId, productId).then(() => {
      toast.success("Product removed successfully");
    });
  };

  if (isLoading || !deal) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    deal && (
      <section className="p-4 space-y-4">
        <h1 className="text-3xl font-medium">Add Product</h1>

        <div className="bg-white rounded-xl shadow-pop">
          <h3 className="text-xl font-medium p-4 border-b border-primary">
            {deal?.title}
          </h3>
          <form onSubmit={handleSubmit} className="p-4 space-y-6">
            <SelectBox
              label="add new product"
              name="products"
              value={formData.products}
              onChange={handleChange}
              options={variants?.map((variant) => ({
                label: variant?.productGroup?.name + " " + variant?.sku,
                value: variant._id,
              }))}
              searchable
              multi
              disabled={productsLoading}
            />
            <div className="ml-auto w-max">
              <Button disabled={loading} className="px-6 py-2 rounded-lg">
                add
              </Button>
            </div>
          </form>
        </div>

        {!deal ? (
          <div className="w-full">
            <h2 className="text-center font-secondary font-medium text-xl mt-8">
              Fetching products
            </h2>
          </div>
        ) : deal.products.length === 0 ? (
          <div className="w-full">
            <h2 className="text-center font-secondary font-medium text-xl mt-8">
              No products added
            </h2>
          </div>
        ) : (
          <div className="bg-white rounded-xl shadow-pop overflow-hidden mt-4">
            <div className="p-4 flex justify-between items-center">
              <div className="HEADING flex items-center gap-4">
                <h3 className="text-xl font-medium">Added Products</h3>
                <span className="bg-mainGray size-5 rounded-full flex justify-center items-center text-xs font-medium">
                  {deal.products.length}
                </span>
              </div>
              <SearchBox placeHolder="Search by deal name" />
            </div>
            <div className="w-full flex flex-col justify-between">
              <table className="w-full">
                <thead className="bg-lightGray">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left font-medium">
                      SL
                    </th>
                    <th scope="col" className="px-6 py-4 font-medium">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 font-medium">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-4 font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deal && (
                    deal?.products?.map((product, index) => (
                      <tr key={index}>
                        <td className="px-6 py-3">{index + 1}</td>
                        <td className="px-6 py-3 text-center">
                          <p className="text-sm">
                            {product?.productGroup.name + " " + product.sku}
                          </p>
                        </td>
                        <td className="px-4 py-3 text-center">
                          <p className="text-sm">{product.sellingPrice}</p>
                        </td>
                        <td className="px-4 py-3">
                          <div className="flex justify-center items-center gap-4">
                            <button onClick={() => handleDelete(product._id)}>
                              <FaRegTrashAlt className="text-error" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) }
                </tbody>
              </table>
            </div>
          </div>
        )}
      </section>
    )
  );
};

export default AddProductOnFlashDeal;
