import PropTypes from "prop-types";

const Button = ({
  children,
  type = "submit",
  className = "px-4 py-2 rounded-lg",
  onClick,
  disabled = false,
  reverse = false,
}) => {
  return reverse ? (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`block capitalize bg-white text-primeRed hover:bg-lightGray border border-primeRed transition-colors duration-200 ease-in-out disabled:bg-mainGray disabled:cursor-not-allowed ${className}`}
    >
      {children}
    </button>
  ) : (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`block capitalize bg-primeRed text-white hover:bg-hotPink transition-colors duration-200 ease-in-out disabled:bg-mainGray disabled:cursor-not-allowed ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  advance: PropTypes.bool,
};
