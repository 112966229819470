import axiosInstance from "./axiosInstance";

const getAllBrands = async () => {
  const response = await axiosInstance.get("/getBrand");
  return response.data?.brandList;
};

const getBrand = async (brandId) => {
  const response = await axiosInstance.get(`/getBrandById/${brandId}`);
  return response.data?.brandDetails;
};

const createBrand = async (brandData) => {
  const response = await axiosInstance.post("/createBrand", brandData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const updateBrand = async (brandId, brandData) => {
  const response = await axiosInstance.post(
    `/editBrand/${brandId}`,
    brandData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const deleteBrand = async (brandId) => {
  const response = await axiosInstance.get(`/deleteBrand/${brandId}`);
  return response.data;
};

const featureBrand = async (brandId, featured) => {
  const response = await axiosInstance.post(`/featuredBrand/${brandId}`, {
    featured,
  });
  return response.data;
};

const utsavFeatureBrand = async (brandId, utsavFeatured) => {
  const response = await axiosInstance.post(
    `/markAsaUtsavFeaturedBrand/${brandId}`,
    {
      utsavFeatured,
    }
  );
  return response.data;
};

export {
  getAllBrands,
  getBrand,
  createBrand,
  updateBrand,
  deleteBrand,
  featureBrand,
  utsavFeatureBrand,
};
