import { FaRegEye, FaRegFilePdf } from "react-icons/fa";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../../utils/dateUtils";
import OrderStatus from "./OrderStatus";
import { getColorForStatus } from "../../../utils/constants";
import { useUtsavOrders } from "../../../services/utsavServices";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import Loading from "../../../components/Loading";

const OrderList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useSearchParamsState("page", 1);
  const [limit] = useSearchParamsState("limit", 10);
  const [startDate] = useSearchParamsState("startDate");
  const [endDate] = useSearchParamsState("endDate");
  const [status, setStatus] = useSearchParamsState("status");

  const { data, isLoading, isFetching, error } = useUtsavOrders({
    status,
    page,
    limit,
    startDate,
    endDate,
  });

  const handleStatusChange = (newStatus) => {
    setPage(1);
    if (newStatus === "All Orders") {
      setStatus("");
    } else {
      setStatus(newStatus);
    }
  };

  if (isLoading || (!error && !data)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching All Orders
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-pop overflow-hidden">
      <OrderStatus
        currentStatus={status}
        onStatusChange={handleStatusChange}
        statusCount={data?.statusCount}
      />
      <div className="relative w-full min-h-[720px] flex flex-col justify-between">
        <table className="w-full">
          <thead className="bg-lightGray">
            <tr>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                SL
              </th>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                Order ID
              </th>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                Products
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Date/Time
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Customer
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Payment
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Price
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Status
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.orderDetails?.map((order, index) => (
              <tr key={index} className="border-b border-primary">
                <td className="px-6 py-3">{index + 1}</td>
                <td className="px-6 py-3 text-sm">
                  #{order._id.slice(0, 8) + "..."}
                </td>
                <td className="px-6 py-3 space-y-1">
                  {order.orderItem?.slice(0, 1)?.map((item, index) => (
                    <div
                      key={index}
                      className="PRODUCT-MAIN flex gap-4 cursor-pointer"
                      onClick={() => navigate(`/order/${order._id}`)}
                    >
                      {item.productId ? (
                        <>
                          <div className="size-12 flex-shrink-0">
                            <img
                              src={item.productId?.images[0]}
                              alt={index}
                              className="size-full object-center object-contain"
                            />
                          </div>
                          <div className="text-xs">
                            <h3 className="font-medium">
                              {item.productId?.name}
                            </h3>
                            {item?.productId?.attributes &&
                              Object.keys(item?.productId?.attributes)
                                .slice(0, 3)
                                .map((key) => (
                                  <p key={key} className="capitalize">
                                    {key}: {item?.productId?.attributes[key]}
                                  </p>
                                ))}
                          </div>
                        </>
                      ) : (
                        <div className="size-12 bg-lightGray flex items-center justify-center text-primary rounded text-xxs text-center">
                          Invalid Product
                        </div>
                      )}
                    </div>
                  ))}
                  {order?.orderItem.length > 1 && (
                    <p className="text-xs font-medium">
                      +{order.orderItem.length - 1} more
                    </p>
                  )}
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    {formatDate(new Date(order.createdAt))}
                  </p>
                  <p className="text-sm">
                    {formatTime(new Date(order.createdAt))}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{order.address.receiverName}</p>
                  <p className="text-sm">{order.address.receiverPhone}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{order.payment_method}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p>₹ {order.totalPrice}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <div
                    className={`${getColorForStatus(
                      order.status
                    )} rounded-full text-white font-secondary text-xxs py-[2px] px-2 w-max mx-auto`}
                  >
                    {order.status}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex justify-center items-center gap-4">
                    <button onClick={() => navigate(`/order/${order._id}`)}>
                      <FaRegEye className="text-info" />
                    </button>
                    <button>
                      <FaRegFilePdf className="text-blue" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {isFetching && <Loading />}

        <Pagination
          currentPage={parseInt(page, 10)}
          totalCount={data?.totalOrders}
          pageSize={limit}
          onPageChange={(page) => setPage(page.toString())}
        />
      </div>
    </div>
  );
};

export default OrderList;
