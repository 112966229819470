import { useCallback, useState } from "react";
import { blockUnblockCustomer, deleteCustomer } from "../api/customerApis";

const useCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const removeCustomer = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await deleteCustomer(userId);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("customers");
        sessionStorage.removeItem(`customer_${userId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const blockCustomer = useCallback(async (userId, status) => {
    setLoading(true);
    setError(null);
    try {
      const response = await blockUnblockCustomer(userId, status);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("customers");
        sessionStorage.removeItem(`customer_${userId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    removeCustomer,
    blockCustomer,
    loading,
    error,
  };
};

export default useCustomer;
