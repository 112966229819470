import Tracker from "../../components/Tracker";

const OrderTracker = ({ order, statusHistory }) => {
  return (
    <div className="bg-white border border-darkGray rounded-xl shadow-pop">
      <p className="font-medium text-lg px-6 py-2 border-b border-primary">
        Order Status
      </p>
      <Tracker statusHistory={statusHistory} currentStatus={order.status} />
    </div>
  );
};

export default OrderTracker;
