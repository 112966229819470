import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangeSelector = ({ onDateChange = () => {} }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    // Call onDateChange whenever the date range changes
    if (startDate && endDate) {
      onDateChange(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0]
      );
    }
  }, [startDate, endDate, onDateChange]);

  return (
    <div className="flex flex-col items-center gap-4">
      <label className="block text-sm font-medium text-gray-700">
        Select Date Range
      </label>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => setDateRange(update)}
        dateFormat="yyyy-MM-dd"
        withPortal
        className="px-2 py-1 h-10 w-65 border border-secondary rounded-md text-sm"
      />
    </div>
  );
};

export default DateRangeSelector;
