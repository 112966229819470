import { MdOutlineLocationOn, MdOutlinePhone } from "react-icons/md";

const Address = ({ address }) => {
  return (
    <div className="w-90 h-44 rounded-xl bg-blue-100 shadow-pop">
      <div className="HEAD flex items-center gap-2 px-4 py-2 border-b border-primary">
        <span className="bg-rose p-1 rounded-full text-blue-500">
          <MdOutlineLocationOn />
        </span>
        <h2 className="font-medium">Shipping Address</h2>
      </div>

      <div className="BODY px-4 py-2">
        {address ? (
          <>
            <div className="flex items-center gap-2 font-medium leading-tight">
              <h3>{address.receiverName}</h3>
              <p className="uppercase text-xxs pt-[2px]">
                ({address.addressType})
              </p>
            </div>

            <div className="flex justify-start items-center text-xs text-secondary">
              <MdOutlinePhone size={14} className="mr-2" />
              <p>{address.receiverPhone || "N/A"}</p>
              {address?.alternatePhone && (
                <p>
                  <span className="mx-1">/</span>
                  {address.alternatePhone}
                </p>
              )}
            </div>

            <div className="text-xs font-medium text-secondary flex flex-wrap gap-x-1 mt-2">
              {address?.houseNumber && <p>{address.houseNumber},</p>}
              {address?.street && <p>{address.street},</p>}
              {address?.locality && <p>{address.locality},</p>}
              {address?.landmark && <p>{address.landmark},</p>}
              {address?.city && <p>{address.city},</p>}
              {address?.state && <p>{address.state},</p>}
              {address?.pinCode && <p>{address.pinCode}</p>}
            </div>
          </>
        ) : (
          <p className="my-4">None</p>
        )}
      </div>
    </div>
  );
};

export default Address;
