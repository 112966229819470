import {
  getTotalCustomer,
  getTotalIncome,
  getTotalOrder,
  getTotalSale,
} from "../api/dashboardApis";
import { useFetchWithCache } from "./useFetchWithCache";

export const useAnalytics = () => {
  const {
    data: totalIncome,
    loading: totalIncomeLoading,
    error: totalIncomeError,
    refetch: refetchTotalIncome,
  } = useFetchWithCache(getTotalIncome);

  const {
    data: totalOrder,
    loading: totalOrderLoading,
    error: totalOrderError,
    refetch: refetchTotalOrder,
  } = useFetchWithCache(getTotalOrder);

  const {
    data: totalSale,
    loading: totalSaleLoading,
    error: totalSaleError,
    refetch: refetchTotalSale,
  } = useFetchWithCache(getTotalSale);

  const {
    data: totalCustomer,
    loading: totalCustomerLoading,
    error: totalCustomerError,
    refetch: refetchTotalCustomer,
  } = useFetchWithCache(getTotalCustomer);

  const loading =
    totalIncomeLoading ||
    totalOrderLoading ||
    totalSaleLoading ||
    totalCustomerLoading;
  const error =
    totalIncomeError || totalOrderError || totalSaleError || totalCustomerError;

  return {
    totalIncome,
    totalOrder,
    totalSale,
    totalCustomer,
    loading,
    error,
    refetchAll: () => {
      refetchTotalIncome();
      refetchTotalOrder();
      refetchTotalSale();
      refetchTotalCustomer();
    },
  };
};
