import { useEffect, useState } from "react";
import { formatDate, formatTime } from "../../utils/dateUtils";
import SearchBar from "../../components/SearchBar";
import { MdOutlineMessage, MdOutlineNotifications,MdOutlineSearch } from "react-icons/md";
import AccountTab from "./AccountTab";
import { FiMenu } from "react-icons/fi";
import Logo from "../../components/Logo";

const Navbar = ({setIsCollapsed,isCollapsed,isOpen, setIsOpen}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  // const [isCollapsed, setIsCollapsed] = useState(false); 
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);
 
  const toggleCollapse = () => setIsOpen(!isOpen);
  return (
    <header className="w-full absolute right-0 left-0 top-0 justify-between items-center z-10 p-2 pr-4  backdrop-blur-md bg-white/30 border border-white/20 shadow-sm overflow-hidden">
      {/* Glass Reflection Overlay */}
      <div
        className="absolute inset-0 pointer-events-none rounded-lg"
        style={{
          background: "linear-gradient(135deg, rgba(255, 255, 255, 0.15), transparent)",
          opacity: 0.1,
        }}
      ></div>



      <nav className="flex  justify-between items-center w-full">
      <div className={`p-2`}>
        <div className="flex justify-between items-center h-[50%] px-1">
          {!isCollapsed && 
          <div className="w-40">
          <Logo />
          </div>
          }
          {isCollapsed &&<button onClick={toggleCollapse} className="text-xl">
            <FiMenu className={`transform ${isCollapsed ? "rotate-180" : ""}`} />
          </button>}
        </div>
      </div>
        <div className="WELCOME-SECTION">
          <h1 className="text-primeRed text-xl font-bold ">Welcome </h1>
          {!isCollapsed &&<div className="flex items-center gap-2 text-violet-950 font-bold text-sm ">
            <p>{formatDate(currentDate)}</p>
            <span className="block h-4 w-[1px] bg-black" />
            <p>{formatTime(currentDate)}</p>
          </div>}
        </div>

        {/* <SearchBar /> */}

        <div className="NAV-ITEMS flex items-center gap-10">
          <button>
            <MdOutlineSearch size={24} className="text-primeRed" />
          </button>
          <button>
            <MdOutlineMessage size={24} className="text-primeRed" />
          </button>
          <button>
            <MdOutlineNotifications size={24} className="text-primeRed" />
          </button>
          

        </div>
        <div className="flex items-center">
            <AccountTab isCollapsed={isCollapsed} />
          </div>
      </nav>
    </header>
  );
};

export default Navbar;
