import { useState, useEffect } from "react";
import DateRangeSelector from "../../components/DateRangeSelector";
import PieChart from "../../components/PieChart";
import Legend from "./Legend";
import axiosInstance from "../../api/axiosInstance";

const SalesByCategory = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]); // State to hold API data

  // Handler for date range change
  const handleRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const getCurrentDateRange = () => {
    const end = new Date();
    end.setDate(end.getDate() - 7); // Set end date as 7 days ago
    const start = new Date();
    start.setDate(end.getDate() - 7); // Set start date as 14 days ago
    return { start, end };
  };

   useEffect(() => {
     const { start, end } = getCurrentDateRange();
     handleRangeChange(start, end);
   }, []);

 

  // Function to fetch data from the API
  const fetchSalesData = async () => {
    try {
      const response = await axiosInstance.get("sales/percentage-by-category",
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      // Assuming response data is in the format needed for the chart
      if (response.data.success) {
        const fetchedData = response.data.data.map((item, index) => ({
          name: item.category,
          "product sold": item.product_sold, // Adjust based on the actual API response
          color: generateColor(index), // Optional: Generate colors dynamically if needed
        }));
        setData(fetchedData);
        console.log(fetchedData)
      }
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  // Fetch data when the component mounts or the date range changes
  useEffect(() => {
    if (startDate && endDate) fetchSalesData();
  }, [startDate, endDate]);

  // Function to generate colors for each category dynamically
  const generateColor = (index) => {
    const colors = [
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#FF4634",
      "#7252F1",
      "#234221",
      "#AE4234",
      "#DF3245",
      "#325677",
    ];
    return colors[index % colors.length];
  };

  return (
    <div className="LINE-CHART w-[68%] h-[400px] bg-white flex flex-col justify-between py-2 px-4 rounded-xl shadow-pop">
      <div className="flex justify-between items-center">
        <p className="text-sm font-medium">Sales by Category</p>
        <DateRangeSelector compact onDateChange={handleRangeChange} />
      </div>

      <div className="h-[100%] flex flex-row items-center self-center">
        <PieChart data={data} />

        <div className=" grid grid-cols-2 gap-x-16 gap-y-2 w-[50%] mx-auto">
        {data.map((key, index) => (
          <Legend key={index} title={key.name} fill={key.color} />
        ))}
      </div>
      </div>
      
    </div>
  );
};

export default SalesByCategory;
