import { useCallback, useState } from "react";
import { approveBorrowRequest } from "../api/utsavApis";

const useWallet = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const approveBorrow = useCallback(async (id, approval) => {
    setLoading(true);
    setError(null);
    try {
      const response = await approveBorrowRequest(id, approval);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("borrowRequests");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { approveBorrow, loading, error };
};

export default useWallet;
