import Rating from "../../components/Rating";
import { PiArrowUpLight } from "react-icons/pi";
import TinyLineChart from "../../components/TinyLineChart";
import { LuDownload } from "react-icons/lu";

const CustomerRating = () => {
  const data = [
    { name: "january", rating: 3.1 },
    { name: "february", rating: 3.7 },
    { name: "march", rating: 4.1 },
    { name: "april", rating: 3.9 },
    { name: "may", rating: 3.5 },
    { name: "june", rating: 4.1 },
    { name: "july", rating: 4.1 },
    { name: "august", rating: 3.9 },
    { name: "september", rating: 4.4 },
    { name: "october", rating: 4.6 },
    { name: "november", rating: 4.1 },
    { name: "december", rating: 4.2 },
  ];

  return (
    <div className="w-[30%] h-96 bg-white rounded-xl shadow-pop p-4">
      <h2 className="font-medium text-lg text-center mt-2 mb-6">
        Customer Rating
      </h2>
      <p className="text-4xl font-medium text-center my-4">4.0</p>

      <div className="mx-auto w-max relative my-4">
        <Rating rating={4.3} size={20} />
        <span className="absolute -right-12 top-1/2 -translate-y-1/2 font-medium">
          (400)
        </span>
      </div>

      <div className="flex items-center w-max mx-auto">
        <PiArrowUpLight size={16} />
        <p className="leading-none text-sm">+40 Point from last month</p>
      </div>

      <div className="GRAPH h-24 w-52 mx-auto my-4">
        <TinyLineChart data={data} />
      </div>

      <button className="flex items-center gap-2 bg-darkGreen mx-auto p-2 rounded-lg text-white hover:bg-success transition-colors duration-200 ease-in-out">
        <LuDownload />
        <p className="text-xs">Download Report</p>
      </button>
    </div>
  );
};

export default CustomerRating;
