const FileInputBox = ({
  name,
  label,
  onChange,
  accept = ".jpg, .jpeg, .png",
  align = "left",
  required = false,
  multiple = false,
  children,
  className = "w-max",
  containerClassName = "gap-2",
}) => {
  const id = name?.replace(" ", "-").toLowerCase();

  return (
    <div
      className={`flex ${
        align === "top" ? "flex-col items-start" : "items-center"
      } ${containerClassName}`}
    >
      {label && align === "top" && (
        <label
          htmlFor={id}
          className="capitalize text-sm font-medium font-secondary"
        >
          {label}
        </label>
      )}

      {label && align === "left" && (
        <label
          htmlFor={id}
          className="capitalize text-sm font-medium font-secondary"
        >
          {label}
        </label>
      )}

      <div className={`FILE-UPLOAD relative ${className}`}>
        {children}

        <input
          id={id}
          type="file"
          name={name}
          onChange={onChange}
          accept={accept}
          multiple={multiple}
          required={required}
          className="block absolute inset-0 opacity-0 cursor-pointer file:cursor-pointer"
        />
      </div>

      {label && align === "right" && (
        <label
          htmlFor={id}
          className="capitalize text-sm font-medium font-secondary"
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default FileInputBox;
