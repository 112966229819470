import { BiCategory } from "react-icons/bi";
import { FaRegStar, FaRegUser } from "react-icons/fa";
import { FaRegImages, FaWpforms } from "react-icons/fa6";
import { GrAnnounce } from "react-icons/gr";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { IoWalletOutline } from "react-icons/io5";
import { LuShirt } from "react-icons/lu";
import {
  MdCardMembership,
  MdLogout,
  MdNotificationsNone,
  MdOutlineBusinessCenter,
  MdOutlineHome,
  MdOutlineMediation,
} from "react-icons/md";
import { PiStarFourBold } from "react-icons/pi";
import { RiFileList3Line, RiMoneyRupeeCircleLine } from "react-icons/ri";
import {
  TbPackage,
  TbPasswordUser,
  TbRosetteDiscount,
  TbUserShield,
  TbUsers,
} from "react-icons/tb";

const sidebarLinks = {
  Dashboard: {
    home: { url: "/", icon: <MdOutlineHome /> },
    orders: { url: "orders", icon: <RiFileList3Line /> },
    products: { url: "products", icon: <TbPackage /> },
    customers: { url: "customers", icon: <TbUsers /> },
  },
 
  "product management": {
    category: {
      icon: <BiCategory />,
      subLinks: [
        { title: "categories", url: "/categories" },
        { title: "sub categories", url: "/subcategories" },
        { title: "product types", url: "/product-types" },
      ],
    },
    brands: { url: "brands", icon: <FaRegStar /> },
    
  },
  "promotion management": {
    banners: { url: "banners", icon: <FaRegImages /> },
    "offers & deal": {
      icon: <TbRosetteDiscount />,
      subLinks: [
        { title: "coupons", url: "/coupons" },
        { title: "gift cards", url: "/gift-cards" },
        { title: "flash deal", url: "/flash-deal" },
        { title: "featured deal", url: "/featured-deal" },
        { title: "deal of the day", url: "/deal-of-the-day" },
      ],
    },
    "push Notification": {
      url: "notifications",
      icon: <MdNotificationsNone />,
    },
    announcements: { url: "announcements", icon: <GrAnnounce /> },
  },
  utsav: {
    "membership plan": { url: "membership-plan", icon: <MdCardMembership /> },
    "membership list": { url: "membership-list", icon: <TbUsers /> },
    "wallet transaction": {
      url: "wallet-transaction",
      icon: <IoWalletOutline />,
    },
    "utsav orders": { url: "utsav-orders", icon: <RiFileList3Line /> },
    designers: { url: "designer-list", icon: <TbPasswordUser /> },
    borrow: { url: "borrow-list", icon: <RiMoneyRupeeCircleLine /> },
    gallery: { url: "gallery", icon: <HiOutlineViewGridAdd /> },
  },
  "fashion setup": {
    "fashion category": { url: "fashion", icon: <LuShirt /> },
    blog: {
      icon: <FaWpforms />,
      subLinks: [
        { title: "posts", url: "fashion/blog" },
        { title: "approvals", url: "fashion/blog/approvals" },
      ],
    },
  },
  "user management": {
    customers: { url: "customers", icon: <TbUsers /> },
    reviews: { url: "reviews", icon: <PiStarFourBold /> },
    "all sellers": { url: "sellers", icon: <TbUserShield /> },
  },
  "system settings": {
    "business setup": {
      url: "business-setup",
      icon: <MdOutlineBusinessCenter />,
    },
    "pages & media": {
      url: "pages-media",
      icon: <MdOutlineMediation />,
      subLinks: [
        { title: "a", url: "/" },
        { title: "b", url: "/" },
      ],
    },
    user: { url: "user", icon: <FaRegUser /> },
    logout: { url: "", icon: <MdLogout /> },
  },
};

const sidebarmanager = {
  Dashboard: {
    home: { url: "/", icon: <MdOutlineHome /> },
    orders: { url: "orders", icon: <RiFileList3Line /> },
    products: { url: "products", icon: <TbPackage /> },
    customers: { url: "customers", icon: <TbUsers /> },
  },
 
  "product management": {
    category: {
      icon: <BiCategory />,
      subLinks: [
        { title: "categories", url: "/categories" },
        { title: "sub categories", url: "/subcategories" },
        { title: "product types", url: "/product-types" },
      ],
    },
    brands: { url: "brands", icon: <FaRegStar /> },
    
  },
  "promotion management": {
    banners: { url: "banners", icon: <FaRegImages /> },
    "offers & deal": {
      icon: <TbRosetteDiscount />,
      subLinks: [
        { title: "coupons", url: "/coupons" },
        { title: "gift cards", url: "/gift-cards" },
        { title: "flash deal", url: "/flash-deal" },
        { title: "featured deal", url: "/featured-deal" },
        { title: "deal of the day", url: "/deal-of-the-day" },
      ],
    },
    "push Notification": {
      url: "notifications",
      icon: <MdNotificationsNone />,
    },
    announcements: { url: "announcements", icon: <GrAnnounce /> },
  },
  utsav: {
    "membership plan": { url: "membership-plan", icon: <MdCardMembership /> },
    "membership list": { url: "membership-list", icon: <TbUsers /> },
    "wallet transaction": {
      url: "wallet-transaction",
      icon: <IoWalletOutline />,
    },
    "utsav orders": { url: "utsav-orders", icon: <RiFileList3Line /> },
    designers: { url: "designer-list", icon: <TbPasswordUser /> },
    borrow: { url: "borrow-list", icon: <RiMoneyRupeeCircleLine /> },
    gallery: { url: "gallery", icon: <HiOutlineViewGridAdd /> },
  },
  "fashion setup": {
    "fashion category": { url: "fashion", icon: <LuShirt /> },
    blog: {
      icon: <FaWpforms />,
      subLinks: [
        { title: "posts", url: "fashion/blog" },
        { title: "approvals", url: "fashion/blog/approvals" },
      ],
    },
  },
  "user management": {
    customers: { url: "customers", icon: <TbUsers /> },
    reviews: { url: "reviews", icon: <PiStarFourBold /> },
    "all sellers": { url: "sellers", icon: <TbUserShield /> },
  },
  "system settings": {
    "business setup": {
      url: "business-setup",
      icon: <MdOutlineBusinessCenter />,
    },
    "pages & media": {
      url: "pages-media",
      icon: <MdOutlineMediation />,
      subLinks: [
        { title: "a", url: "/" },
        { title: "b", url: "/" },
      ],
    },
    user: { url: "user", icon: <FaRegUser /> },
    logout: { url: "", icon: <MdLogout /> },
  },
};

const sidebarDesigner = {
 
  "product management": {
    category: {
      icon: <BiCategory />,
      subLinks: [
        { title: "categories", url: "/categories" },
        { title: "sub categories", url: "/subcategories" },
        { title: "product types", url: "/product-types" },
        
      ],
    },
    
      
    products: { url: "products", icon: <TbPackage /> },
    brands: { url: "brands", icon: <FaRegStar /> },
    
  },
  "promotion management": {
    banners: { url: "banners", icon: <FaRegImages /> },
    "offers & deal": {
      icon: <TbRosetteDiscount />,
      subLinks: [
        { title: "coupons", url: "/coupons" },
        { title: "gift cards", url: "/gift-cards" },
        { title: "flash deal", url: "/flash-deal" },
        { title: "featured deal", url: "/featured-deal" },
        { title: "deal of the day", url: "/deal-of-the-day" },
      ],
    },
    "push Notification": {
      url: "notifications",
      icon: <MdNotificationsNone />,
    },
    announcements: { url: "announcements", icon: <GrAnnounce /> },
  },
  utsav: {
    gallery: { url: "gallery", icon: <HiOutlineViewGridAdd /> },
  },
  "fashion setup": {
    "fashion category": { url: "fashion", icon: <LuShirt /> },
    blog: {
      icon: <FaWpforms />,
      subLinks: [
        { title: "posts", url: "fashion/blog" },
        { title: "approvals", url: "fashion/blog/approvals" },
      ],
    },
  },
  
  
};

// Regular Expressions for Validations
const NAME_REGEX = /^[a-zA-Z-\s]+$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^((\+91)?|91?|0)?[789][0-9]{9}$/;
const PASSWORD_REGEX =
  /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
const PIN_CODE_REGEX = /^[1-9][0-9]{5}$/;

// Order Status
const orderStatus = [
  "All Orders",
  "Pending",
  "Confirmed",
  "Shipping",
  "Out For delivery",
  "Delivered",
  "Returned",
  "Canceled",
  "Completed",
];

const serverOrderStatus = [
  "Pending",
  "Confirmed",
  "Shipping",
  "Out For delivery",
  "Delivered",
  "Returned",
  "Canceled",
  "Completed",
];

const getColorForStatus = (status) => {
  switch (status) {
    case "Pending":
      return "bg-yellow-400";
    case "Confirmed":
      return "bg-green-400";
    case "Shipping":
      return "bg-blue-300";
    case "Out For delivery":
      return "bg-cyan-500";
    case "Delivered":
      return "bg-lime-500";
    case "Returned":
      return "bg-orange-400";
    case "Canceled":
      return "bg-red-500";
    case "Completed":
      return "bg-green-500";
    default:
      return "bg-gray-200";
  }
};

const bannerDimensions = {
  Hero: { width: 1800, height: 450 },
  Leaderboard: { width: 1800, height: 200 },
  Landscape: { width: 800, height: 300 },
  "Large Rectangle": { width: 900, height: 600 },
  "Medium Rectangle": { width: 800, height: 400 },
  "Small Rectangle": { width: 600, height: 800 },
  "Wide Skyscraper": { width: 300, height: 600 },
  Skyscraper: { width: 200, height: 600 },
  Square: { width: 900, height: 900 },
  Microbar: { width: 1800, height: 90 },
  Tinybar: { width: 728, height: 90 },
};

export {
  sidebarLinks,
  NAME_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PASSWORD_REGEX,
  PIN_CODE_REGEX,
  orderStatus,
  serverOrderStatus,
  getColorForStatus,
  bannerDimensions,
  sidebarDesigner
};
