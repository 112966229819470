import { useState, useEffect } from "react";
import DateRangeSelector from "../../components/DateRangeSelector";
import LineChart from "../../components/LineChart";
import Legend from "./Legend";
import Pagination from "../../components/Pagination";
import { getAllOrders } from "../../api/orderApis";

const SalesByProduct = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Track current page
  const pageSize = 7; // Set page size (number of days per page)

  const handleRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setPage(1); // Reset to first page when date range changes
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      setError(null); // Reset error state before fetching
      try {
        const response = await getAllOrders({
          status: "",
          page,
          limit: 26,
          startDate,
          endDate,
        });

        if (response.success) {
          setData(response.data)
          // Transform the data for chart consumption
          const dailyTotals = response.orderDetails.reduce((acc, order) => {
            const orderDate = new Date(order.createdAt);
            const date = orderDate.toLocaleDateString("en-US", {
              weekday: "long",
            });

            // Filter orders within the selected date range
            if (
              (startDate && orderDate < new Date(startDate)) ||
              (endDate && orderDate > new Date(endDate))
            ) {
              return acc; // Skip if not within the date range
            }

            if (!acc[date])
              acc[date] = { name: date, "total order": 0, "product sold": 0 };

            acc[date]["total order"] += 1; // Increment total order count
            acc[date]["product sold"] += order.orderItem.reduce(
              (sum, item) => sum + item.itemQuantity,
              0
            ); // Sum item quantities

            return acc;
          }, {});

          const paginatedData = Object.values(dailyTotals).slice(
            (page - 1) * pageSize,
            page * pageSize
          );

          setChartData(paginatedData); // Update chart data for current page
        }
      } catch (err) {
        setError("Error loading data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders(); // Fetch orders when startDate, endDate, or page changes
  }, [startDate, endDate, page]); // Add page as dependency

  return (
    <div className="LINE-CHART w-[100%] h-100 bg-white flex flex-col justify-between py-2 px-4 rounded-xl shadow-pop">
      <div className="flex justify-between items-center rounded-s-none">
        <p className="text-sm font-medium">Current Progress</p>
        <div className="flex gap-6">
          <Legend fill="rgba(135, 206, 250, 1)" title="Total Orders" />
          <Legend fill="rgba(34, 197, 94, 1)" title="Products Sold" />
        </div>
        <DateRangeSelector compact onDateChange={handleRangeChange} />
      </div>

      <div className="h-[100%] shadow-none">
        {isLoading ? (
          <p className="text-center text-gray-500">Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <LineChart data={chartData} />
        )}
      </div>

      {/* Pagination Component */}
      <Pagination
        currentPage={page}
        totalCount={chartData.length}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </div>
  );
};

export default SalesByProduct;
