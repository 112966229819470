import axiosInstance from "./axiosInstance";

const getAllCustomers = async (params) => {
  try {
    const response = await axiosInstance.get("/getAllUser", { params });

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`);
    }

    const { users = [], page = 1, limit = 10, total = 0 } = response.data || {};
    return { users, page, limit, total }; // Return structured response
  } catch (error) {
    console.error("Error fetching customers:", error.message || error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch customers"
    );
  }
};


const getCustomerById = async (userId) => {
  const response = await axiosInstance.get(`/getCustomerDetailsById/${userId}`);
  return response.data?.userDetails;
};

const deleteCustomer = (userId) => {
  return axiosInstance.get(`/deleteCustomer/${userId}`);
};

const blockUnblockCustomer = (userId, status) => {
  return axiosInstance.post(`/blockingCustomer/${userId}`, {
    blockStatus: status,
  });
};

const getOrdersOfCustomer = async (userId) => {
  const response = await axiosInstance.get(`/customerOrderDetails/${userId}`);
  return response.data?.orderDetails;
};

export {
  getAllCustomers,
  getCustomerById,
  deleteCustomer,
  blockUnblockCustomer,
  getOrdersOfCustomer,
};
