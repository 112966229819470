import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModalProvider } from "./contexts/modalContext";
import { ToastProvider } from "./contexts/toastContext";
import { AdminProvider } from "./contexts/adminContext";
import { AuthProvider } from "./contexts/authContext";
import { SocketProvider } from "./contexts/SocketContext";

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastProvider>
          <AuthProvider>
            <SocketProvider>
            <AdminProvider>
              <ModalProvider>
                <App />
              </ModalProvider>
            </AdminProvider>
            </SocketProvider>
          </AuthProvider>
        </ToastProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);

reportWebVitals();
