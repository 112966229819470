import { getCookie } from "../utils/cookie";
import axiosInstance from "./axiosInstance";

const loginAdmin = (loginData) => {
  return axiosInstance.post("/login_admin", loginData);
};

const getAdminDetails = (token) => {
  return axiosInstance.get("/adminDetails", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const updateAdminDetails = (adminData) => {
  const token = getCookie("accessToken");
  return axiosInstance.post("/updateAdminDetails", adminData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: "Bearer " + token,
    },
  });
};

const logOutAdmin = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/logout", {
    headers: { authorization: `Bearer ${token}` },
  });
};

const refreshTokenAPI = (refreshToken) => {
  return axiosInstance.post("/verifyRefreshToken", { refreshToken });
};

const verifyToken = (token) => {
  return axiosInstance.post("/validAccessToken", { token });
};

export {
  loginAdmin,
  getAdminDetails,
  updateAdminDetails,
  logOutAdmin,
  refreshTokenAPI,
  verifyToken,
};
