import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";

export function useSearchParamsState(
  searchParamName,
  defaultValue,
  replace = false
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      if (!searchParams.has(searchParamName) && defaultValue !== undefined) {
        const nextParams = new URLSearchParams(searchParams);
        nextParams.set(searchParamName, defaultValue);
        setSearchParams(nextParams, { replace: true }); // use replace to avoid adding history entry
      }
      isInitialized.current = true;
    }
  }, [searchParams, searchParamName, defaultValue, setSearchParams]);

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState) => {
    const nextParams = new URLSearchParams(searchParams);

    if (newState !== null && newState !== undefined) {
      nextParams.set(searchParamName, newState);
    } else {
      nextParams.delete(searchParamName);
    }

    // Compare the current search params with the new search params
    const currentParams = searchParams.toString();
    const updatedParams = nextParams.toString();

    if (currentParams !== updatedParams) {
      setSearchParams(nextParams, { replace: replace });
    }
  };

  return [searchParamsState, setSearchParamsState];
}
