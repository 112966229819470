import FeaturedDealForm from "./FeaturedDealForm";
import FeaturedDealList from "./FeaturedDealList";

const FeaturedDeals = () => {
  return (
    <section className="p-4 space-y-16">
      <FeaturedDealForm />
      <FeaturedDealList />
    </section>
  );
};

export default FeaturedDeals;
