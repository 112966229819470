import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  getAllCustomers,
  getCustomerById,
  getOrdersOfCustomer,
} from "../api/customerApis";

const useCustomers = (params) => {
  return useQuery({
    queryKey: ["customers", params],
    queryFn: () => getAllCustomers(params),
    staleTime: 1000 * 60 * 5, // Cache the data for 5 minutes
    keepPreviousData: true, // Retain previous data during query transitions
    select: (data) => {
      // Ensure proper structure and default values
      return {
        customers: data.users || [],
        page: data.page || 1,
        limit: data.limit || 10,
        totalCount: data.total || 0,
      };
    },
  });
};



const useCustomerById = (userId) => {
  return useQuery({
    queryKey: ["customer", userId],
    queryFn: () => getCustomerById(userId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!userId,
  });
};

const useOrdersOfCustomers = (userId) => {
  return useQuery({
    queryKey: ["orders", userId],
    queryFn: () => getOrdersOfCustomer(userId),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
    enabled: !!userId,
  });
};

export { useCustomers, useCustomerById, useOrdersOfCustomers };
