import Button from "../../components/Button";
import Captcha from "../../components/Captcha";
import InputBox from "../../components/InputBox";
import useForm from "../../hooks/useForm";
import { useToast } from "../../contexts/toastContext";
import useCaptcha from "../../hooks/useCaptcha";
import { isEmailValid, isPasswordValid } from "../../utils/validation";
import { useAuth } from "../../contexts/authContext";

const Login = () => {
  const toast = useToast();
  const { login } = useAuth();

  const { captchaCode, regenerateCaptcha, validateCaptcha } = useCaptcha(6);

  const onSubmitHandler = async (loginData) => {
    try {
      const { email, password, captcha } = loginData;

      //Client side validations
      if (!isEmailValid(email).success) {
        toast.error(isEmailValid(email).message);
        regenerateCaptcha();
        return;
      } else if (!isPasswordValid(password).success) {
        toast.error(isPasswordValid(password).message);
        regenerateCaptcha();
        return;
      }

      login({ email, password }).then(() => {
        clearForm();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { formData, handleChange, handleSubmit, clearForm } = useForm(
    {},
    onSubmitHandler
  );

  return (
    <div className="w-[600px] space-y-8">
      <div>
        <h1 className="text-2xl font-medium">Login</h1>
        <p>Enter your credentials to login</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <InputBox
          type="email"
          label="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          validation={isEmailValid}
          className="border border-dark rounded-md px-4 py-3 text-xs"
        />
        <InputBox
          type="password"
          label="password"
          placeholder="Enter your password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          validation={isPasswordValid}
          className="border border-dark rounded-md px-4 py-3 text-xs"
        />
        {/* <div className="CAPTCHA-CONTAINER flex items-end gap-8">
          <InputBox
            label="captcha"
            placeholder="enter captcha code"
            name="captcha"
            value={formData.captcha}
            onChange={handleChange}
            className="border border-dark rounded-md px-4 py-3 text-xs"
          />
          <Captcha
            code={captchaCode}
            reload={regenerateCaptcha}
            textColor="green"
            className="h-[42px]"
          />
        </div> */}
        <div className="pt-4">
          <Button className="mx-auto px-16 py-2 rounded-full text-sm">
            login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
