import { useMembers } from "../../services/utsavServices";
import SearchBox from "../../components/SearchBox";
import Pagination from "../../components/Pagination";
import { FaRegEye } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { useMemo } from "react";
import { formatDate, formatTime } from "../../utils/dateUtils";
import useForm from "../../hooks/useForm";
import Loading from "../../components/Loading";

const MemberList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const {
    data: members = [],
    isLoading,
    isFetching,
    error,
  } = useMembers({ query });
  const [page, setPage] = useSearchParamsState("page", 1);
  const limit = 10;

  const handleSearch = (formData) => {
    const { query } = formData;
    setSearchParams({ query, page: 1 });
  };

  const { formData, handleChange, handleSubmit } = useForm({}, handleSearch);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (parseInt(page, 10) - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return members.slice(firstPageIndex, lastPageIndex);
  }, [page, members, limit]);

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Members
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <div className="flex justify-between items-start">
        <h1 className="text-3xl font-medium">Membership List</h1>
        <SearchBox
          name="query"
          value={formData.query}
          onChange={handleChange}
          onSubmit={handleSubmit}
          placeHolder="search by name"
        />
      </div>

      {members.length === 0 ? (
        <div className="w-full">
          <h2 className="text-center font-secondary font-medium text-xl mt-8">
            No Members Available
          </h2>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-pop overflow-hidden">
          <div className="relative custom-scroll overflow-x-auto">
            <table className="w-full">
              <thead className="bg-mainGray">
                <tr className="divide-x divide-primary">
                  <th scope="col" className="px-6 py-4 font-medium">
                    SL
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Ref.Code
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Total Member
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Joined
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Total Spend
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTableData.map((member, index) => (
                  <tr key={index} className="text-sm divide-x divide-primary">
                    <td className="px-6 py-3 text-center border-y border-primary border-collapse">
                      {index + 1}
                    </td>
                    <td className="px-6 py-3 text-center border-y border-primary border-collapse">
                      <p>{member.fullName}</p>
                    </td>
                    <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                      <p>{member?.referralDetails?.code}</p>
                    </td>
                    <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                      <p>{member?.referralDetails?.referred_user?.length}</p>
                    </td>
                    <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                      <p>
                        {formatDate(new Date(member.createdAt))}
                        {", "}
                        {formatTime(new Date(member.createdAt))}
                      </p>
                    </td>
                    <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                      <p>₹ {member.totalSpend}</p>
                    </td>
                    <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                      <button onClick={() => navigate(`/member/${member._id}`)}>
                        <FaRegEye className="text-info" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {isFetching && <Loading />}
          </div>
          <Pagination
            currentPage={parseInt(page, 10)}
            totalCount={members.length}
            pageSize={limit}
            onPageChange={(page) => setPage(page.toString())}
          />
        </div>
      )}
    </section>
  );
};

export default MemberList;
