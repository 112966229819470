import { useEffect, useState } from "react";
import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../contexts/toastContext";
import useGiftCard from "../../hooks/useGiftCard";
import { BsImageAlt } from "react-icons/bs";
import { useTemplateById } from "../../services/giftCardServices";

const TemplateForm = ({ edit = false }) => {
  const { templateId } = useParams();
  const { data: template, isLoading, error } = useTemplateById(templateId);
  const { addTemplate, editTemplate, loading } = useGiftCard(templateId);
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitHandler = (templateData) => {
    const { title, image } = templateData;
    try {
      if (edit) {
        editTemplate(templateId, { title, template: image[0] }).then(() => {
          toast.success("Template updated successfully");
          navigate(-1);
        });
      } else {
        addTemplate({ title, template: image[0] }).then(() => {
          toast.success("Template created successfully");
          navigate(-1);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && template) {
      setFormData({
        title: template.title,
        image: template.template,
      });
      if (template.template) {
        setSelectedImage(template.template);
      }
    }
  }, [edit, template, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  if (isLoading || (edit && !template)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-8 space-y-4">
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Gift Card Template" : "Create Gift Card Template"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[45%] space-y-4">
          <InputBox
            label="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          <FileInput
            label="image ratio (3:2)"
            name="image"
            onChange={handleFileChange}
            align="top"
            dynamic
            className="bg-offWhite w-full border border-secondary rounded-md px-4 py-2"
            containerClassName="gap-2"
          />
        </div>
        <div className="w-[50%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div className="w-90 h-60 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="deal-logo"
                  className="size-full object-cover object-center"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>

            <p className="text-sm font-medium">Dimensions: 900 x 600 pixels</p>
          </div>

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={loading}
            >
              Reset
            </Button>
            <Button disabled={loading}>submit</Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default TemplateForm;
