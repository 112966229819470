import { useParams } from "react-router-dom";
import Address from "./Address";
import GeneralInfo from "./GeneralInfo";
import OrderTracker from "./OrderTracker";
import PaymentInfo from "./PaymentInfo";
import PurchaseTable from "./PurchaseTable";
import useOrder from "../../hooks/useOrder";

const Order = () => {
  const { orderId } = useParams();
  const { order, statusHistory } = useOrder(orderId);

  return (
    order && (
      <section className="p-4 space-y-4">
        <h1 className="text-3xl font-medium">Order Details</h1>

        <div className="WRAPPER flex justify-between">
          <div className="LEFT w-[70%] space-y-4">
            <div className="flex justify-between">
              <Address address={order.address} />
              <PaymentInfo order={order} />
            </div>
            <PurchaseTable order={order} orderItems={order.orderItem} />
            <OrderTracker order={order} statusHistory={statusHistory} />
          </div>
          <div className="RIGHT w-[25%] space-y-4">
            <GeneralInfo order={order} />
          </div>
        </div>
      </section>
    )
  );
};

export default Order;
