import axiosInstance from "./axiosInstance";

const getCustomerWallet = async (customerId) => {
  const response = await axiosInstance.get(
    `/getBalanceFromAdmin/${customerId}`
  );

  return response.data?.walletDetails;
};

const addBalance = async (amount, description, userId) => {
  const response = await axiosInstance.post("/addBalanceFromAdmin", {
    amount,
    description,
    userId,
  });

  return response.data;
};

export { getCustomerWallet, addBalance };
