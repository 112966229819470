import { useEffect, useState } from "react";
import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import TextareaBox from "../../components/TextareaBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import { GoImage } from "react-icons/go";
import SelectBox from "../../components/SelectBox";
import { useBrands } from "../../services/brandServices";
import {
  useCategories,
  useProductTypes,
  useSubcategories,
} from "../../services/categoryServices";
import {
  useBannerById,
  useCreateBanner,
  useUpdateBanner,
} from "../../services/bannerServices";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../contexts/toastContext";
import { bannerDimensions } from "../../utils/constants";
import { useFeaturedDeals, useFlashDeals } from "../../services/dealServices";

const BannerForm = ({ edit = false }) => {
  const { bannerId } = useParams();
  const { data: brands } = useBrands();
  const { data: categories } = useCategories();
  const { data: allSubcategories } = useSubcategories();
  const { data: allProductTypes } = useProductTypes();
  const { data: flashDeals } = useFlashDeals();
  const { data: featuredDeals } = useFeaturedDeals();
  const [selectedImage, setSelectedImage] = useState(null);
  const [resourceOptions, setResourceOptions] = useState([]);
  const { data: banner, isLoading, error } = useBannerById(bannerId);
  const createMutation = useCreateBanner();
  const updateMutation = useUpdateBanner(bannerId);
  const navigate = useNavigate();
  const toast = useToast();

  const getBannerDimensions = (bannerType) => {
    const dimensions = bannerDimensions[bannerType];
    return dimensions
      ? `Dimensions: ${dimensions.width} x ${dimensions.height} pixels`
      : "";
  };

  const getBannerStyles = (bannerType) => {
    const dimensions = bannerDimensions[bannerType];
    return dimensions
      ? { width: dimensions.width / 4, height: dimensions.height / 4 }
      : {};
  };

  const onSubmitHandler = (bannerData) => {
    const { bannerImg } = bannerData;
    const payload = { ...bannerData, bannerImg: bannerImg[0] };

    if (edit) {
      updateMutation.mutate(
        { bannerId, bannerData: payload },
        {
          onSuccess: () => {
            toast.success("Banner updated successfully");
            navigate(-1);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Failed to update banner");
          },
        }
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Banner created successfully");
          navigate(-1);
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to create banner");
        },
      });
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({ bannerType: "Hero", resourceType: "Brand" }, onSubmitHandler);

  useEffect(() => {
    if (edit && banner) {
      setFormData({
        bannerType: banner.bannerType,
        position: banner.position,
        resourceType: banner.details.resourceType,
        valueId: banner.details.valueId,
        ...banner,
      });
      if (banner.bannerImg) {
        setSelectedImage(banner.bannerImg);
      }
    }
  }, [banner, edit, setFormData]);

  useEffect(() => {
    switch (formData.resourceType) {
      case "Brand":
        setResourceOptions(
          brands.map((brand) => ({ label: brand.name, value: brand._id }))
        );
        break;
      case "Category":
        setResourceOptions(
          categories.map((category) => ({
            label: category.name,
            value: category._id,
          }))
        );
        break;
      case "Subcategory":
        setResourceOptions(
          allSubcategories.map((subcategory) => ({
            label: subcategory.name,
            value: subcategory._id,
          }))
        );
        break;
      case "ProductType":
        setResourceOptions(
          allProductTypes.map((type) => ({ label: type.name, value: type._id }))
        );
        break;
      case "Flash Deal":
        console.log(flashDeals);
        setResourceOptions(
          flashDeals.map((deal) => ({ label: deal.title, value: deal._id }))
        );
        break;
      case "Featured Deal":
        setResourceOptions(
          featuredDeals.map((deal) => ({ label: deal.title, value: deal._id }))
        );
        break;
      default:
        setResourceOptions([]);
    }
  }, [
    formData.resourceType,
    brands,
    categories,
    allSubcategories,
    allProductTypes,
    flashDeals,
    featuredDeals,
  ]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  const renderResourceValueSelectBox = () => {
    if (
      ["Product", "Search", "Utsav", "Home", "About", "Contact"].includes(
        formData.resourceType
      )
    ) {
      return null;
    }

    return (
      <SelectBox
        label={formData.resourceType}
        name="valueId"
        value={formData.valueId}
        onChange={handleChange}
        options={resourceOptions}
      />
    );
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Banner" : "Add Banner"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[45%] space-y-4">
          <SelectBox
            label="banner type"
            name="bannerType"
            value={formData.bannerType}
            onChange={handleChange}
            options={Object.keys(bannerDimensions).map((value) => ({
              label: value,
              value,
            }))}
          />
          <SelectBox
            label="banner position"
            name="position"
            value={formData.position}
            onChange={handleChange}
            options={[
              "Top",
              "Bottom",
              "Middle_1",
              "Middle_2",
              "Middle_3",
              "Middle_4",
              "Middle_1_app",
              "Middle_2_app",
              "Middle_3_app",
              "Middle_4_app",
              "Popup",
            ].map((value) => ({
              label: value,
              value,
            }))}
          />
          <SelectBox
            label="resource type"
            name="resourceType"
            value={formData.resourceType}
            onChange={handleChange}
            options={[
              "Brand",
              "Category",
              "Subcategory",
              "ProductType",
              "Product",
              "Search",
              "Flash Deal",
              "Featured Deal",
              "Utsav",
              "Home",
              "About",
              "Contact",
            ].map((value) => ({
              label: value,
              value,
            }))}
          />
          {formData.resourceType && renderResourceValueSelectBox()}
          <InputBox
            label="banner title"
            name="bannerTitle"
            value={formData.bannerTitle}
            onChange={handleChange}
          />
          <InputBox
            label="banner url"
            name="linkUrl"
            value={formData.linkUrl}
            onChange={handleChange}
          />
          <TextareaBox
            label="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="w-[50%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div
              className="bg-lightGray flex items-center justify-center"
              style={getBannerStyles(formData.bannerType)}
            >
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="banner"
                  className="size-full object-cover object-center"
                />
              ) : (
                <GoImage size={24} className="text-primary" />
              )}
            </div>
            {formData.bannerType && (
              <p className="text-sm font-medium">
                {getBannerDimensions(formData.bannerType)}
              </p>
            )}
          </div>

          <FileInput
            label="banner image"
            accept=".jpg, .jpeg, .png, .gif"
            name="bannerImg"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2 w-80"
          />

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              Reset
            </Button>
            <Button
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              submit
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default BannerForm;
