import { FiXSquare } from "react-icons/fi";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import Pagination from "../../../components/Pagination";
import SearchBox from "../../../components/SearchBox";
import { FaRegEye } from "react-icons/fa";
import useDesigner from "../../../hooks/useDesigner";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDesigners } from "../../../services/utsavServices";

const DesignerList = () => {
  const navigate = useNavigate();
  const { data: designers, isLoading, error } = useDesigners();
  const { approveDesigner } = useDesigner();
  const [page, setPage] = useSearchParamsState("designer-page", 1);
  const limit = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (parseInt(page, 10) - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return designers.slice(firstPageIndex, lastPageIndex);
  }, [page, designers, limit]);

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching All Designers
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="space-y-4">
      <div className="flex justify-between items-start">
        <h1 className="text-2xl font-medium">Designer List</h1>
        <SearchBox placeHolder="search by name" />
      </div>

      {designers.length === 0 ? (
        <div className="w-full">
          <h2 className="text-center font-secondary font-medium text-xl mt-8">
            No Designers Joined Yet
          </h2>
        </div>
      ) : (
        <div className="bg-white rounded-xl overflow-hidden shadow-pop">
          <table className="w-full">
            <thead className="bg-mainGray">
              <tr className="divide-x divide-primary">
                <th scope="col" className="px-6 py-4 font-medium">
                  SL
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Name
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Ref.Code
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Total Member
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Total Spend
                </th>
                {/* <th scope="col" className="px-6 py-4 font-medium">
                  Borrow  
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Borrow Due
                </th> */}
                <th scope="col" className="px-6 py-4 font-medium">
                  Dismiss User
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((designer, index) => (
                <tr key={index} className="text-sm divide-x divide-primary">
                  <td className="px-6 py-3 text-center border-y border-primary border-collapse">
                    {index + 1}
                  </td>
                  <td className="px-6 py-3 text-center border-y border-primary border-collapse">
                    <p>{designer?.userId?.fullName}</p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p>{designer?.referralDetails?.code}</p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p>{designer?.referralDetails?.referred_user?.length}</p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p>{designer.totalSpend}</p>
                  </td>
                  {/* <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p></p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p></p>
                  </td> */}
                  <td className="px-4 py-3 space-x-4 text-center border-y border-primary border-collapse">
                    <button
                      onClick={() => approveDesigner(designer._id, "rejected")}
                    >
                      <FiXSquare size={18} className="text-error" />
                    </button>
                  </td>
                  <td className="px-4 py-3 space-x-4 text-center border-y border-primary border-collapse">
                    <button
                      onClick={() =>
                        navigate(`/member/${designer.userId?._id}`)
                      }
                    >
                      <FaRegEye className="text-info" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={parseInt(page, 10)}
            totalCount={designers.length}
            pageSize={limit}
            onPageChange={(page) => setPage(page.toString())}
          />
        </div>
      )}
    </section>
  );
};

export default DesignerList;
