import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useModal } from "../../contexts/modalContext";
import { useNavigate } from "react-router-dom";
import DeleteBanner from "./DeleteBanner";
import useForm from "../../hooks/useForm";
import { useToast } from "../../contexts/toastContext";
import { useMemo } from "react";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import {
  useBanners,
  useDeleteBanner,
  usePublishBanner,
} from "../../services/bannerServices";

const BannerList = () => {
  const { data: banners = [], isLoading, error } = useBanners();
  const deleteMutation = useDeleteBanner();
  const publishMutation = usePublishBanner();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { formData, setFormData } = useForm({});
  const toast = useToast();
  const [page, setPage] = useSearchParamsState("page", 1);
  const limit = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (parseInt(page, 10) - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return banners.slice(firstPageIndex, lastPageIndex);
  }, [page, banners, limit]);

  const handleDelete = (bannerId) => {
    deleteMutation.mutate(bannerId, {
      onSuccess: () => {
        toast.success("Banner deleted successfully");
        closeModal();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete banner");
      },
    });
  };

  const handleIsPublished = (bannerId, e) => {
    const isPublished = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [bannerId]: { ...prevData[bannerId], isPublished },
    }));

    publishMutation.mutate(
      { bannerId, isPublished },
      {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Banners
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return currentTableData.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Banners Found
      </h2>
    </div>
  ) : (
    <div className="w-full min-h-[720px] flex flex-col justify-between bg-white rounded-xl shadow-pop overflow-hidden">
      <table className="w-full">
        <thead className="bg-lightGray">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium">
              SL
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Image
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Banner Position
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Banner Type
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Banner Title
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Published
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTableData.map((banner, index) => (
            <tr key={index} className="border-b border-primary">
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="px-6 py-3">
                <div className="size-24 mx-auto">
                  <img
                    src={banner.bannerImg}
                    alt={banner.title}
                    className="size-full object-contain object-center"
                  />
                </div>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">{banner.position}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">{banner.details.resourceType}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">{banner.bannerTitle || "N/A"}</p>
              </td>
              <td className="px-4 py-3">
                <ToggleSwitch
                  name="isPublished"
                  checked={
                    formData[banner._id]?.isPublished || banner.is_published
                  }
                  onChange={(e) => handleIsPublished(banner._id, e)}
                  className="mx-auto w-10 h-5"
                />
              </td>
              <td className="px-4 py-3">
                <div className="flex justify-center items-center gap-4">
                  <button
                    onClick={() => navigate(`/banner/edit/${banner._id}`)}
                  >
                    <FaRegEdit className="text-success" />
                  </button>
                  <button
                    onClick={() =>
                      openModal(
                        <DeleteBanner
                          onDelete={() => handleDelete(banner._id)}
                        />
                      )
                    }
                  >
                    <FaRegTrashAlt className="text-error" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={parseInt(page, 10)}
        totalCount={banners.length}
        pageSize={limit}
        onPageChange={(page) => setPage(page.toString())}
      />
    </div>
  );
};

export default BannerList;
