import { useState } from "react";

const TextareaBox = ({
  value = "",
  onChange,
  name,
  label,
  align = false,
  autoComplete,
  autoCorrect,
  order,
  maxLength,
  placeholder,
  required = false,
  rows = 5,
  className = "w-full px-4 py-2 rounded-md text-xs resize-none border border-secondary bg-offWhite",
  containerClassName = "gap-2",
}) => {
  const id = label?.replace(" ", "-").toLowerCase();
  const [length, setLength] = useState(0);
  const onChangeHandler = (event) => {
    const { value } = event.target;
    setLength(value.length);
    onChange(event);
  };

  return (
    <div className={"relative flex flex-col" + " ".concat(containerClassName)}>
      <div className="flex justify-between items-center">
        <label
          htmlFor={id}
          className="capitalize font-medium text-sm font-secondary"
          style={align ? { textAlign: "center" } : {}}
        >
          {label}
        </label>

        {maxLength && (
          <p className="font-medium font-secondary text-sm">
            {length}/{maxLength}
          </p>
        )}
      </div>

      <textarea
        id={id}
        name={name}
        value={value}
        placeholder={placeholder || label}
        onChange={onChangeHandler}
        required={required}
        maxLength={maxLength}
        rows={rows}
        className={
          " outline-none placeholder:capitalize" +
          " ".concat(className.toString())
        }
      ></textarea>
    </div>
  );
};

export default TextareaBox;
