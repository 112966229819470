import { useState, useCallback } from "react";
import {
  createGalleryBanner,
  deleteGalleryBanner,
  makePublish,
  updateGalleryBanner,
} from "../api/utsavApis";

const useGallery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addBanner = useCallback(async (brandData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await createGalleryBanner(brandData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("galleryImages");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const editBanner = useCallback(async (bannerId, bannerData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateGalleryBanner(bannerId, bannerData);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("galleryImages");
        sessionStorage.removeItem(`galleryImage_${bannerId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeBanner = useCallback(async (bannerId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await deleteGalleryBanner(bannerId);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("galleryImages");
        sessionStorage.removeItem(`galleryImage_${bannerId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const publishBanner = useCallback(async (bannerId, isPublished) => {
    setLoading(true);
    setError(null);
    try {
      const response = await makePublish(bannerId, isPublished);
      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("galleryImages");
        sessionStorage.removeItem(`galleryImage_${bannerId}`);
      }
    } catch (error) {
      console.error("Error publishing banner", error);
      setError("Failed to publish banner . Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    addBanner,
    editBanner,
    removeBanner,
    publishBanner,
    loading,
    error,
  };
};

export default useGallery;
