import { useQuery } from "@tanstack/react-query";
import {
  getAllCoupons,
  getAllDealOfTheDay,
  getAllFeaturedDeals,
  getAllFlashDeals,
  getCouponById,
  getDealOfTheDayById,
  getFeaturedDealById,
  getFlashDealById,
} from "../api/dealApis";

const useCoupons = () => {
  return useQuery({
    queryKey: ["coupons"],
    queryFn: () => getAllCoupons(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useCouponById = (couponId) => {
  return useQuery({
    queryKey: ["coupon", couponId],
    queryFn: () => getCouponById(couponId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!couponId,
  });
};

const useFlashDeals = () => {
  return useQuery({
    queryKey: ["flashDeals"],
    queryFn: () => getAllFlashDeals(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useFlashDealById = (dealId) => {
  return useQuery({
    queryKey: ["flashDeal", dealId],
    queryFn: () => getFlashDealById(dealId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!dealId,
  });
};

const useFeaturedDeals = () => {
  return useQuery({
    queryKey: ["featuredDeals"],
    queryFn: () => getAllFeaturedDeals(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useFeaturedDealById = (dealId) => {
  return useQuery({
    queryKey: ["featuredDeal", dealId],
    queryFn: () => getFeaturedDealById(dealId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!dealId,
  });
};

const useDealsOfTheDay = () => {
  return useQuery({
    queryKey: ["dealsOfTheDay"],
    queryFn: () => getAllDealOfTheDay(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useDealOfTheDayById = (dealId) => {
  return useQuery({
    queryKey: ["dealOfTheDay", dealId],
    queryFn: () => getDealOfTheDayById(dealId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!dealId,
  });
};

export {
  useCoupons,
  useCouponById,
  useFlashDeals,
  useFlashDealById,
  useFeaturedDeals,
  useFeaturedDealById,
  useDealsOfTheDay,
  useDealOfTheDayById,
};
