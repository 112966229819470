import { useRef, useState } from "react";
import ImageMagnifier from "./ImageMagnifier";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const ProductGallery = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef(null);
  const gap = 16;
  const slidesToShow = 4;

  const calcX = (index) => {
    if (!slideRef.current) return 0;

    const slideWidth = slideRef.current.offsetWidth;

    if (index >= slidesToShow && index <= slides.length - slidesToShow) {
      return (index - slidesToShow + 1) * (slideWidth + gap);
    } else if (index > slides.length - slidesToShow) {
      return (slides.length - slidesToShow) * (slideWidth + gap);
    } else {
      return 0;
    }
  };

  const changeSlideTo = (index) => {
    if (index === currentSlide) return;
    setCurrentSlide(index);
  };

  const changeSlideForwards = () => {
    if (currentSlide >= slides.length - 1) return;
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const changeSlideBackwards = () => {
    if (currentSlide <= 0) return;
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const trackStyle = {
    transform: `translateX(-${calcX(currentSlide)}px)`,
  };

  return (
    <section className="w-[368px] flex flex-col gap-4">
      <div className="IMAGE-CURRENT relative size-[368px] z-[2]">
        {slides.length > slidesToShow ? (
          <button
            onClick={changeSlideBackwards}
            className={`PREV absolute -left-10 top-1/2 -translate-y-1/2 ${
              currentSlide <= 0 ? "text-primary" : "text-black"
            }`}
          >
            <IoChevronBack size={32} />
          </button>
        ) : null}
        <ImageMagnifier
          src={slides[currentSlide]}
          alt="product"
          magnifierWidth={200}
          magnifierHeight={200}
          zoomLevel={2}
        />
        {slides.length > slidesToShow ? (
          <button
            onClick={() => changeSlideForwards()}
            className={`NEXT absolute -right-10 top-1/2 -translate-y-1/2 ${
              currentSlide >= slides.length - 1 ? "text-primary" : "text-black"
            }`}
          >
            <IoChevronForward size={32} />
          </button>
        ) : null}
      </div>

      <div className="ARRAY-CONTAINER">
        <div className="overflow-hidden">
          <div
            className="IMAGES-ARRAY flex gap-4 mx-auto w-max"
            style={trackStyle}
          >
            {slides.map((slide, index) => (
              <div
                ref={slideRef}
                key={index}
                className={`size-20 border-2 flex-shrink-0 cursor-pointer ${
                  currentSlide === index
                    ? "border-primeRed"
                    : "border-transparent"
                }`}
                onMouseEnter={() => changeSlideTo(index)}
              >
                <img
                  src={slide}
                  alt={`product-${index}`}
                  className="size-full object-cover object-center"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductGallery;
