import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";

const StockWarning = () => {
  return (
    <div className="RETURN-REQUEST bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 py-4 px-6 border-b-2 border-secondary">
        <p className="font-medium">Minimum stock limit for warning</p>
      </div>
      <div className="py-4 px-6 space-y-4">
        <InputBox label="Minimum stock limit" name="days" />
        <div className="w-max ml-auto">
          <Button>save</Button>
        </div>
      </div>
    </div>
  );
};

export default StockWarning;
