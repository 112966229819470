import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getAllApprovals,
  getAllDesigners,
  getAllGalleryBanners,
  getAllMembers,
  getAllWalletTransactions,
  getBorrowRequests,
  getDesigner,
  getGalleryBannerById,
  getMemberById,
  getMembershipPlan,
  getUtsavOrders,
  makeDesigner,
} from "../api/utsavApis";

const useMembers = (params) => {
  return useQuery({
    queryKey: ["members", params],
    queryFn: () => getAllMembers(params),
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  });
};

const useMemberById = (userId) => {
  return useQuery({
    queryKey: ["member", userId],
    queryFn: () => getMemberById(userId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!userId,
  });
};

const useMembershipPlan = () => {
  return useQuery({
    queryKey: ["membershipPlan"],
    queryFn: () => getMembershipPlan(),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
  });
};

const useDesigners = () => {
  return useQuery({
    queryKey: ["designers"],
    queryFn: () => getAllDesigners(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useDesigner = (userId) => {
  return useQuery({
    queryKey: ["designer", userId],
    queryFn: () => getDesigner(userId),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });
};

const useMakeDesigner = (userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, status }) => makeDesigner(userId, status),
    onSuccess: () => {
      queryClient.invalidateQueries(["designer"], userId);
    },
  });
};

const useApprovals = () => {
  return useQuery({
    queryKey: ["approvals"],
    queryFn: () => getAllApprovals(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useWalletTransactions = () => {
  return useQuery({
    queryKey: ["walletTransactions"],
    queryFn: () => getAllWalletTransactions(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useBorrowRequests = () => {
  return useQuery({
    queryKey: ["borrowRequests"],
    queryFn: () => getBorrowRequests(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useGalleryBanners = () => {
  return useQuery({
    queryKey: ["galleryBanners"],
    queryFn: () => getAllGalleryBanners(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useGalleryBannerById = (bannerId) => {
  return useQuery({
    queryKey: ["galleryBanner", bannerId],
    queryFn: () => getGalleryBannerById(bannerId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!bannerId,
  });
};

const useUtsavOrders = (params) => {
  return useQuery({
    queryKey: ["orders", "utsav", params],
    queryFn: () => getUtsavOrders(params),
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  });
};

export {
  useMembers,
  useMemberById,
  useMembershipPlan,
  useDesigners,
  useDesigner,
  useMakeDesigner,
  useApprovals,
  useWalletTransactions,
  useBorrowRequests,
  useGalleryBanners,
  useGalleryBannerById,
  useUtsavOrders,
};
