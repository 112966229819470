import SearchBox from "../../components/SearchBox";
import { FaRegEye } from "react-icons/fa";
import { useModal } from "../../contexts/modalContext";
import { formatDate } from "../../utils/dateUtils";
import { useGiftCards } from "../../services/giftCardServices";

const GiftCardList = () => {
  const { data: giftCards, isLoading, error } = useGiftCards();
  const { openModal } = useModal();

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Gift Cards
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return giftCards.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Gift Cards Found
      </h2>
    </div>
  ) : (
    <div className="bg-white rounded-xl shadow-pop overflow-hidden">
      <div className="p-4 flex justify-between items-center">
        <div className="HEADING flex items-center gap-4">
          <h3 className="text-xl font-medium">Gift Card List</h3>
          <span className="bg-mainGray size-5 rounded-full flex justify-center items-center text-xs font-medium">
            {giftCards.length}
          </span>
        </div>
        <SearchBox placeHolder="Search by user name" />
      </div>
      <div className="w-full flex flex-col justify-between">
        <table className="w-full">
          <thead className="bg-lightGray">
            <tr>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                SL
              </th>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                Title
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                From
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                To
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Amount
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Expires
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {giftCards.map((card, index) => (
              <tr key={index}>
                <td className="px-6 py-3">{index + 1}</td>
                <td className="px-6 py-3 text-sm">
                  <div className="flex items-center gap-2">
                    <div className="w-18 h-12">
                      <img
                        src={card.templateId?.template}
                        alt={card.templateId?.title}
                        className="size-full"
                      />
                    </div>
                    <p>{card.templateId?.title}</p>
                  </div>
                </td>
                <td className="px-6 py-3 text-center">
                  <p className="text-sm">{card.Issuer_Information.fullName}</p>
                  <p className="text-sm">{card.Issuer_Information.email}</p>
                  <p className="text-sm">{card.Issuer_Information.phone}</p>
                </td>
                <td className="px-6 py-3 text-center">
                  <p className="text-sm">{card.Recipient_Information.name}</p>
                  <p className="text-sm">{card.Recipient_Information.email}</p>
                  <p className="text-sm">
                    {card.Recipient_Information.phoneNumber}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">₹ {card.Value}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    {formatDate(new Date(card.Expiration_Date))}
                  </p>
                </td>
                <td className="px-4 py-3">
                  <div className="flex justify-center items-center gap-4">
                    <button onClick={() => openModal(null)}>
                      <FaRegEye className="text-info" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GiftCardList;
