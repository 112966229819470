import Units from "./Units";
import VariantAttributes from "./VariantAttributes";

const ProductAttributes = () => {
  return (
    <section className="grid grid-cols-2 gap-4">
      <VariantAttributes />
      <Units />
    </section>
  );
};

export default ProductAttributes;
