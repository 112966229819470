import axiosInstance from "./axiosInstance";

const getAllCoupons = async () => {
  const response = await axiosInstance.get("/getAllCoupons");
  return response.data?.couponsDetails;
};

const getCouponById = async (couponId) => {
  const response = await axiosInstance.get(`/getTheCoupon/${couponId}`);
  return response.data;
};

const createCoupon = (couponData) => {
  return axiosInstance.post("/createCoupons", couponData);
};

const updateCoupon = (couponId, couponData) => {
  return axiosInstance.post(`/updateCoupons/${couponId}`, couponData);
};

const updateCouponStatus = (couponId, status) => {
  return axiosInstance.post(`/updateStatusCoupons/${couponId}`, { status });
};

const deleteCoupon = (couponId) => {
  return axiosInstance.delete(`/deleteCoupons/${couponId}`);
};

const getAllFlashDeals = async () => {
  const response = await axiosInstance.get("/getAllFlashDeal");
  return response.data?.flashDealDetails;
};

const getFlashDealById = async (dealId) => {
  const response = await axiosInstance.get(`/getFlashDealById/${dealId}`);
  return response.data?.dealDetails;
};

const createFlashDeal = (dealData) => {
  return axiosInstance.post("/createFlashDeal", dealData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateFlashDeal = (dealId, dealData) => {
  return axiosInstance.post(`/editFlashDealById/${dealId}`, dealData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const addProductFlashDeal = (dealId, products) => {
  return axiosInstance.post(`/addProductFlashDeal/${dealId}`, products);
};

const deleteProductFlashDeal = (dealId, productId) => {
  return axiosInstance.post(`/deleteProductFlashDeal/${dealId}`, {
    productId: productId,
  });
};

const updateStatusFlashDeal = (dealId, status) => {
  return axiosInstance.post(`/updateStatusFlashDeal/${dealId}`, { status });
};

const deleteFlashDeal = (dealId) => {
  return axiosInstance.delete(`/deleteFlashDeal/${dealId}`);
};

const getAllFeaturedDeals = async () => {
  const response = await axiosInstance.get("/getAllFeaturedDeals");
  return response.data?.featuredDealsDetails;
};

const getFeaturedDealById = async (dealId) => {
  const response = await axiosInstance.get(`/getFeaturedDealById/${dealId}`);
  return response.data?.dealDetails;
};

const createFeaturedDeal = (dealData) => {
  return axiosInstance.post("/createFeaturedDeal", dealData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateFeaturedDeal = (dealId, dealData) => {
  return axiosInstance.post(`/editFeaturedDealById/${dealId}`, dealData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const addProductFeaturedDeal = (dealId, products) => {
  return axiosInstance.post(`/addProductFeaturedDeal/${dealId}`, products);
};

const deleteProductFeaturedDeal = (dealId, productId) => {
  return axiosInstance.post(`/deleteProductFeaturedDeal/${dealId}`, {
    productId,
  });
};

const updateStatusFeaturedDeal = (dealId, status) => {
  return axiosInstance.post(`/updateStatusFeaturedDeal/${dealId}`, { status });
};

const deleteFeaturedDeal = (dealId) => {
  return axiosInstance.delete(`/deleteFeaturedDeal/${dealId}`);
};

const getAllDealOfTheDay = async () => {
  const response = await axiosInstance.get("/getDealOfTheDay");
  return response.data?.dealOfTheDayDetails;
};

const getDealOfTheDayById = async (dealId) => {
  const response = await axiosInstance.get(`/getDEalOfTheDayById/${dealId}`);
  return response.data?.dealDetails;
};

const createDealOfTheDay = (dealData) => {
  return axiosInstance.post("/createDealOfTheDay", dealData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateDealOfTheDay = (dealId, dealData) => {
  return axiosInstance.post(`/editDEalOfTheDayById/${dealId}`, dealData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateStatusDealOfTheDay = (dealId, status) => {
  return axiosInstance.post(`/updateStatusDealOfTheDay/${dealId}`, { status });
};

const deleteDealOfTheDay = (dealId) => {
  return axiosInstance.delete(`/deleteDealOfTheDay/${dealId}`);
};

export {
  getAllCoupons,
  getCouponById,
  createCoupon,
  updateCoupon,
  updateCouponStatus,
  deleteCoupon,
  getAllFlashDeals,
  getFlashDealById,
  createFlashDeal,
  updateFlashDeal,
  addProductFlashDeal,
  deleteProductFlashDeal,
  updateStatusFlashDeal,
  deleteFlashDeal,
  getAllFeaturedDeals,
  getFeaturedDealById,
  createFeaturedDeal,
  updateFeaturedDeal,
  addProductFeaturedDeal,
  deleteProductFeaturedDeal,
  updateStatusFeaturedDeal,
  deleteFeaturedDeal,
  getAllDealOfTheDay,
  getDealOfTheDayById,
  createDealOfTheDay,
  updateDealOfTheDay,
  updateStatusDealOfTheDay,
  deleteDealOfTheDay,
};
