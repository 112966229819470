import React from "react";
import { FaTimes, FaCheckCircle } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";

const NewOrder = ({ onClose, orderDetails,navigate }) => (
  <div style={styles.overlay}>
    <div style={styles.modal}>
      <button style={styles.closeButton} onClick={onClose}>
        <FaTimes />
      </button>
      <div style={styles.iconContainer}>
        <MdShoppingCart style={styles.headerIcon} />
      </div>
      <h2 style={styles.header}>New Order Received!</h2>
      <p style={styles.subheader}>A new order has just been confirmed.</p>
      <p style={styles.orderDetail}>
        <strong>Order ID:</strong> {orderDetails.orderId}
      </p>
      <div style={styles.orderInfo}>
        <button style={styles.confirmButton} onClick={()=>{
          navigate(`/order/${orderDetails.orderId}`)
          onClose()
        }}>
          <FaCheckCircle style={styles.confirmIcon} /> Open Details
        </button>
      </div>
    </div>
  </div>
);

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fefefe",
    borderRadius: "16px",
    padding: "30px",
    position: "relative",
    width: "90%",
    maxWidth: "420px",
    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
    color: "#333",
  },
  closeButton: {
    position: "absolute",
    top: "15px",
    right: "15px",
    background: "none",
    border: "none",
    fontSize: "1.5rem",
    cursor: "pointer",
    color: "#888",
  },
  iconContainer: {
    backgroundColor: "#FF204E",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto 20px",
  },
  headerIcon: {
    color: "#fff",
    fontSize: "2.5rem",
  },
  header: {
    fontSize: "1.8rem",
    color: "#333",
    fontWeight: "600",
    marginBottom: "15px",
  },
  subheader: {
    fontSize: "1rem",
    color: "#555",
    marginBottom: "25px",
  },
  orderInfo: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  orderDetail: {
    margin: "10px 0",
    fontSize: "1rem",
    color: "#444",
  },
  confirmButton: {
    backgroundColor: "#FF204E",
    color: "#fff",
    borderRadius: "50px",
    padding: "12px 25px",
    border: "none",
    fontSize: "1.1rem",
    fontWeight: "600",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 12px rgba(255, 32, 78, 0.4)",
    transition: "transform 0.2s ease",
  },
  confirmIcon: {
    marginRight: "10px",
    fontSize: "1.4rem",
  },
};

export default NewOrder;
