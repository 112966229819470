import { Fragment } from "react";
import PropTypes from "prop-types";
import { serverOrderStatus } from "../utils/constants";
import { formatDate, formatTime } from "../utils/dateUtils";
import useMediaQuery from "../hooks/useMediaQuery";

const MAIN_STATUSES = [
  "Confirmed",
  "Shipping",
  "Out For delivery",
  "Delivered",
];

const Tracker = ({ currentStatus, statusHistory }) => {
  const maxMd = useMediaQuery("(min-width: 768px)");

  const sortedStatusHistory = statusHistory.sort(
    (a, b) =>
      serverOrderStatus.indexOf(a.status) - serverOrderStatus.indexOf(b.status)
  );

  const mergedStatusHistory = MAIN_STATUSES.map((mainStatus) => {
    const matchingHistory = sortedStatusHistory.find(
      (status) => status.status === mainStatus
    );
    return (
      matchingHistory || {
        status: mainStatus,
        date: null,
        _id: `placeholder-${mainStatus}`,
      }
    );
  });

  const currentStatusIndex = mergedStatusHistory.findIndex(
    (status) => status.status === currentStatus
  );

  return (
    <div className="relative">
      <div className="PROGRESS-BAR px-16 pt-8 pb-16 max-md:p-8 flex max-md:flex-col justify-between md:items-center border-b border-black">
        {mergedStatusHistory.map((status, index) => (
          <Fragment key={status._id}>
            <div className="relative">
              <span
                className={`size-6 border-3 rounded-full flex justify-center items-center ${
                  index <= currentStatusIndex && status.date
                    ? "border-primeRed"
                    : "border-darkGray"
                }`}
              >
                {index <= currentStatusIndex && status.date && (
                  <span className="block size-4 bg-primeRed rounded-full" />
                )}
              </span>

              <div className="absolute md:mt-1 md:left-1/2 md:-translate-x-1/2 max-md:top-1/2 max-md:left-10 max-md:-translate-y-1/2 w-max text-sm md:text-center font-medium">
                <p
                  className={`first-letter:uppercase ${
                    index <= currentStatusIndex && status.date
                      ? "text-primeRed"
                      : "text-primary"
                  }`}
                >
                  {status.status}
                </p>
                <p>{status.date ? formatDate(new Date(status.date)) : ""}</p>
              </div>

              {status.status === currentStatus && maxMd ? (
                <span className="absolute size-5 left-1/2 -translate-x-1/2 top-[78px] rotate-45 bg-white border-t border-l border-black" />
              ) : null}
            </div>

            {index !== mergedStatusHistory.length - 1 && (
              <div className="relative md:h-[3px] md:w-full max-md:w-[3px] max-md:h-24 max-md:left-[10px] bg-darkGray overflow-hidden">
                {index < currentStatusIndex && status.date && (
                  <span className="absolute md:h-[3px] md:w-full max-md:w-[3px] max-md:h-full bg-primeRed transition-transform duration-500 ease-out origin-left" />
                )}
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {currentStatusIndex !== -1 && (
        <div className="PROGRESS-DETAILS px-12 max-md:px-8 py-3 max-md:py-2 flex items-center gap-12">
          <div className="font-semibold font-secondary text-center">
            <p className="text-3xl">
              {new Date(mergedStatusHistory[currentStatusIndex].date).getDate()}
            </p>
            <p>
              {new Date(mergedStatusHistory[currentStatusIndex].date)
                .toLocaleString("default", { month: "short" })
                .toUpperCase()}
            </p>
          </div>
          <div>
            <p className="font-medium">{`Order is ${currentStatus.toLowerCase()}`}</p>
            <p className="text-sm text-primary">
              {formatTime(
                new Date(mergedStatusHistory[currentStatusIndex].date)
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

Tracker.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Tracker;
