import { MdFileDownload } from "react-icons/md";
import Button from "../../components/Button";
import SearchBox from "../../components/SearchBox";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import CategoryList from "./CategoryList";

const Categories = () => {
  const navigate = useNavigate();

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Categories</h1>
      <div className="HEADING flex justify-between items-center">
        <SearchBox name="search" placeHolder="search category name" />
        <div className="flex gap-4">
          <Button
            reverse
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <MdFileDownload className="mt-[3px]" />
            <p className="text-sm">export</p>
          </Button>
          <Button
            onClick={() => navigate("/category/add")}
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <FiPlus />
            <p className="text-sm">add category</p>
          </Button>
        </div>
      </div>
      <CategoryList />
    </section>
  );
};

export default Categories;
