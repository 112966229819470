import { FaRegEye, FaRegFilePdf } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../utils/dateUtils";
import OrderStatus from "./OrderStatus";
import { getColorForStatus } from "../../utils/constants";
import { useDownloadInvoice, useOrders } from "../../services/orderServices";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import Loading from "../../components/Loading";
import { useToast } from "../../contexts/toastContext";
import "./OrderList.css";
import { useAdmin } from "../../contexts/adminContext";
import { useState } from "react";
import {ShimmerCategoryItems,ShimmerDiv,ShimmerButton} from "shimmer-effects-react";
const OrderList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useSearchParamsState("page", 1);
  const [limit] = useSearchParamsState("limit", 10);
  const [startDate] = useSearchParamsState("startDate");
  const [endDate] = useSearchParamsState("endDate");
  const [status, setStatus] = useSearchParamsState("status");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const downloadMutation = useDownloadInvoice();
  const admin = useAdmin();
  const toast = useToast();

  const { data, isLoading, isFetching, error } = useOrders({
    status,
    page,
    limit,
    startDate,
    endDate,
  });

  const handleStatusChange = (newStatus) => {
    setPage(1);
    setStatus(newStatus === "All Orders" ? "" : newStatus);
  };

  const handleCheckboxToggle = (orderId) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handleDownloadInvoice = (orderId) => {
    downloadMutation.mutate(orderId, {
      onSuccess: (response) => {
        const invoiceUrl = response.invoicePath;
        window.open(invoiceUrl, "_blank");
      },
      onError: (error) => {
        console.error(error.message);
        toast.error("This order is not shipped yet.");
      },
    });
  };

  if ( isLoading || (!error && !data) ) {
    return (
      <>
      
        <div className="flex items-center mt-8">
          {/* Shimmer Button loading animation */}
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="mr-5 mt-5 mb-5" key={index}>
              <ShimmerButton size="md" mode="light" rounded={1} />
            </div>
          ))}
        </div>

        {/* Shimmer Category loading animation */}

        {/* Shimmer Table loading animation */}
        <table className="w-full bg-gray-50 rounded-lg shadow-md overflow-hidden mt-12">
          <thead className="bg-slate-900 text-white text-left">
            <tr>
             
              {["Order Id", "Items", "Payment", "Customer", "Date", "Total", "Status", "Action"].map((header, idx) => (
                <th key={idx} className="px-4 py-3 font-semibold">
                <ShimmerDiv mode="light" height={20} width={70} rounded={1}/>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 8 }).map((_, idx) => (
              <tr key={idx} className="border-b border-gray-200 hover:bg-gray-100">
                {/* Placeholder for order rows */}
                <td className="px-4 py-3">
                <ShimmerDiv mode="light" height={20} width={70} rounded={1}/>
                </td>
                <td className="px-4 py-3 flex flex-row items-center space-x-2">
                <ShimmerDiv mode="light" height={60} width={60} rounded={1}/>
                <ShimmerDiv mode="light" height={20} width={80} rounded={1}/>
                </td>
                <td className="px-4 py-3">
                  <ShimmerButton size="sm" mode="light" rounded={4} />
                </td>
                <td className="px-4 py-3">
                <ShimmerDiv mode="light" height={16} width={80} rounded={1}/>
                <div className="mt-4" />
                <ShimmerDiv mode="light" height={16} width={60} rounded={1}/>
                </td>
                <td className="px-4 py-3">
                <ShimmerDiv mode="light" height={16} width={100} rounded={1}/>
                <div className="mt-4" />
                <ShimmerDiv mode="light" height={16} width={38} rounded={1}/>
                </td>
                <td className="px-4 py-3">
                <ShimmerDiv mode="light" height={16} width={38} rounded={1}/>
                </td>
                <td className="px-4 py-3">
                <ShimmerDiv mode="light" height={16} width={50} rounded={1}/>
                </td>
                <td className="px-4 py-3">
                <ShimmerDiv mode="light" height={16} width={60} rounded={1}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center mt-8">
        <h2 className="font-medium text-xl text-red-500">{error.message}</h2>
      </div>
    );
  }

  return (
    <div className="">
      <div className="mb-6 flex justify-between items-center">
        {/* <h1 className="text-2xl font-semibold">Orders</h1> */}
       
      </div>

      <OrderStatus
        currentStatus={status}
        onStatusChange={handleStatusChange}
        statusCount={data?.statusCount}
      />

      <table className="w-full bg-gray-50 rounded-lg shadow-md overflow-hidden">
        <thead className="bg-slate-900 text-white text-left">
          <tr>
                <div className="px-4 py-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-purple-600"
                  checked={false}
                  onChange={() => {}}
                />
           </div>
            {[ "Order Id", "Items"," Payment ", "Customer", "Date", "Total", "Status", "Action"].map(
              (header, idx) => (
                <th key={idx} className="px-4 py-3 font-semibold">
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {data?.orderDetails?.map((order, index) => (
            <tr
              key={order._id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              {/* Checkbox */}
              <td className="px-4 py-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-purple-600"
                  checked={selectedOrders.includes(order._id)}
                  onChange={() => handleCheckboxToggle(order._id)}
                />
              </td>

              {/* Order ID */}
              <td className="px-4 py-3 text-sm">#{order._id.slice(0, 8)}</td>

              {/* Items */}
              <td className="px-4 py-3 text-sm">
                <div className="flex items-center gap-2 max-w-48 overflow-hidden">
                  <div className="h-12 w-12 flex min-w-12">
                    <img
                      src={
                        order.orderItem[0]?.productId?.images[0] || "/placeholder.png"
                      }
                      alt="Product"
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div>
                    <p className="text-xs font-medium truncate">
                      {order.orderItem[0]?.productId?.name || "Invalid Product"}
                    </p>
                    {order.orderItem.length > 1 && (
                      <p className="text-xs text-gray-500">
                        +{order.orderItem.length - 1} more
                      </p>
                    )}
                  </div>
                </div>
              </td>

              <td className="px-4 py-3 text-sm self-center">
                
                <span className={`text-gray-500 font-semibold text-xxs px-2 py-1 rounded-xl border-2 ${order.payment_complete ? "bg-green-200 text-green-600 border-green-600":"bg-red-200 text-red-600 border-red-600"}`}>
                  {order.payment_complete?'PAID':'UNPAID'}
                </span>
              </td>
              

              {/* Customer Info */}
              <td className="px-4 py-3 text-sm">
                {order.address.receiverName}
                <br />
                <span className="text-gray-500 text-xs">
                  {order.address.receiverPhone}
                </span>
              </td>

              {/* Date */}
             
              <td className="px-4 py-3 text-sm">
                {formatDate(new Date(order.createdAt))}
                <br />
                {formatTime(new Date(order.createdAt))}
              </td>
              {/* Total Price */}
              <td className="px-4 py-3 text-sm">₹ {order.totalPrice}</td>

              {/* Fulfillment Status */}
              <td className="px-4 py-3">
                <span
                  className={`py-1 px-3 rounded-full text-gray-600 text-xs border-2 border-gray-400 font-medium ${getColorForStatus(
                    order.status
                  )}`}
                >
                  {order.status}
                </span>
              </td>

              {/* Actions */}
              <td className="px-4 py-3">
                {admin?.admin?.userLevel <= 1 && (
                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => navigate(`/order/${order._id}`)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <FaRegEye />
                    </button>
                    <button
                      onClick={() => handleDownloadInvoice(order._id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <FaRegFilePdf />
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-6">
        <Pagination
          currentPage={parseInt(page, 10)}
          totalCount={data?.totalOrders}
          pageSize={limit}
          onPageChange={(page) => setPage(page.toString())}
        />
      </div>
    </div>
  );
};

export default OrderList;
