import axiosInstance from "./axiosInstance";

const getTotalIncome = () => {
  return axiosInstance.get("/getTotalIncome");
};

const getTotalOrder = () => {
  return axiosInstance.get("/getTotalOrder");
};

const getTotalSale = () => {
  return axiosInstance.get("/getProductSold");
};

const getTotalCustomer = () => {
  return axiosInstance.get("/getTotalUser");
};

export { getTotalIncome, getTotalOrder, getTotalSale, getTotalCustomer };
