import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import AccountModal from "./AccountModal";
import { useAdmin } from "../../contexts/adminContext";

const AccountTab = ({isCollapsed}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const admin = useAdmin()
  // console.log(admin)

  return (
    <>
      {/* AccountTab */}
     {!isCollapsed ?<div
        className="mr-15 bg-white  border border-primary rounded-lg px-4 py-2 flex items-center gap-4 w-64 cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out"
        onClick={handleOpenModal}
      >
        {/* User Avatar */}
        <div className="size-10 bg-primeRed rounded-full flex items-center justify-center">
          <span className="text-white font-bold">F</span>
        </div>

        {/* User Info */}
        <div className="font-secondary leading-tight">
          <p className="font-semibold text-base">{admin?.admin?.fullName}</p>
          <p className="text-sm text-primary">{admin?.admin?.email}</p>
        </div>

      
      </div>:
      
      <div onClick={handleOpenModal} className="size-10 bg-primeRed rounded-full flex items-center justify-center">
          <span className="text-white font-bold">F</span>
        </div>
      }
      

      {/* Modal */}
      {isModalOpen && <AccountModal onClose={handleCloseModal} />}
    </>
  );
};

export default AccountTab;
