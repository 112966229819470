import {
  EMAIL_REGEX,
  NAME_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  PIN_CODE_REGEX,
} from "./constants";

const isNameValid = (name) => {
  if (!name)
    return {
      success: false,
      message: "Name is required",
    };
  if (name.length < 3)
    return {
      success: false,
      message: "Name should be more than 3 characters",
    };

  const isValid = NAME_REGEX.test(name);
  if (!isValid)
    return {
      success: false,
      message: "Please enter a valid name",
    };

  return {
    success: true,
    message: "OK",
  };
};

const isEmailValid = (email) => {
  if (!email)
    return {
      success: false,
      message: "Email is required",
    };
  if (email.length > 254)
    return {
      success: false,
      message: "Please enter a valid email",
    };

  const isValid = EMAIL_REGEX.test(email);
  if (!isValid)
    return {
      success: false,
      message: "Please enter a valid email",
    };

  const parts = email.split("@");
  if (parts[0].length > 64)
    return {
      success: false,
      message: "Please enter a valid email",
    };

  return {
    success: true,
    message: "OK",
  };
};

const isPhoneValid = (phone) => {
  if (!phone)
    return {
      success: false,
      message: "Phone is required",
    };

  const isValid = PHONE_REGEX.test(phone);
  if (!isValid)
    return {
      success: false,
      message: "Please enter a valid phone",
    };

  return {
    success: true,
    message: "OK",
  };
};

const isPasswordValid = (password) => {
  if (!password)
    return {
      success: false,
      message: "Password is required",
    };

  const isValid = PASSWORD_REGEX.test(password);
  if (!isValid)
    return {
      success: false,
      message:
        "Your password must be have at least 8 characters long, 1 uppercase & 1 lowercase character, 1 number",
    };

  return {
    success: true,
    message: "OK",
  };
};

const isPinCodeValid = (pinCode) => {
  if (!pinCode) {
    return {
      success: false,
      message: "Pin code is required",
    };
  }

  const isValid = PIN_CODE_REGEX.test(pinCode);
  if (!isValid) {
    return {
      success: false,
      message: "Please enter a valid pin code",
    };
  }

  return {
    success: true,
    message: "OK",
  };
};

export {
  isNameValid,
  isEmailValid,
  isPhoneValid,
  isPasswordValid,
  isPinCodeValid,
};
