import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addBalance, getCustomerWallet } from "../api/walletApis";

const useCustomerWallet = (customerId) => {
  return useQuery({
    queryKey: ["wallet", customerId],
    queryFn: () => getCustomerWallet(customerId),
    staleTime: 1000 * 60 * 5,
    enabled: !!customerId,
  });
};

const useAddBalance = (customerId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ amount, description, userId }) =>
      addBalance(amount, description, userId),
    onSuccess: () => {
      queryClient.invalidateQueries(["wallet", customerId]);
    },
  });
};

export { useCustomerWallet, useAddBalance };
