import { IoSearch } from "react-icons/io5";
import Button from "./Button";

const SearchBox = ({ name, placeHolder, value = "", onChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className="h-10 w-80 relative flex">
      <input
        type="search"
        placeholder={placeHolder}
        name={name}
        value={value}
        onChange={onChange}
        className="h-full w-60 rounded-tl-md rounded-bl-md border border-primary outline-none pl-12 pr-4 placeholder:capitalize placeholder:text-sm placeholder:text-grey placeholder:font-secondary flex-shrink-0"
      />

      <span className="absolute left-3 top-1/2 -translate-y-1/2">
        <IoSearch className="text-primary text-2xl" />
      </span>

      <Button className="h-full w-20 px-4 py-2 rounded-tr-md rounded-br-md">
        search
      </Button>
    </form>
  );
};

export default SearchBox;
