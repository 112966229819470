import { FaRegMoneyBillAlt } from "react-icons/fa";
import { getColorForStatus } from "../../utils/constants";

const PaymentInfo = ({ order }) => {
  return (
    <div className="w-90 border border-darkGray rounded-xl bg-white shadow-pop">
      <div className="HEAD flex items-center gap-2 px-4 py-2 border-b border-primary">
        <span className="bg-rose p-1 rounded-full text-primeRed">
          <FaRegMoneyBillAlt />
        </span>
        <h2 className="font-medium">Payment</h2>
      </div>

      <div className="BODY p-4">
        <table className="w-full">
          <tbody>
            <tr>
              <th className="text-sm font-medium text-left w-max py-1 pr-2">
                Transaction ID:
              </th>
              <td>
                <div className="text-xs font-medium">{order.transactionId}</div>
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium text-left w-max py-1 pr-2">
                Payment Method:
              </th>
              <td>
                <div className="text-sm font-medium">
                  {order.payment_method}
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium text-left w-max py-1 pr-2">
                Order Status:
              </th>
              <td>
                <div
                  className={`${getColorForStatus(
                    order.status
                  )} rounded-full font-secondary text-white text-xxs py-[2px] px-2 w-max`}
                >
                  {order.status}
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium text-left py-1 pr-2">
                Payment Status:
              </th>
              <td>
                <div
                  className={`rounded-full text-white font-secondary text-xxs py-1 px-2 leading-none w-max ${
                    order.payment_complete ? "bg-green-500" : "bg-red-500"
                  }`}
                >
                  {order.payment_complete ? "Paid" : "Not Paid"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentInfo;
