import { FaRegEdit, FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import SearchBox from "../../components/SearchBox";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../contexts/modalContext";
import DeleteCoupon from "./DeleteCoupon";
import CouponCard from "./CouponCard";
import useCoupon from "../../hooks/useCoupon";
import { formatDate } from "../../utils/dateUtils";
import { useToast } from "../../contexts/toastContext";
import useForm from "../../hooks/useForm";
import { useEffect } from "react";
import { useCoupons } from "../../services/dealServices";

const CouponList = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { data: coupons, isLoading, error } = useCoupons();
  const { removeCoupon, updateStatus } = useCoupon();
  const { formData, setFormData } = useForm({});
  const toast = useToast();

  useEffect(() => {
    const initialFormData = coupons.reduce((acc, coupon) => {
      acc[coupon._id] = { status: coupon.status };
      return acc;
    }, {});
    setFormData(initialFormData);
  }, [coupons, setFormData]);

  const handleDelete = (couponId) => {
    removeCoupon(couponId).then(() => {
      toast.error("Coupon deleted successfully");
      closeModal();
    });
  };

  const handleStatusChange = (couponId, e) => {
    const newStatus = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [couponId]: { ...prevData[couponId], status: newStatus },
    }));
    updateStatus(couponId, newStatus);
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Coupons
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return coupons.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Coupons Found
      </h2>
    </div>
  ) : (
    <div className="bg-white rounded-xl shadow-pop overflow-hidden">
      <div className="p-4 flex justify-between items-center">
        <div className="HEADING flex items-center gap-4">
          <h3 className="text-xl font-medium">Coupon List</h3>
          <span className="bg-mainGray size-5 rounded-full flex justify-center items-center text-xs font-medium">
            {coupons.length}
          </span>
        </div>
        <SearchBox />
      </div>
      <div className="w-full flex flex-col justify-between">
        <table className="w-full">
          <thead className="bg-lightGray">
            <tr>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                SL
              </th>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                Coupon
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Coupon Type
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Start/Expire
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                User Limit
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Discount Bearer
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Active
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon, index) => (
              <tr key={index}>
                <td className="px-6 py-3">{index + 1}</td>
                <td className="px-6 py-3 text-sm">
                  <p>{coupon.title}</p>
                  <p className="font-semibold">Code: {coupon.code}</p>
                </td>
                <td className="px-6 py-3 text-center">
                  <p className="text-sm">{coupon.couponType}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    {formatDate(new Date(coupon.Start_Date))}
                    {" / "}
                    {formatDate(new Date(coupon.Expire_Date))}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{coupon.Limit_For_Same_User}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{coupon.Coupon_Bearer}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <ToggleSwitch
                    name="status"
                    checked={formData[coupon._id]?.status || coupon.status}
                    onChange={(e) => handleStatusChange(coupon._id, e)}
                    className="mx-auto w-10 h-5"
                  />
                </td>
                <td className="px-4 py-3">
                  <div className="flex justify-center items-center gap-4">
                    <button onClick={() => openModal(<CouponCard />)}>
                      <FaRegEye className="text-info" />
                    </button>
                    <button
                      onClick={() => navigate(`/coupon/edit/${coupon._id}`)}
                    >
                      <FaRegEdit className="text-success" />
                    </button>
                    <button
                      onClick={() =>
                        openModal(
                          <DeleteCoupon
                            onDelete={() => handleDelete(coupon._id)}
                          />
                        )
                      }
                    >
                      <FaRegTrashAlt className="text-error" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponList;
