import FashionForm from "./FashionForm";
import FashionList from "./FashionList";

const Fashion = () => {
  return (
    <section className="p-4 space-y-16">
      <FashionForm />
      <FashionList />
    </section>
  );
};

export default Fashion;
