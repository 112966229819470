import { useEffect, useState } from "react";
import FileInput from "../../../components/FileInput";
import InputBox from "../../../components/InputBox";
import TextareaBox from "../../../components/TextareaBox";
import useForm from "../../../hooks/useForm";
import Button from "../../../components/Button";
import { GoImage } from "react-icons/go";
import SelectBox from "../../../components/SelectBox";
import { useCategories } from "../../../services/categoryServices";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../contexts/toastContext";
import useGallery from "../../../hooks/useGallery";
import { useGalleryBannerById } from "../../../services/utsavServices";

const GalleryForm = ({ edit = false }) => {
  const { bannerId } = useParams();
  const { data: categories, isLoading: categoriesLoading } = useCategories();
  const [selectedImage, setSelectedImage] = useState(null);
  const { data: banner, isLoading, error } = useGalleryBannerById(bannerId);
  const { addBanner, editBanner } = useGallery(bannerId);
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmitHandler = (bannerData) => {
    console.log(bannerData);
    const { bannerImg } = bannerData;
    try {
      if (edit) {
        editBanner(bannerId, { ...bannerData, bannerImg: bannerImg[0] }).then(
          () => {
            toast.success("Banner updated successfully");
            navigate(-1);
          }
        );
      } else {
        addBanner({ ...bannerData, bannerImg: bannerImg[0] }).then(() => {
          toast.success("Banner created successfully");
          navigate(-1);
        });
      }
    } catch (error) {}
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && banner) {
      setFormData(banner);
      if (banner.bannerImg) {
        setSelectedImage(banner.bannerImg);
      }
    }
  }, [banner, edit, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  if (isLoading || (edit && !banner)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">
        {edit ? "Update Image" : "Add Image"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[45%] space-y-4">
          <SelectBox
            label="category"
            name="categoryId"
            value={formData.categoryId}
            onChange={handleChange}
            options={categories.map((cat) => ({
              label: cat.name,
              value: cat._id,
            }))}
            disabled={categoriesLoading}
          />
          <InputBox
            label="banner title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          <TextareaBox
            label="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="w-[50%] flex flex-col items-end gap-8">
          <div
            className={`size-80 flex items-center justify-center ${
              !selectedImage && "bg-lightGray"
            }`}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="banner"
                className="size-full object-contain object-center"
              />
            ) : (
              <GoImage size={24} className="text-primary" />
            )}
          </div>

          <FileInput
            label="banner image"
            accept=".jpg, .jpeg, .png, .gif"
            name="bannerImg"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2 w-80"
          />

          <div className="flex gap-8 justify-end">
            <Button type="button" onClick={clearForm} reverse>
              Reset
            </Button>
            <Button>submit</Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default GalleryForm;
