import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createFashionBlog,
  createFashionCategory,
  deleteFashionBlog,
  deleteFashionCategory,
  editFashionBlog,
  editFashionCategory,
  getAllFashionBlogs,
  getAllFashionCategories,
  getFashionBlogById,
  getFashionCategoryById,
} from "../api/fashionApis";

const useFashionCategories = () => {
  return useQuery({
    queryKey: ["fashionCategories"],
    queryFn: getAllFashionCategories,
    staleTime: 1000 * 60 * 5,
  });
};

const useFashionCategoryById = (fashionCategoryId) => {
  return useQuery({
    queryKey: ["fashionCategory", fashionCategoryId],
    queryFn: () => getFashionCategoryById(fashionCategoryId),
    staleTime: 1000 * 60 * 5,
    enabled: !!fashionCategoryId,
  });
};

const useCreateFashionCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createFashionCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["fashionCategories"]);
    },
  });
};

const useUpdateFashionCategory = (fashionCategoryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ fashionCategoryId, fashionData }) =>
      editFashionCategory(fashionCategoryId, fashionData),
    onSuccess: () => {
      queryClient.invalidateQueries(["fashionCategory", fashionCategoryId]);
    },
  });
};

const useDeleteFashionCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteFashionCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["fashionCategories"]);
    },
  });
};

const useFashionBlogs = () => {
  return useQuery({
    queryKey: ["fashionBlogs"],
    queryFn: getAllFashionBlogs,
    staleTime: 1000 * 60 * 5,
  });
};

const useFashionBlogById = (fashionBlogId) => {
  return useQuery({
    queryKey: ["fashionBlog", fashionBlogId],
    queryFn: () => getFashionBlogById(fashionBlogId),
    staleTime: 1000 * 60 * 5,
    enabled: !!fashionBlogId,
  });
};

const useCreateFashionBlog = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createFashionBlog,
    onSuccess: () => {
      queryClient.invalidateQueries(["fashionBlogs"]);
    },
  });
};

const useUpdateFashionBlog = (fashionBlogId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ fashionBlogId, blogData }) =>
      editFashionBlog(fashionBlogId, blogData),
    onSuccess: () => {
      queryClient.invalidateQueries(["fashionBlog", fashionBlogId]);
    },
  });
};

const useDeleteFashionBlog = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteFashionBlog,
    onSuccess: () => {
      queryClient.invalidateQueries(["fashionBlogs"]);
    },
  });
};

export {
  useFashionCategories,
  useFashionCategoryById,
  useCreateFashionCategory,
  useUpdateFashionCategory,
  useDeleteFashionCategory,
  useFashionBlogs,
  useFashionBlogById,
  useCreateFashionBlog,
  useUpdateFashionBlog,
  useDeleteFashionBlog,
};
