const Approvals = () => {
  return (
    <section className="space-y-4 p-4">
      <h3 className="text-3xl font-medium">Pending Approvals</h3>

      <div className="bg-white rounded-xl shadow-pop overflow-hidden">
        <div className="w-full flex flex-col justify-between">
          <table className="w-full">
            <thead className="bg-lightGray">
              <tr>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  SL
                </th>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  Post
                </th>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  Caption
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6].map((deal, index) => (
                <tr key={index}>
                  <td className="px-6 py-3">{index + 1}</td>
                  <td className="px-6 py-3">
                    <div className="size-32 bg-mainGray"></div>
                  </td>
                  <td className="px-6 py-3 text-sm">
                    <p className="text-sm">Caption {deal}</p>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex justify-center items-center gap-4">
                      <button className="border border-success rounded-full px-2 text-xs text-success hover:bg-success hover:text-white transition-colors duration-150 ease-in-out">
                        Approve
                      </button>
                      <button className="border border-error rounded-full px-2 text-xs text-error hover:bg-error hover:text-white transition-colors duration-150 ease-in-out">
                        Reject
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Approvals;
