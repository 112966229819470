import SearchBox from "../../../components/SearchBox";
import Pagination from "../../../components/Pagination";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { useMemo } from "react";
import { formatDate, formatTime } from "../../../utils/dateUtils";

const Transactions = ({ transactions }) => {
  const [page, setPage] = useSearchParamsState("transaction-page", 1);
  const limit = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (parseInt(page, 10) - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return transactions.slice(firstPageIndex, lastPageIndex);
  }, [page, transactions, limit]);

  return (
    <section className="space-y-4">
      <div className="flex justify-between items-start">
        <h1 className="text-2xl font-medium">Wallet Transactions</h1>
        <SearchBox placeHolder="search by name" />
      </div>

      {currentTableData.length === 0 ? (
        <div className="w-full">
          <h2 className="text-center font-secondary font-medium text-xl mt-8">
            No Transactions Yet
          </h2>
        </div>
      ) : (
        <div className="bg-white rounded-xl overflow-hidden shadow-pop">
          <table className="w-full">
            <thead className="bg-mainGray">
              <tr className="divide-x divide-primary">
                <th scope="col" className="px-6 py-4 font-medium">
                  SL
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Name
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Amount
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Status
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Date / Time
                </th>
                {/* <th scope="col" className="px-6 py-4 font-medium">
                  Admin Approval
                </th> */}
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((transaction, index) => (
                <tr key={index} className="text-sm divide-x divide-primary">
                  <td className="px-6 py-3 text-center border-y border-primary border-collapse">
                    {index + 1}
                  </td>
                  <td className="px-6 py-3 text-center border-y border-primary border-collapse">
                    <p>{transaction.userId?.fullName}</p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p>{transaction.amount}</p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p className="capitalize">{transaction.type}</p>
                  </td>
                  <td className="px-4 py-3 text-center border-y border-primary border-collapse">
                    <p>{formatDate(new Date(transaction.date))}</p>
                    <p>{formatTime(new Date(transaction.date))}</p>
                  </td>
                  {/* <td className="px-4 py-3 space-x-4 text-center border-y border-primary border-collapse">
                    <button onClick={null}>
                      <FiCheckSquare size={18} className="text-success" />
                    </button>
                    <button onClick={null}>
                      <FiXSquare size={18} className="text-error" />
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={parseInt(page, 10)}
            totalCount={transactions.length}
            pageSize={limit}
            onPageChange={(page) => setPage(page.toString())}
          />
        </div>
      )}
    </section>
  );
};

export default Transactions;
