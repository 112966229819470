import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getAdminDetails } from "../api/adminApis";
import { useAuth } from "./authContext";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { auth, logout } = useAuth();
  const adminFetched = useRef(false);

  const fetchAdmin = useCallback(
    async (accessToken) => {
      setIsLoading(true);
      try {
        const response = await getAdminDetails(accessToken);
        if (response?.status) {
          setAdmin((prev) => {
            return { ...response.data };
          });
          console.log("Admin data fetched:", response.data);
        }
      } catch (error) {
        console.error("Error fetching admin:", error);
        if (error.response?.status === 401) {
          // Unauthorized, logout the admin
          logout();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [logout]
  );

  useEffect(() => {
    const { accessToken } = auth;
    if (!adminFetched.current && accessToken) {
      fetchAdmin(accessToken);
      adminFetched.current = true;
    } else if (!accessToken) {
      setAdmin(null);
      setIsLoading(false);
      adminFetched.current = false;
    }
  }, [auth, fetchAdmin]);

  return (
    <AdminContext.Provider value={{ admin, isLoading }}>
      {children}
    </AdminContext.Provider>
  );
};

export function useAdmin() {
  return useContext(AdminContext);
}
