import { MdOutlineSupervisedUserCircle } from "react-icons/md";
import ToggleSwitch from "../../../components/ToggleSwitch";
import useForm from "../../../hooks/useForm";
import { useDesigner, useMakeDesigner } from "../../../services/utsavServices";

const Details = ({ user }) => {
  const { isSuccess: isDesigner } = useDesigner(user._id);
  const designerMutation = useMakeDesigner(user._id);
  const { formData, setFormData } = useForm({});

  const handleToggleDesigner = (userId, e) => {
    const isDesigner = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [userId]: { ...prevData[userId], isDesigner },
    }));
    designerMutation.mutate({ userId, status: isDesigner });
  };

  return (
    <div className="w-90 h-44 rounded-xl bg-yellow-100 shadow-pop">
      <div className="HEAD flex items-center gap-2 px-4 py-2 border-b border-primary">
        <span className="bg-rose p-1 rounded-full text-yellow-500">
          <MdOutlineSupervisedUserCircle />
        </span>
        <h2 className="font-medium">Member Details</h2>
      </div>

      <div className="BODY px-4 py-2">
        <table className="w-full">
          <tbody>
            <tr>
              <th className="text-sm font-medium text-left py-1">Name:</th>
              <td className="text-sm">{user.fullName}</td>
            </tr>
            <tr>
              <th className="text-sm font-medium text-left py-1">Phone No:</th>
              <td className="text-sm">{user.phone}</td>
            </tr>
            <tr>
              <th className="text-sm font-medium text-left py-1">Email Id:</th>
              <td className="text-sm">{user.email}</td>
            </tr>
            <tr>
              <th className="text-sm font-medium text-left py-1">Designer:</th>
              <td className="text-sm">
                <ToggleSwitch
                  name="isDesigner"
                  checked={formData.isDesigner || isDesigner}
                  onChange={(e) => handleToggleDesigner(user._id, e)}
                  className="w-10 h-5"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Details;
