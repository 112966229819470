import axiosInstance from "./axiosInstance";

const getAllCategories = async () => {
  const response = await axiosInstance.get("/getAllCategory");
  return response.data?.mainCategoryList;
};

const getCategory = async (categoryId) => {
  const response = await axiosInstance.get(`/getCategoryId/${categoryId}`);
  return response.data?.categoryDetails;
};

const getAllSubcategories = async () => {
  const response = await axiosInstance.get("/getAllSubCategory");
  return response.data?.subCategoryList;
};

const getSubcategories = async (categoryId) => {
  const response = await axiosInstance.get(
    `/getSubcategoryBasedOnCategory/${categoryId}`
  );
  return response.data?.subCategoryList;
};

const getSubcategory = async (subcategoryId) => {
  const response = await axiosInstance.get(
    `/getSubCategoryId/${subcategoryId}`
  );
  return response.data?.subCategoryIdDetails;
};

const getAllProductTypes = async () => {
  const response = await axiosInstance.get("/getAllProductType");
  return response.data?.productTypeList;
};

const getProductTypes = async (subcategoryId) => {
  const response = await axiosInstance.get(
    `/getProductTypeBasedOnSubCategory/${subcategoryId}`
  );
  return response.data?.productTypeList;
};

const getProductType = async (productTypeId) => {
  const response = await axiosInstance.get(
    `/getProductTypeById/${productTypeId}`
  );
  return response.data?.productTypeDetails;
};

const createCategory = async (categoryData) => {
  const response = await axiosInstance.post("/createCategory", categoryData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const updateCategory = async (categoryId, categoryData) => {
  const response = await axiosInstance.post(
    `/editCategory/${categoryId}`,
    categoryData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const deleteCategory = async (categoryId) => {
  const response = await axiosInstance.get(`/deleteCategory/${categoryId}`);
  return response.data;
};

const createSubcategory = async (subcategoryData) => {
  const response = await axiosInstance.post(
    "/createSubCategory",
    subcategoryData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const updateSubcategory = async (subcategoryId, subcategoryData) => {
  const response = await axiosInstance.post(
    `/editSubCategory/${subcategoryId}`,
    subcategoryData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const deleteSubcategory = async (subcategoryId) => {
  const response = await axiosInstance.get(
    `/deleteSubCategory/${subcategoryId}`
  );
  return response.data;
};

const createProductType = async (productTypeData) => {
  const response = await axiosInstance.post(
    "/createProductType",
    productTypeData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

const updateProductType = async (productTypeId, productTypeData) => {
  const response = await axiosInstance.post(
    `/editProductType/${productTypeId}`,
    productTypeData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

const deleteProductType = async (productTypeId) => {
  const response = await axiosInstance.get(
    `/deleteProductType/${productTypeId}`
  );
  return response.data;
};

const featureCategory = async (categoryId, featured) => {
  const response = await axiosInstance.post(
    `/featuredMainCategory/${categoryId}`,
    {
      featured,
    }
  );
  return response.data;
};

const featureSubcategory = async (subCategoryId, featured) => {
  const response = await axiosInstance.post(
    `/featuredSubCategory/${subCategoryId}`,
    {
      featured,
    }
  );
  return response.data;
};

const featureProductType = async (productTypeId, featured) => {
  const response = await axiosInstance.post(
    `/makeProductTypeIsFeatured/${productTypeId}`,
    {
      value: featured,
    }
  );
  return response.data;
};

export {
  getAllCategories,
  getAllSubcategories,
  getAllProductTypes,
  getSubcategories,
  getProductTypes,
  getCategory,
  getSubcategory,
  getProductType,
  createCategory,
  updateCategory,
  deleteCategory,
  createSubcategory,
  updateSubcategory,
  deleteSubcategory,
  createProductType,
  updateProductType,
  deleteProductType,
  featureCategory,
  featureSubcategory,
  featureProductType,
};
