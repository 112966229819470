import CustomerRating from "./CustomerRating";
import Overview from "./Overview";
import ProductSold from "./ProductSold";
import RecentReviews from "./RecentReviews";
import SalesByCategory from "./SalesByCategory";
import SalesByProduct from "./SalesByProduct";

const Dashboard = () => {
  return (
    <section className="p-8 space-y-8">
      <Overview />
      <div className="ANALYTICS flex justify-between h-full">
        <SalesByProduct />
       
      </div>
      <div className="ANALYTICS flex justify-between h-full">
      <SalesByCategory />
      <CustomerRating />
      </div>
      
      
    </section>
  );
};

export default Dashboard;
