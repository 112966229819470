import ToggleSwitch from "../../components/ToggleSwitch";
import useDeal from "../../hooks/useDeal";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import useForm from "../../hooks/useForm";
import { useEffect } from "react";
import {
  useDeleteFashionCategory,
  useFashionCategories,
} from "../../services/fashionServices";
import { useModal } from "../../contexts/modalContext";
import DeleteFashion from "./DeleteFashion";
import { useToast } from "../../contexts/toastContext";

const FashionList = () => {
  const { data: categories = [], isLoading, error } = useFashionCategories();
  const deleteMutation = useDeleteFashionCategory();
  const { updateStatus } = useDeal("featured");
  const navigate = useNavigate();
  const toast = useToast();
  const { openModal, closeModal } = useModal();
  const { formData, setFormData } = useForm({});

  useEffect(() => {
    const initialFormData = categories.reduce((acc, category) => {
      acc[category._id] = { status: category.status };
      return acc;
    }, {});
    setFormData(initialFormData);
  }, [categories, setFormData]);

  const handleDelete = (fashionCategoryId) => {
    deleteMutation.mutate(fashionCategoryId, {
      onSuccess: () => {
        toast.success("Fashion category deleted successfully");
        closeModal();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete fashion category");
      },
    });
  };

  const handleStatusChange = (fashionCategoryId, e) => {
    const newStatus = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [fashionCategoryId]: {
        ...prevData[fashionCategoryId],
        status: newStatus,
      },
    }));
    updateStatus(fashionCategoryId, newStatus);
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Fashion Categories
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return categories.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Fashion Category Found
      </h2>
    </div>
  ) : (
    <section className="space-y-4">
      <h3 className="text-3xl font-medium">Fashion List</h3>

      <div className="bg-white rounded-xl shadow-pop overflow-hidden">
        <div className="w-full flex flex-col justify-between">
          <table className="w-full">
            <thead className="bg-lightGray">
              <tr>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  SL
                </th>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  Image
                </th>
                <th scope="col" className="px-6 py-4 text-left font-medium">
                  Title
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Status
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={index}>
                  <td className="px-6 py-3">{index + 1}</td>
                  <td className="px-6 py-3">
                    <div className="size-16 bg-mainGray">
                      <img
                        src={category.logoUrl}
                        alt={category.name}
                        className="size-full object-contain object-center"
                      />
                    </div>
                  </td>
                  <td className="px-6 py-3 text-sm">
                    <p className="text-sm">{category.name}</p>
                  </td>
                  <td className="px-4 py-3 text-center">
                    <ToggleSwitch
                      name="status"
                      checked={
                        formData[category._id]?.status || category.status
                      }
                      onChange={(e) => handleStatusChange(category._id, e)}
                      className="mx-auto w-10 h-5"
                    />
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex justify-center items-center gap-4">
                      <button onClick={() => navigate(`edit/${category._id}`)}>
                        <FaRegEdit className="text-success" />
                      </button>
                      <button
                        onClick={() =>
                          openModal(
                            <DeleteFashion
                              onDelete={() => handleDelete(category._id)}
                            />
                          )
                        }
                      >
                        <FaRegTrashAlt className="text-error" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default FashionList;
