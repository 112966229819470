import { MdFileDownload } from "react-icons/md";
import Button from "../../components/Button";
import SearchBox from "../../components/SearchBox";
import Overview from "./Overview";
import OrderList from "./OrderList";
import DateRangeSelector from "../../components/DateRangeSelector";
import { useSearchParams } from "react-router-dom";

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Handle date range changes
  const handleDateChange = (newStartDate, newEndDate) => {
    const newSearchParams = { ...Object.fromEntries(searchParams.entries()) };
    newSearchParams.startDate = newStartDate;
    newSearchParams.endDate = newEndDate;
    newSearchParams.page = 1;
    setSearchParams(newSearchParams);
  };

  // Handle search query
  const handleSearch = (searchQuery) => {
    const newSearchParams = { ...Object.fromEntries(searchParams.entries()) };
    if (searchQuery) {
      newSearchParams.search = searchQuery;
    } else {
      delete newSearchParams.search; // Remove search param if query is empty
    }
    newSearchParams.page = 1;
    setSearchParams(newSearchParams);
  };

  return (
    <section className="p-4 space-y-4 animate-fade-in">
      <h1 className="text-3xl font-medium text-center sm:text-left">Order List</h1>

      <Overview />

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-2">
        <DateRangeSelector onDateChange={handleDateChange} />
        
        {/* Pass handleSearch to SearchBox */}
        <SearchBox
          name="search"
          placeHolder="Search Order"
          onSearch={handleSearch} // Pass the search handler
        />

        <button
          className="px-4 py-2 bg-primeRed text-white rounded-lg hover:bg-purple-700"
          onClick={() => {}}
        >
          Create Order
        </button>

        <Button
          reverse
          className="rounded-md px-3 py-2 font-medium flex items-center gap-2 justify-center sm:justify-start"
        >
          <MdFileDownload className="mt-[3px]" />
          <p className="text-sm">Export</p>
        </Button>
      </div>

      {/* Pass searchParams to OrderList */}
      <OrderList searchParams={searchParams} /> 
    </section>
  );
};

export default Orders;
