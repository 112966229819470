import { FiUser } from "react-icons/fi";
import { RiUserReceived2Line } from "react-icons/ri";

const ReferredBy = ({ user }) => {
  return (
    <div className="w-90 h-44 rounded-xl bg-green-100 shadow-pop">
      <div className="HEAD flex items-center gap-2 px-4 py-2 border-b border-primary">
        <span className="bg-rose p-1 rounded-full text-green-500">
          <RiUserReceived2Line />
        </span>
        <h2 className="font-medium">Referred By</h2>
      </div>

      <div className="BODY px-4 py-2">
        <div className="flex items-center gap-2 mt-4">
          {user ? (
            <>
              <span className="size-10 bg-mainGray rounded-full flex items-center justify-center">
                <FiUser />
              </span>
              <p className="text-lg">{user.fullName}</p>
            </>
          ) : (
            <p>None</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferredBy;
