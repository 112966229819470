import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useModal } from "../../contexts/modalContext";
import { useNavigate } from "react-router-dom";
import DeleteSubcategory from "./DeleteSubcategory";
import {
  useDeleteSubcategory,
  useFeatureSubcategory,
  useSubcategories,
} from "../../services/categoryServices";
import { useMemo, useState } from "react";
import { useToast } from "../../contexts/toastContext";
import useForm from "../../hooks/useForm";

const SubcategoryList = () => {
  const { data: allSubcategories, isLoading, error } = useSubcategories();
  const deleteMutation = useDeleteSubcategory();
  const featuredMutation = useFeatureSubcategory();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { formData, setFormData } = useForm({});
  const limit = 10;
  const toast = useToast();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return allSubcategories.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allSubcategories]);

  const handleDelete = (subcategoryId) => {
    deleteMutation.mutate(subcategoryId, {
      onSuccess: () => {
        toast.error("Subcategory deleted successfully");
        closeModal();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete subcategory");
      },
    });
  };

  const handleIsFeatured = (subcategoryId, e) => {
    const isFeatured = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [subcategoryId]: { ...prevData[subcategoryId], is_featured: isFeatured },
    }));

    featuredMutation.mutate(
      { subcategoryId, isFeatured },
      {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Subcategories
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return currentTableData.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Sub Categories Found
      </h2>
    </div>
  ) : (
    <div className="w-full min-h-[720px] flex flex-col justify-between bg-white px-8 pt-8 rounded-xl shadow-pop">
      <table className="w-full rounded-xl border border-primary border-separate border-spacing-0 overflow-hidden">
        <thead className="bg-mainGray">
          <tr className="divide-x divide-primary">
            <th scope="col" className="px-6 py-4 font-medium">
              SL
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Subcategory Image
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Name
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Popularity
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Featured
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTableData.map((subcategory, index) => (
            <tr key={index} className="divide-x divide-primary">
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="px-6 py-3">
                <div className="size-24 mx-auto">
                  <img
                    src={subcategory.logoUrl}
                    alt={subcategory.name}
                    className="size-full object-cover object-center"
                  />
                </div>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">{subcategory.name}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">4.3</p>
              </td>
              <td className="px-4 py-3">
                <ToggleSwitch
                  name="is_featured"
                  checked={
                    formData[subcategory._id]?.is_featured ||
                    subcategory?.is_featured
                  }
                  onChange={(e) => handleIsFeatured(subcategory._id, e)}
                  className="mx-auto w-10 h-5"
                />
              </td>
              <td className="px-4 py-3">
                <div className="flex justify-center items-center gap-4">
                  <button
                    onClick={() =>
                      navigate(`/subcategory/edit/${subcategory._id}`)
                    }
                  >
                    <FaRegEdit className="text-success" />
                  </button>
                  <button
                    onClick={() =>
                      openModal(
                        <DeleteSubcategory
                          onDelete={() => handleDelete(subcategory._id)}
                        />
                      )
                    }
                  >
                    <FaRegTrashAlt className="text-error" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalCount={allSubcategories.length}
        pageSize={limit}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default SubcategoryList;
