import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import Button from "../../components/Button";
import ProductGallery from "../../components/ProductGallery";
import Details from "./Details";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "../../contexts/modalContext";
import DeleteProduct from "./DeleteProduct";
import { useVariantById } from "../../services/productServices";
import Info from "./Info";
import useProduct from "../../hooks/useProduct";

const Product = () => {
  const { variantId } = useParams();
  const { data: variant, isLoading, error } = useVariantById(variantId);
  const { removeVariant } = useProduct();

  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const handleDelete = (variantId) => {
    removeVariant(variantId).then(() => {
      closeModal();
      navigate(-1);
    });
  };

  if (isLoading && (!error || !variant || !variant?.productGroup)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <div className="HEADER flex justify-between">
        <h1 className="text-3xl font-medium">Product Details</h1>

        <div className="BTN-SECTION flex items-center gap-4">
          <Button
            reverse
            onClick={() =>
              openModal(
                <DeleteProduct onDelete={() => handleDelete(variantId)} />
              )
            }
            className="flex items-center gap-2 px-4 py-2 rounded-md"
          >
            <FaRegTrashAlt />
            <p>delete</p>
          </Button>
          <Button
            onClick={() =>
              (window.location.href = `/product/edit/${variant.productGroup._id}`)
            }
            className="flex items-center gap-2 px-4 py-2 rounded-md"
          >
            <FaRegEdit />
            <p>edit product</p>
          </Button>
        </div>
      </div>

      <div className="PRODUCT-DETAILS bg-white p-16 rounded-xl shadow-pop">
        <section className="PRODUCT-HEAD flex justify-between gap-32">
          <div className="RIGHT-SECTION size-max sticky lg:top-8 z-[5]">
            <ProductGallery
              slides={[
                ...variant?.images,
                ...variant?.productGroup?.otherImages,
                ...variant?.productGroup?.thumbnail,
              ]}
            />
          </div>
          <div className="LEFT-SECTION w-full">
            <Details product={variant} />
            <Info product={variant} />
          </div>
        </section>
      </div>
    </section>
  );
};

export default Product;
