import { useState, useEffect, useCallback } from "react";

export const useFetchWithCache = (fetchFunction) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const cacheKey = fetchFunction.name;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData) {
        setData(JSON.parse(cachedData));
      } else {
        const response = await fetchFunction();

        if (response?.status) {
          console.log(response.data);
          sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
          setData(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [fetchFunction]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refetch: fetchData };
};
