import ApprovalList from "./ApprovalList";
import DesignerList from "./DesignerList";

const Designers = () => {
  return (
    <section className="p-4 space-y-8">
      <DesignerList />
      <ApprovalList />
    </section>
  );
};

export default Designers;
