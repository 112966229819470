import { motion } from "framer-motion";
import { getColorForStatus, orderStatus } from "../../../utils/constants";

const OrderStatus = ({ currentStatus, onStatusChange, statusCount }) => {
  const totalOrders = Object.values(statusCount).reduce(
    (sum, count) => sum + count,
    0
  );

  return (
    <ul className="flex justify-between items-center px-6 py-4">
      {orderStatus.map((item) => (
        <li
          key={item}
          onClick={() => onStatusChange(item)}
          className="relative flex items-center gap-2 cursor-pointer"
        >
          <p className="text-sm font-medium capitalize">{item}</p>
          <span
            className={`text-xxs font-bold rounded-full px-2 pt-[3px] pb-[2px] leading-none ${getColorForStatus(
              item
            )}`}
          >
            {item === "All Orders"
              ? totalOrders
              : statusCount
              ? statusCount[item]
              : 0}
          </span>
          {(item === currentStatus ||
            (item === "All Orders" && !currentStatus)) && (
            <motion.span
              layoutId="status-underline"
              className="block h-1 rounded-full bg-mainBlue absolute -bottom-2 right-4 left-1"
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default OrderStatus;
