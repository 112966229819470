import { PiWarningBold } from "react-icons/pi";
import ToggleSwitch from "../../../components/ToggleSwitch";

const Maintenance = () => {
  return (
    <div className="MAINTENANCE bg-white rounded-xl shadow-pop p-4 space-y-4">
      <div className="text-sm text-primary flex items-center gap-2">
        <PiWarningBold />
        <p className="leading-none">
          By turning on maintenance mode all your app and customer side website
          will be off. Only admin panel and seller panel will be functional.
        </p>
      </div>
      <ToggleSwitch
        label="maintenance mode"
        containerClassName="border border-secondary px-6 py-3 rounded-md justify-between"
      />
    </div>
  );
};

export default Maintenance;
