import { useState, useCallback } from "react";
import {
  addProductFeaturedDeal,
  addProductFlashDeal,
  createDealOfTheDay,
  createFeaturedDeal,
  createFlashDeal,
  deleteDealOfTheDay,
  deleteFeaturedDeal,
  deleteFlashDeal,
  deleteProductFeaturedDeal,
  deleteProductFlashDeal,
  updateDealOfTheDay,
  updateFeaturedDeal,
  updateFlashDeal,
  updateStatusDealOfTheDay,
  updateStatusFeaturedDeal,
  updateStatusFlashDeal,
} from "../api/dealApis";

const useDeal = (dealName = "flash") => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addDeal = useCallback(
    async (dealData) => {
      setLoading(true);
      setError(null);
      try {
        if (dealName === "flash") {
          const response = await createFlashDeal(dealData);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
          }
        } else if (dealName === "featured") {
          const response = await createFeaturedDeal(dealData);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
          }
        } else if (dealName === "dealOfTheDay") {
          const response = await createDealOfTheDay(dealData);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
          }
        }
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [dealName]
  );

  const editDeal = useCallback(
    async (dealId, dealData) => {
      setLoading(true);
      setError(null);
      try {
        if (dealName === "flash") {
          const response = await updateFlashDeal(dealId, dealData);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "featured") {
          const response = await updateFeaturedDeal(dealId, dealData);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "dealOfTheDay") {
          const response = await updateDealOfTheDay(dealId, dealData);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        }
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [dealName]
  );

  const removeDeal = useCallback(
    async (dealId) => {
      setLoading(true);
      setError(null);
      try {
        if (dealName === "flash") {
          const response = await deleteFlashDeal(dealId);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "featured") {
          const response = await deleteFeaturedDeal(dealId);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "dealOfTheDay") {
          const response = await deleteDealOfTheDay(dealId);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        }
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [dealName]
  );

  const addProductOnDeal = useCallback(
    async (dealId, products) => {
      setLoading(true);
      setError(null);
      try {
        if (dealName === "flash") {
          const response = await addProductFlashDeal(dealId, products);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "featured") {
          const response = await addProductFeaturedDeal(dealId, products);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        }
      } catch (error) {
        console.error("Error adding products", error);
        setError("Failed to add products. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [dealName]
  );

  const removeProductOnDeal = useCallback(
    async (dealId, productId) => {
      setLoading(true);
      setError(null);
      try {
        if (dealName === "flash") {
          const response = await deleteProductFlashDeal(dealId, productId);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "featured") {
          const response = await deleteProductFeaturedDeal(dealId, productId);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        }
      } catch (error) {
        console.error("Error adding products", error);
        setError("Failed to add products. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [dealName]
  );

  const updateStatus = useCallback(
    async (dealId, status) => {
      setLoading(true);
      setError(null);
      try {
        if (dealName === "flash") {
          const response = await updateStatusFlashDeal(dealId, status);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "featured") {
          const response = await updateStatusFeaturedDeal(dealId, status);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        } else if (dealName === "dealOfTheDay") {
          const response = await updateStatusDealOfTheDay(dealId, status);

          if (response?.status) {
            console.log(response.data);
            sessionStorage.removeItem(`${dealName}_deals`);
            sessionStorage.removeItem(`${dealName}_deal_${dealId}`);
          }
        }
      } catch (error) {
        console.error("Error updating status", error);
        setError("Failed to update status. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [dealName]
  );

  return {
    addDeal,
    editDeal,
    addProductOnDeal,
    removeProductOnDeal,
    updateStatus,
    removeDeal,
    loading,
    error,
  };
};

export default useDeal;
