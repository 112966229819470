import Button from "../../../components/Button";
import RadioInput from "../../../components/RadioInput";

const ProductBrand = () => {
  return (
    <div className="APPLE-STORE-STATUS bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 px-6 py-4 border-b-2 border-secondary">
        <p className="font-medium">Product Brand</p>
      </div>
      <div className="px-6 py-4 space-y-2">
        <p className="text-sm font-medium pb-2">Product Brand on/off</p>
        <RadioInput label="turn on" name="apple_store_status" />
        <RadioInput label="turn off" name="apple_store_status" />
        <div className="w-max ml-auto pt-4">
          <Button>save</Button>
        </div>
      </div>
    </div>
  );
};

export default ProductBrand;
