import { useCallback, useState } from "react";
import { approveDesignerById } from "../api/utsavApis";

const useDesigner = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const approveDesigner = useCallback(async (userId, approval) => {
    setLoading(true);
    setError(null);
    try {
      const response = await approveDesignerById(userId, approval);

      if (response?.status) {
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    approveDesigner,
    loading,
    error,
  };
};

export default useDesigner;
