import { LuCalendarRange } from "react-icons/lu";
import PurchaseList from "./PurchaseList";
import Details from "./Details";
import CustomerForm from "./CustomerForm";
import {
  useCustomerById,
  useOrdersOfCustomers,
} from "../../services/customerServices";
import { useParams } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";

const Customer = () => {
  const { userId } = useParams();
  const { data: customer, isLoading, error } = useCustomerById(userId);
  const { data: customerOrders, isLoading: ordersLoading } =
    useOrdersOfCustomers(userId);

  if (isLoading || !customer) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Customer Details</h1>
      <div className="flex items-center gap-8">
        <h3 className="text-lg font-medium">Customer ID #{customer._id}</h3>
        <div className="flex items-center gap-2 text-sm text-secondary">
          <LuCalendarRange />
          <p>Joined At: {formatDate(new Date(customer.createdAt))}</p>
        </div>
      </div>
      <div className="FLEX-CONTAINER flex gap-8">
        <div className="w-[70%]">
          {ordersLoading ? <></> : <PurchaseList orders={customerOrders} />}
        </div>
        <div className="w-[30%] space-y-8">
          <Details user={customer} numOfOrders={customerOrders.length} />
          <CustomerForm user={customer} />
        </div>
      </div>
    </section>
  );
};

export default Customer;
