import { useParams } from "react-router-dom";
import Address from "./Address";
import Details from "./Details";
import Referrals from "./Referrals";
import ReferredBy from "./ReferredBy";
import Transactions from "./Transactions";
import { useMemberById } from "../../../services/utsavServices";

const Member = () => {
  const { userId } = useParams();
  const { data, isLoading, error } = useMemberById(userId);

  if (isLoading || !data) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-8 space-y-8">
      <div className="flex justify-between">
        <Details user={data?.userData} />
        <Address address={data?.address} />
        <ReferredBy user={data?.referralDetails?.referred_by} />
      </div>
      <Transactions transactions={data?.walletTransactionDetails} />
      <Referrals referrals={data?.referralDetails?.referred_user} />
    </section>
  );
};

export default Member;
