import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useModal } from "../../contexts/modalContext";
import { useNavigate } from "react-router-dom";
import DeleteBrand from "./DeleteBrand";
import { useMemo, useState } from "react";
import { useToast } from "../../contexts/toastContext";
import useForm from "../../hooks/useForm";
import {
  useBrands,
  useDeleteBrand,
  useFeatureBrand,
  useUtsavFeatureBrand,
} from "../../services/brandServices";

const BrandList = () => {
  const { data: brands, isLoading, error } = useBrands();
  const deleteMutation = useDeleteBrand();
  const featuredMutation = useFeatureBrand();
  const utsavFeaturedMutation = useUtsavFeatureBrand();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { formData, setFormData } = useForm({});
  const toast = useToast();
  const limit = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return brands.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, brands]);

  const handleDelete = (brandId) => {
    deleteMutation.mutate(brandId, {
      onSuccess: () => {
        toast.success("Brand deleted successfully");
        closeModal();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete brand");
      },
    });
  };

  const handleIsFeatured = (brandId, e) => {
    const isFeatured = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [brandId]: { ...prevData[brandId], is_featured: isFeatured },
    }));

    featuredMutation.mutate(
      { brandId, isFeatured },
      {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  const handleIsUtsavFeatured = (brandId, e) => {
    const utsavFeatured = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [brandId]: { ...prevData[brandId], utsavFeatured: utsavFeatured },
    }));
    utsavFeaturedMutation.mutate(
      { brandId, isFeatured: utsavFeatured },
      {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Brands
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return currentTableData.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Brands Found
      </h2>
    </div>
  ) : (
    <div className="w-full min-h-[720px] flex flex-col justify-between bg-white rounded-xl shadow-pop overflow-hidden">
      <table className="w-full">
        <thead className="bg-lightGray border-b border-primary">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium">
              SL
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Brand Logo
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Name
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Total Product
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Total Order
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Featured
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Utsav Featured
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTableData.map((brand, index) => (
            <tr key={index}>
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="px-6 py-3">
                <div className="size-16 mx-auto">
                  <img
                    src={brand.logoUrl}
                    alt={brand.name}
                    className="size-full object-center object-cover"
                  />
                </div>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">{brand.name}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">65</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">0</p>
              </td>
              <td className="px-4 py-3">
                <ToggleSwitch
                  name="is_featured"
                  checked={
                    formData[brand._id]?.is_featured || brand?.is_featured
                  }
                  onChange={(e) => handleIsFeatured(brand._id, e)}
                  className="mx-auto w-10 h-5"
                />
              </td>
              <td className="px-4 py-3">
                <ToggleSwitch
                  name="utsavFeatured"
                  checked={
                    formData[brand._id]?.utsavFeatured || brand?.utsavFeatured
                  }
                  onChange={(e) => handleIsUtsavFeatured(brand._id, e)}
                  className="mx-auto w-10 h-5"
                />
              </td>
              <td className="px-4 py-3">
                <div className="flex justify-center items-center gap-4">
                  <button onClick={() => navigate(`/brand/edit/${brand._id}`)}>
                    <FaRegEdit className="text-success" />
                  </button>
                  <button
                    onClick={() =>
                      openModal(
                        <DeleteBrand onDelete={() => handleDelete(brand._id)} />
                      )
                    }
                  >
                    <FaRegTrashAlt className="text-error" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalCount={brands.length}
        pageSize={limit}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default BrandList;
