import { useNavigate } from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import { FaRegEye, FaRegFilePdf } from "react-icons/fa";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { useMemo } from "react";
import Pagination from "../../components/Pagination";

const PurchaseList = ({ orders }) => {
  const navigate = useNavigate();
  const [page, setPage] = useSearchParamsState("page", 1, true);
  const limit = 5;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (parseInt(page, 10) - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    return orders.slice(firstPageIndex, lastPageIndex);
  }, [page, orders, limit]);

  return orders.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Orders Made Yet
      </h2>
    </div>
  ) : (
    <div className="ORDERS bg-white rounded-xl shadow-pop border border-primary flex flex-col justify-between">
      <div className="px-6 py-4 flex justify-between items-center border-b border-primary">
        <p className="text-lg font-medium">Purchase History</p>
        <SearchBox placeHolder="search by product" />
      </div>
      <table className="w-full border-b border-secondary">
        <thead>
          <tr className="bg-lightGray font-secondary">
            <th scope="col" className="px-6 py-4 text-left font-medium">
              SL.
            </th>
            <th scope="col" className="px-6 py-4 text-left font-medium">
              Product
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              QTY
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Subtotal
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Discount
            </th>
            <th scope="col" className="px-6 py-4 font-medium">
              Total
            </th>
            <th scope="col" className="px-6 py-4 text-left font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-secondary">
          {currentTableData.map((order, index) => (
            <tr key={index}>
              <td className="px-6">{index + 1}</td>
              <td className="px-6 py-3 space-y-1">
                {order.orderItem?.slice(0, 1)?.map((item, index) => (
                  <div
                    key={index}
                    className="PRODUCT-MAIN flex gap-4 cursor-pointer"
                    onClick={() => navigate(`/order/${order._id}`)}
                  >
                    {item.productId ? (
                      <>
                        <div className="size-12 flex-shrink-0">
                          <img
                            src={item.productId?.images[0]}
                            alt={index}
                            className="size-full object-center object-contain"
                          />
                        </div>
                        <div className="text-xs">
                          <h3 className="font-medium">
                            {item.productId?.name}
                          </h3>
                          {item?.productId?.attributes &&
                            Object.keys(item?.productId?.attributes)
                              .slice(0, 3)
                              .map((key) => (
                                <p key={key} className="capitalize">
                                  {key}: {item?.productId?.attributes[key]}
                                </p>
                              ))}
                        </div>
                      </>
                    ) : (
                      <div className="size-12 bg-lightGray flex items-center justify-center text-primary rounded text-xxs text-center">
                        Invalid Product
                      </div>
                    )}
                  </div>
                ))}
                {order?.orderItem.length > 1 && (
                  <p className="text-xs font-medium">
                    +{order.orderItem.length - 1} more
                  </p>
                )}
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">{order.orderItem.length}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">₹ {order.subtotal}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">₹ {order.discount}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <p className="text-sm">₹ {order.totalPrice}</p>
              </td>
              <td className="px-4 py-3 text-center">
                <div className="flex justify-center items-center gap-4">
                  <button onClick={() => navigate(`/order/${order._id}`)}>
                    <FaRegEye className="text-info" />
                  </button>
                  <button>
                    <FaRegFilePdf className="text-mainBlue" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={parseInt(page, 10)}
        totalCount={orders.length}
        pageSize={limit}
        onPageChange={(page) => setPage(page.toString())}
      />
    </div>
  );
};

export default PurchaseList;
