import SearchBox from "../../components/SearchBox";
import ToggleSwitch from "../../components/ToggleSwitch";
import DeleteFeaturedDeal from "./DeleteFeaturedDeal";
import useDeal from "../../hooks/useDeal";
import Button from "../../components/Button";
import { FiPlus } from "react-icons/fi";
import { useModal } from "../../contexts/modalContext";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { formatDate } from "../../utils/dateUtils";
import useForm from "../../hooks/useForm";
import { useEffect } from "react";
import { useToast } from "../../contexts/toastContext";
import { useFeaturedDeals } from "../../services/dealServices";

const FeaturedDealList = () => {
  const { data: deals, isLoading, error } = useFeaturedDeals();
  const { removeDeal, updateStatus } = useDeal("featured");
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { formData, setFormData } = useForm({});
  const toast = useToast();

  useEffect(() => {
    const initialFormData = deals.reduce((acc, deal) => {
      acc[deal._id] = { status: deal.status };
      return acc;
    }, {});
    setFormData(initialFormData);
  }, [deals, setFormData]);

  const handleDelete = (dealId) => {
    removeDeal(dealId).then(() => {
      toast.error("Deal deleted successfully");
      closeModal();
    });
  };

  const handleStatusChange = (dealId, e) => {
    const newStatus = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,
      [dealId]: { ...prevData[dealId], status: newStatus },
    }));
    updateStatus(dealId, newStatus);
  };

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Featured Deals
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return deals.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Featured deals Found
      </h2>
    </div>
  ) : (
    <div className="bg-white rounded-xl shadow-pop overflow-hidden">
      <div className="p-4 flex justify-between items-center">
        <div className="HEADING flex items-center gap-4">
          <h3 className="text-xl font-medium">Featured Deal List</h3>
          <span className="bg-mainGray size-5 rounded-full flex justify-center items-center text-xs font-medium">
            {deals.length}
          </span>
        </div>
        <SearchBox placeHolder="Search by deal name" />
      </div>
      <div className="w-full flex flex-col justify-between">
        <table className="w-full">
          <thead className="bg-lightGray">
            <tr>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                SL
              </th>
              <th scope="col" className="px-6 py-4 text-left font-medium">
                Title
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Start Date
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                End Date
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Active Products
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Active
              </th>
              <th scope="col" className="px-6 py-4 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {deals.map((deal, index) => (
              <tr key={index}>
                <td className="px-6 py-3">{index + 1}</td>
                <td className="px-6 py-3 text-sm">
                  <p className="text-sm">{deal.title}</p>
                </td>
                <td className="px-6 py-3 text-center">
                  <p className="text-sm">
                    {formatDate(new Date(deal.start_Date))}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">
                    {formatDate(new Date(deal.end_Date))}
                  </p>
                </td>
                <td className="px-4 py-3 text-center">
                  <p className="text-sm">{deal.products.length}</p>
                </td>
                <td className="px-4 py-3 text-center">
                  <ToggleSwitch
                    name="status"
                    checked={formData[deal._id]?.status || deal.status}
                    onChange={(e) => handleStatusChange(deal._id, e)}
                    className="mx-auto w-10 h-5"
                  />
                </td>
                <td className="px-4 py-3">
                  <div className="flex justify-center items-center gap-4">
                    <Button
                      reverse
                      onClick={() => navigate(`add-product/${deal._id}`)}
                      className="rounded-md px-2 py-1 font-medium flex items-center gap-2"
                    >
                      <FiPlus />
                      <p className="text-xs">add products</p>
                    </Button>
                    <button onClick={() => navigate(`edit/${deal._id}`)}>
                      <FaRegEdit className="text-success" />
                    </button>
                    <button
                      onClick={() =>
                        openModal(
                          <DeleteFeaturedDeal
                            onDelete={() => handleDelete(deal._id)}
                          />
                        )
                      }
                    >
                      <FaRegTrashAlt className="text-error" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FeaturedDealList;
