import { BsImageAlt } from "react-icons/bs";
import Button from "../../components/Button";
import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import useForm from "../../hooks/useForm";
import { useEffect, useState } from "react";
import TextareaBox from "../../components/TextareaBox";
import {
  useCreateFashionCategory,
  useFashionCategoryById,
  useUpdateFashionCategory,
} from "../../services/fashionServices";
import { useToast } from "../../contexts/toastContext";
import { useNavigate, useParams } from "react-router-dom";

const FashionForm = ({ edit = false }) => {
  const { fashionCategoryId } = useParams();
  const {
    data: fashionCategory,
    isLoading,
    error,
  } = useFashionCategoryById(fashionCategoryId);
  const createMutation = useCreateFashionCategory();
  const updateMutation = useUpdateFashionCategory(fashionCategoryId);
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitHandler = (fashionData) => {
    const { name, description, logoImg } = fashionData;
    const payload = { name, description, logoImg: logoImg ? logoImg[0] : "" };
    console.log(payload);

    if (edit) {
      updateMutation.mutate(
        { fashionCategoryId, fashionData: payload },
        {
          onSuccess: () => {
            toast.success("Fashion category updated successfully");
            navigate(-1);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Failed to update fashion category");
          },
        }
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Fashion category created successfully");
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to create fashion category");
        },
      });
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && fashionCategory) {
      setFormData({
        name: fashionCategory.name,
        description: fashionCategory.description,
        logoImg: null,
      });
      if (fashionCategory.logoUrl) {
        setSelectedImage(fashionCategory.logoUrl);
      }
    }
  }, [edit, fashionCategory, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  if (isLoading || (edit && !fashionCategory)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className={`space-y-4 ${edit && "p-4"}`}>
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Fashion Category" : "Add Fashion Category"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[45%] space-y-4">
          <InputBox
            label="title"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <FileInput
            label="upload image (ratio 1:1)"
            name="logoImg"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2"
          />
          <TextareaBox
            label="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="w-[50%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div className="size-60 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="category-logo"
                  className="size-full object-cover object-center"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>

            <p className="text-sm font-medium">Dimensions: 400 x 400 pixels</p>
          </div>

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              Reset
            </Button>
            <Button
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              submit
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default FashionForm;
