import { TbProgressCheck } from "react-icons/tb";
import Tab from "../../../components/Tab";
import { RiTruckLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { LuPackageCheck } from "react-icons/lu";

const Overview = () => {
  return (
    <div className="flex justify-between gap-4">
      <Tab
        title="in progress"
        icon={<TbProgressCheck />}
        body="Rs 00000"
        growth={12}
        accent="#fcc92d"
      />
      <Tab
        title="shipped"
        icon={<RiTruckLine />}
        body="Rs 00000"
        growth={-12}
        accent="#69b6f3"
      />
      <Tab
        title="delivered"
        icon={<LuPackageCheck />}
        body="00000"
        growth={0}
        accent="#91c936"
      />
      <Tab
        title="cancelled"
        icon={<IoMdClose />}
        body="00000"
        growth={5}
        accent="#c6373d"
      />
    </div>
  );
};

export default Overview;
