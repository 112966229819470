import {
  MdInfoOutline,
  MdMailOutline,
  MdOutlinePhone,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { SlUser } from "react-icons/sl";
import SelectBox from "../../components/SelectBox";
import { serverOrderStatus } from "../../utils/constants";
import useOrder from "../../hooks/useOrder";
import useForm from "../../hooks/useForm";
import { useAdmin } from "../../contexts/adminContext";

const GeneralInfo = ({ order }) => {
  const { updateStatus } = useOrder();
  const { formData, handleChange } = useForm({});
  const admin= useAdmin()
  const handleStatusChange = (e) => {
    handleChange(e);
    updateStatus(order._id, e.target.value);
  };

  return (
    <div className="border border-darkGray rounded-xl bg-white shadow-pop">
      <div className="HEAD flex items-center gap-2 px-4 py-2 border-b border-primary">
        <span className="bg-rose p-1 rounded-full text-primeRed">
          <MdInfoOutline />
        </span>
        <h2 className="font-medium">General Info</h2>
      </div>

      <div className="BODY">
        <div className="flex items-start gap-4 px-4 py-2">
          <MdOutlineShoppingCart className="mt-1" />
          <div>
            <p className="font-medium">Order Status</p>
            <SelectBox
              name="orderStatus"
              value={formData.orderStatus || order.status}
              options={serverOrderStatus.map((value) => ({
                label: value,
                value,
              }))}
              disabled={admin?.admin?.userLevel >= 1}
              onChange={handleStatusChange}
              className="w-44 border border-darkGray rounded px-3 py-1 text-sm"
            />
          </div>
        </div>

        <div className="flex items-start gap-4 px-4 py-2">
          <SlUser className="mt-1" />
          <div>
            <p className="font-medium">Customer</p>
            <p className="text-sm">{order.address?.receiverName}</p>
          </div>
        </div>

        <div className="flex items-start gap-4 px-4 py-2">
          <MdMailOutline className="mt-1" />
          <div>
            <p className="font-medium">Email ID</p>
            <p className="text-sm">{order.userId?.email}</p>
          </div>
        </div>

        <div className="flex items-start gap-4 px-4 py-2">
          <MdOutlinePhone className="mt-1" />
          <div>
            <p className="font-medium">Mobile No</p>
            <p className="text-sm">{order.address?.receiverPhone}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
