import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { eraseCookie, getCookie, setCookie } from "../utils/cookie";
import axiosInstance from "../api/axiosInstance";
import {
  loginAdmin,
  logOutAdmin,
  refreshTokenAPI,
  verifyToken,
} from "../api/adminApis";
import { useToast } from "./toastContext";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => ({
    accessToken: getCookie("accessToken"),
    refreshToken: getCookie("refreshToken"),
  }));
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const login = useCallback(
    async (loginData) => {
      try {
        const response = await loginAdmin(loginData);
        if (response?.status) {
          const { accessToken, refreshToken } = response.data;
          setAuth({ accessToken, refreshToken });
          setCookie("accessToken", accessToken, 7);
          setCookie("refreshToken", refreshToken, 7);
          axiosInstance.defaults.headers.common[
            "authorization"
          ] = `Bearer ${accessToken}`;
          navigate("/", { replace: true });
        }
      } catch (error) {
        console.error("Error during login:", error);
        if (!error?.response) {
          toast.error("no server response");
        } else if (error.response) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error("Server issues!!!");
        }
      }
    },
    // eslint-disable-next-line
    [navigate]
  );

  const logout = useCallback(async () => {
    try {
      await logOutAdmin();
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setAuth({ accessToken: null, refreshToken: null });
      eraseCookie("accessToken");
      eraseCookie("refreshToken");
      delete axiosInstance.defaults.headers.common["authorization"];
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const refreshAuthToken = useCallback(async () => {
    try {
      const response = await refreshTokenAPI(auth.refreshToken);
      if (response?.status) {
        const newAccessToken = response.data.accessToken;
        setAuth((prev) => ({ ...prev, accessToken: newAccessToken }));
        setCookie("accessToken", newAccessToken, 7);
        axiosInstance.defaults.headers.common[
          "authorization"
        ] = `Bearer ${newAccessToken}`;
      }
    } catch (error) {
      console.error("Token refresh error:", error);
      logout();
    }
    return null;
  }, [auth.refreshToken, logout]);

  useEffect(() => {
    const initializeAuth = async () => {
      if (auth.accessToken) {
        axiosInstance.defaults.headers.common[
          "authorization"
        ] = `Bearer ${auth.accessToken}`;

        try {
          await verifyToken(auth.accessToken);
          console.log("TOKEN IS VALID");
        } catch (error) {
          if (error.response?.status === 401) {
            console.log("REFRESHING TOKEN");
            await refreshAuthToken();
          } else {
            console.error("Authentication initialization error:", error);
            logout();
          }
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, [auth.accessToken, refreshAuthToken, logout]);

  return (
    <AuthContext.Provider value={{ auth, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
