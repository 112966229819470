import authBg from "../../assets/images/auth-bg.png";
import Login from "./Login";

const Auth = () => {
  return (
    <main className="flex h-screen w-full">
      <section
        className="w-2/5 h-full bg-center bg-cover"
        style={{ backgroundImage: `url(${authBg})` }}
      ></section>
      <section className="w-3/5 h-full flex justify-center items-center">
        <Login />
      </section>
    </main>
  );
};

export default Auth;
