const RadioInput = ({
  value = "",
  onChange,
  name,
  label,
  defaultChecked = false,
  disabled = false,
  align = "right",
  required = false,
  className,
  containerClassName,
}) => {
  const id = label?.replace(" ", "-").toLowerCase();

  return (
    <div className={`relative flex gap-2 items-start ${containerClassName}`}>
      {label && align === "left" && (
        <label htmlFor={id} className="capitalize text-sm">
          {label}
        </label>
      )}
      <div className={`grid place-items-center mt-[2px] ${className}`}>
        <input
          id={id}
          type="radio"
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={onChange}
          required={required}
          className="peer col-start-1 row-start-1 appearance-none shrink-0 size-4 border border-black rounded-full disabled:border-primary hover:cursor-pointer"
        />
        <span className="pointer-events-none col-start-1 row-start-1 size-2 rounded-full peer-checked:bg-primeRed peer-checked:peer-disabled:bg-mainGray" />
      </div>
      {label && align === "right" && (
        <label htmlFor={id} className="capitalize text-sm">
          {label}
        </label>
      )}
    </div>
  );
};

export default RadioInput;
