import axiosInstance from "./axiosInstance";

const getAllFashionCategories = async () => {
  const response = await axiosInstance.get("/getAllFashionCategory");
  return response.data?.fashionCategoryList;
};

const getFashionCategoryById = async (fashionCategoryId) => {
  const response = await axiosInstance.get(
    `/getFashionCategoryById/${fashionCategoryId}`
  );
  return response.data?.fashionCategoryDetails;
};

const createFashionCategory = async (fashionData) => {
  const response = await axiosInstance.post(
    "/createFashionCategory",
    fashionData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response.data;
};

const editFashionCategory = async (fashionCategoryId, fashionData) => {
  console.log(fashionData);
  const response = await axiosInstance.post(
    `/editFashionCategory/${fashionCategoryId}`,
    fashionData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response.data;
};

const deleteFashionCategory = async (fashionCategoryId) => {
  const response = await axiosInstance.get(
    `/deleteFashionCategory/${fashionCategoryId}`
  );
  return response.data;
};

const getAllFashionBlogs = async () => {
  const response = await axiosInstance.get("/getAllFashionBlog");
  return response.data?.fashionCategoryDetails;
};

const getFashionBlogById = async (fashionBlogId) => {
  const response = await axiosInstance.get(
    `/getFashionBlogById/${fashionBlogId}`
  );
  return response.data?.fashionCategoryDetails;
};

const createFashionBlog = async (blogData) => {
  const response = await axiosInstance.post("/createBlog", blogData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

const editFashionBlog = async (fashionBlogId, blogData) => {
  const response = await axiosInstance.post(
    `/editFashionBlog/${fashionBlogId}`,
    blogData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response.data;
};

const deleteFashionBlog = async (fashionBlogId) => {
  const response = await axiosInstance.get(
    `/deleteFashionBlog/${fashionBlogId}`
  );
  return response.data;
};

export {
  getAllFashionCategories,
  getFashionCategoryById,
  createFashionCategory,
  editFashionCategory,
  deleteFashionCategory,
  getAllFashionBlogs,
  getFashionBlogById,
  createFashionBlog,
  editFashionBlog,
  deleteFashionBlog,
};
