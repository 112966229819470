import { IoTrashOutline } from "react-icons/io5";
import Button from "../../components/Button";
import { useModal } from "../../contexts/modalContext";

const DeleteCustomer = ({ onDelete }) => {
  const { closeModal } = useModal();

  return (
    <div className="bg-white rounded-xl p-8 space-y-12">
      <div className="HEAD space-y-2">
        <span className="block w-max mx-auto bg-rose p-4 rounded-full text-error">
          <IoTrashOutline size={32} />
        </span>
        <p className="text-sm font-medium text-center">Delete Customer</p>
        <p className="text-sm font-medium text-primary text-center">
          Do you want to delete this customer?
        </p>
      </div>

      <div className="BTN-SECTION flex justify-around">
        <Button onClick={onDelete}>delete</Button>
        <Button reverse onClick={() => closeModal()}>
          cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteCustomer;
