import { useEffect, useState } from "react";
import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import TextareaBox from "../../components/TextareaBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import SelectBox from "../../components/SelectBox";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCategories,
  useCreateSubcategory,
  useSubcategoryById,
  useUpdateSubcategory,
} from "../../services/categoryServices";
import { useToast } from "../../contexts/toastContext";
import { BsImageAlt } from "react-icons/bs";

const SubcategoryForm = ({ edit = false }) => {
  const { subcategoryId } = useParams();
  const {
    data: subcategory,
    isLoading,
    error,
  } = useSubcategoryById(subcategoryId);
  const createMutation = useCreateSubcategory();
  const updateMutation = useUpdateSubcategory(subcategoryId);
  const { data: categories, isLoading: categoryLoading } = useCategories();
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitHandler = (subcategoryData) => {
    const { name, description, mainCategoryId, logo } = subcategoryData;
    const payload = {
      name,
      description,
      mainCategoryId,
      logo: logo ? logo[0] : subcategory?.logoUrl,
    };

    if (edit) {
      updateMutation.mutate(
        { subcategoryId, subcategoryData: payload },
        {
          onSuccess: () => {
            toast.success("Subcategory updated successfully");
            navigate(-1);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Failed to update subcategory");
          },
        }
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Subcategory created successfully");
          navigate(-1);
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to create subcategory");
        },
      });
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && subcategory) {
      setFormData({
        name: subcategory.name,
        description: subcategory.description,
        mainCategoryId: subcategory.mainCategoryId,
        logo: null,
      });
      if (subcategory.logoUrl) {
        setSelectedImage(subcategory.logoUrl);
      }
    }
  }, [edit, subcategory, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  if (isLoading || (edit && !subcategory)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Sub-Category" : "Add Sub-Category"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[65%] space-y-4">
          <InputBox
            label="subcategory name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <SelectBox
            label="category"
            name="mainCategoryId"
            value={formData.mainCategoryId}
            onChange={handleChange}
            options={categories.map((category) => ({
              label: category.name,
              value: category._id,
            }))}
            disabled={categoryLoading}
          />
          <FileInput
            label="subcategory logo ratio (1:1)"
            name="logo"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2"
          />
          <TextareaBox
            label="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="w-[30%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div className="size-60 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="subcategory-logo"
                  className="size-full object-cover object-center"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>

            <p className="text-sm font-medium">Dimensions: 900 x 900 pixels</p>
          </div>

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              Reset
            </Button>
            <Button
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              submit
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default SubcategoryForm;
