import axiosInstance from "./axiosInstance";

const getProduct = async (productId) => {
  const response = await axiosInstance.get(`/getProductById/${productId}`);
  return response.data;
};

const createProduct = (productData) => {
  return axiosInstance.post("/createProduct", productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateProduct = (productId, productData) => {
  return axiosInstance.post(`/updateProduct/${productId}`, productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getVariants = async (params) => {
  try {
    const response = await axiosInstance.get("/getAllVariants", { params });

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`);
    }

    const { variants = [], page = 1, limit = 10, total = 0 } = response.data || {};
    return { variants, page, limit, total }; // Return structured response
  } catch (error) {
    console.error("Error fetching customers:", error.message || error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch customers"
    );
  }
};
const getSearchVariants = async ({data}) => {
  try {
    const response = await axiosInstance.get("/getSearchData?query="+data);

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`);
    }

    const { variants = [], page = 1, limit = 10, total = 0 } = response.data || {};
    return { variants, page, limit, total }; // Return structured response
  } catch (error) {
    console.error("Error fetching customers:", error.message || error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch customers"
    );
  }
};
const getVariant = async (variantId) => {
  const response = await axiosInstance.get(`/getVariantById/${variantId}`);
  return response.data?.variantDetails;
};

const createVariant = (variantData) => {
  return axiosInstance.post("/createVariant", variantData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateVariant = (variantId, variantData) => {
  return axiosInstance.post(`/updateVariant/${variantId}`, variantData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteVariant = (variantId) => {
  return axiosInstance.get(`/deleteVariant/${variantId}`);
};

const featureProduct = (variantId, featured) => {
  return axiosInstance.post(`/featuredProduct/${variantId}`, { featured });
};

const makeAsNewArrival = (variantId, isNewArrival) => {
  return axiosInstance.post(`/markAsaNewArrivals/${variantId}`, {
    arrival: isNewArrival,
  });
};

const activeProduct = (variantId, isActive) => {
  return axiosInstance.post(`/activeProduct/${variantId}`, {
    activeStatus: isActive,
  });
};

export {
  getProduct,
  createProduct,
  updateProduct,
  getVariants,
  getVariant,
  createVariant,
  updateVariant,
  deleteVariant,
  featureProduct,
  makeAsNewArrival,
  activeProduct,
  getSearchVariants
};
