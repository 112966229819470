import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { DOTS, usePagination } from "../hooks/usePagination";
import { Fragment } from "react";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    limit: pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage >= lastPage) return;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage <= 1) return;
    onPageChange(currentPage - 1);
  };

  return (
    <div className="py-5 text-center">
      <div className="inline-flex gap-1 rounded-full border border-stroke p-3 dark:border-black/10">
        <ul className="flex items-center gap-1">
          {/* Previous Button */}
          <li>
            <button
              className="flex h-10 min-w-10 items-center justify-center rounded-full px-2 text-dark hover:bg-gray-2 dark:text-black/4 dark:hover:bg-black/5"
              onClick={onPrevious}
            >
              <FiChevronLeft size={20} />
            </button>
          </li>

          {/* Pagination Items */}
          {paginationRange.map((pageNumber, index) => (
            <Fragment key={index}>
              {pageNumber === DOTS && (
                <li className="relative h-max top-1">{DOTS}</li>
              )}

              {pageNumber !== DOTS && (
                <li
                  className={`rounded-full ${
                    pageNumber === currentPage
                      ? "text-lime-50 bg-primeRed"
                      : "text-dark bg-white"
                  } flex justify-center items-center cursor-pointer px-4 py-2 border ${
                    pageNumber === currentPage
                      ? "border-primeRed"
                      : "border-black/3"
                  } ${
                    pageNumber === currentPage ?
                    "hover:bg-primeRed"
                    : "hover:bg-black/5"
                  
                  }  transition-colors duration-200 ease-in-out`}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </li>
              )}
            </Fragment>
          ))}

          {/* Next Button */}
          <li>
            <button
              className="flex h-10 min-w-10 items-center justify-center rounded-full px-2 text-dark hover:bg-gray-2 dark:text-white/4 dark:hover:bg-black/5"
              onClick={onNext}
            >
              <FiChevronRight size={20} />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
