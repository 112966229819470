import { useEffect } from "react";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import TextareaBox from "../../components/TextareaBox";
import useForm from "../../hooks/useForm";
import useMemberPlan from "../../hooks/useMemberPlan";
import { useToast } from "../../contexts/toastContext";
import { useMembershipPlan } from "../../services/utsavServices";

const MemberPlan = () => {
  const toast = useToast();
  const { data: plan, isLoading, error } = useMembershipPlan();
  const { editPlan, loading } = useMemberPlan();
  const onSubmitHandler = (planData) => {
    editPlan(planData).then(() => {
      toast.success("Plan updated successfully");
    });
  };

  const { formData, setFormData, handleChange, handleSubmit } = useForm(
    {},
    onSubmitHandler
  );

  useEffect(() => {
    if (plan)
      setFormData({
        amount: plan.amount,
        reward: plan.reward,
        status: plan.status,
        instruction: plan.instruction,
      });
  }, [plan, setFormData]);

  if (isLoading || !plan) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Membership Plan</h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 space-y-8"
      >
        <div className="TOP flex justify-between gap-8">
          <div className="w-1/3 space-y-3">
            <InputBox
              type="number"
              label="amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
            <InputBox
              type="number"
              label="reward"
              name="reward"
              value={formData.reward}
              onChange={handleChange}
            />
            <SelectBox
              label="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              options={["On", "Off"].map((value) => ({
                label: value,
                value,
              }))}
            />
          </div>
          <div className="w-2/3">
            <TextareaBox
              label="instruction"
              name="instruction"
              value={formData.instruction}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="BOTTOM flex justify-end items-center gap-8">
          <Button reverse disabled={loading}>
            reset
          </Button>
          <Button disabled={loading}>submit</Button>
        </div>
      </form>
    </section>
  );
};

export default MemberPlan;
