import { useQuery } from "@tanstack/react-query";
import {
  getAllGiftCards,
  getAllTemplates,
  getTemplateById,
} from "../api/giftCardApis";

const useGiftCards = () => {
  return useQuery({
    queryKey: ["giftCards"],
    queryFn: () => getAllGiftCards(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useTemplates = () => {
  return useQuery({
    queryKey: ["templates"],
    queryFn: () => getAllTemplates(),
    staleTime: 1000 * 60 * 5,
    placeholderData: [],
  });
};

const useTemplateById = (templateId) => {
  return useQuery({
    queryKey: ["template"],
    queryFn: () => getTemplateById(templateId),
    staleTime: 1000 * 60 * 5,
    placeholderData: null,
    enabled: !!templateId,
  });
};

export { useGiftCards, useTemplates, useTemplateById };
