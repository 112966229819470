import { MdFileDownload } from "react-icons/md";
import Button from "../../../components/Button";
import SearchBox from "../../../components/SearchBox";
import Overview from "./Overview";
import OrderList from "./OrderList";
import DateRangeSelector from "../../../components/DateRangeSelector";
import { useSearchParams } from "react-router-dom";

const UtsavOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDateChange = (newStartDate, newEndDate) => {
    const newSearchParams = { ...Object.fromEntries(searchParams.entries()) };
    newSearchParams.startDate = newStartDate;
    newSearchParams.endDate = newEndDate;
    newSearchParams.page = 1;
    setSearchParams(newSearchParams);
  };

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Utsav Order List</h1>

      <Overview />

      <div className="HEADING flex justify-between items-end">
        <DateRangeSelector onDateChange={handleDateChange} />
        <SearchBox name="search" placeHolder="search product name" />
        <Button
          reverse
          className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
        >
          <MdFileDownload className="mt-[3px]" />
          <p className="text-sm">export</p>
        </Button>
      </div>

      <OrderList />
    </section>
  );
};

export default UtsavOrders;
