import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import Sidebar from "../layouts/Sidebar";
import Dashboard from "../pages/Dashboard";
import Products from "../pages/Products";
import Product from "../pages/Products/Product";
import Orders from "../pages/Orders";
import Order from "../pages/Orders/Order";
import Categories from "../pages/Categories";
import CategoryForm from "../pages/Categories/CategoryForm";
import Subcategories from "../pages/Subcategories";
import SubcategoryForm from "../pages/Subcategories/SubcategoryForm";
import ProductTypes from "../pages/ProductTypes";
import ProductTypeForm from "../pages/ProductTypes/ProductTypeForm";
import Banners from "../pages/Banners";
import BannerForm from "../pages/Banners/BannerForm";
import Brands from "../pages/Brands";
import BrandForm from "../pages/Brands/BrandForm";
import Auth from "../pages/Auth";
import AddProduct from "../pages/Products/AddProduct";
import EditProduct from "../pages/Products/EditProduct";
import Coupons from "../pages/Coupons";
import CouponForm from "../pages/Coupons/CouponForm";
import GiftCards from "../pages/GiftCard";
import TemplateForm from "../pages/GiftCard/TemplateForm";
import FlashDeals from "../pages/FlashDeals";
import FlashDealForm from "../pages/FlashDeals/FlashDealForm";
import AddProductOnFlashDeal from "../pages/FlashDeals/AddProductOnFlashDeal";
import FeaturedDeals from "../pages/FeaturedDeals";
import FeaturedDealForm from "../pages/FeaturedDeals/FeaturedDealForm";
import AddProductOnFeaturedDeal from "../pages/FeaturedDeals/AddProductOnFeaturedDeal";
import DealOfTheDay from "../pages/DealOfTheDay";
import DealOfTheDayForm from "../pages/DealOfTheDay/DealOfTheDayForm";
import BusinessSetup from "../pages/BusinessSetup";
import MemberPlan from "../pages/Utsav/MemberPlan";
import MemberList from "../pages/Utsav/MemberList";
import Member from "../pages/Utsav/Member";
import WalletTransaction from "../pages/Utsav/WalletTransaction";
import UtsavOrders from "../pages/Utsav/UtsavOrders";
import Designers from "../pages/Utsav/Designers";
import BorrowList from "../pages/Utsav/BorrowList";
import Customers from "../pages/Customers";
import Customer from "../pages/Customers/Customer";
import Gallery from "../pages/Utsav/Gallery";
import GalleryForm from "../pages/Utsav/Gallery/GalleryForm";
import { useAdmin } from "../contexts/adminContext";
import Loader from "./Loader";
import Fashion from "../pages/Fashion";
import FashionForm from "../pages/Fashion/FashionForm";
import Blog from "../pages/Fashion/Blog";
import BlogForm from "../pages/Fashion/BlogForm";
import Approvals from "../pages/Fashion/Approvals";
import { useEffect, useState } from "react";

const AnimatedRoutes = () => {
  const location = useLocation();
  const { admin, isLoading } = useAdmin();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const requireAuth = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (!isLoading && !admin) {
      return <Navigate to="/auth" state={{ from: location }} replace />;
    }
    if (admin) {
      <Outlet />;
    }
  };

  useEffect(() => {
    // Collapse the Navbar on smaller devices
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    // Set initial state and attach event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notRequireAuth = () => {
    return isLoading ? (
      <Loader />
    ) : admin ? (
      <Navigate
        to={location.state?.from || "/"}
        state={{ from: location }}
        replace
      />
    ) : (
      <Outlet />
    );
  };

  return (
    <Routes location={location}>
      {/* Auth Route */}
      <Route element={notRequireAuth()}>
        <Route path="/auth" element={<Auth />} />
      </Route>
      {/* Protected Routes */}
      <Route element={requireAuth()}>
        <Route
          path="/"
          element={
            <main className="h-screen flex">
              <Sidebar  isOpen={isOpen} setIsOpen={setIsOpen} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
              <section className="size-full flex flex-col overflow-hidden">
                <Navbar isOpen={isOpen} setIsOpen={setIsOpen} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <div
                  id="main-container"
                  className="bg-halfGray h-full overflow-y-scroll pt-20"
                >
                  <Outlet />
                </div>
              </section>
            </main>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="products" element={<Products />} />
          <Route path="product">
            <Route path="add" element={<AddProduct />} />
            <Route path="edit/:productId" element={<EditProduct />} />
            <Route path=":variantId" element={<Product />} />
          </Route>
          <Route path="orders" element={<Orders />} />
          <Route path="order/:orderId" element={<Order />} />
          <Route path="categories" element={<Categories />} />
          <Route path="category">
            <Route path="add" element={<CategoryForm />} />
            <Route path="edit/:categoryId" element={<CategoryForm edit />} />
          </Route>
          <Route path="subcategories" element={<Subcategories />} />
          <Route path="subcategory">
            <Route path="add" element={<SubcategoryForm />} />
            <Route
              path="edit/:subcategoryId"
              element={<SubcategoryForm edit />}
            />
          </Route>
          <Route path="product-types" element={<ProductTypes />} />
          <Route path="product-type">
            <Route path="add" element={<ProductTypeForm />} />
            <Route
              path="edit/:productTypeId"
              element={<ProductTypeForm edit />}
            />
          </Route>
          <Route path="banners" element={<Banners />} />
          <Route path="banner">
            <Route path="add" element={<BannerForm />} />
            <Route path="edit/:bannerId" element={<BannerForm edit />} />
          </Route>
          <Route path="brands" element={<Brands />} />
          <Route path="brand">
            <Route path="add" element={<BrandForm />} />
            <Route path="edit/:brandId" element={<BrandForm edit />} />
          </Route>
          <Route path="coupons" element={<Coupons />} />
          <Route path="coupon/edit/:couponId" element={<CouponForm edit />} />
          <Route path="gift-cards" element={<GiftCards />} />
          <Route path="gift-card">
            <Route path="add" element={<TemplateForm />} />
            <Route path="edit/:templateId" element={<TemplateForm edit />} />
          </Route>
          <Route path="flash-deal">
            <Route index element={<FlashDeals />} />
            <Route path="edit/:dealId" element={<FlashDealForm edit />} />
            <Route
              path="add-product/:dealId"
              element={<AddProductOnFlashDeal />}
            />
          </Route>
          <Route path="featured-deal">
            <Route index element={<FeaturedDeals />} />
            <Route path="edit/:dealId" element={<FeaturedDealForm edit />} />
            <Route
              path="add-product/:dealId"
              element={<AddProductOnFeaturedDeal />}
            />
          </Route>
          <Route path="deal-of-the-day">
            <Route index element={<DealOfTheDay />} />
            <Route path="edit/:dealId" element={<DealOfTheDayForm edit />} />
          </Route>
          <Route path="membership-plan" element={<MemberPlan />} />
          <Route path="membership-list" element={<MemberList />} />
          <Route path="member/:userId" element={<Member />} />
          <Route path="wallet-transaction" element={<WalletTransaction />} />
          <Route path="utsav-orders" element={<UtsavOrders />} />
          <Route path="designer-list" element={<Designers />} />
          <Route path="borrow-list" element={<BorrowList />} />
          <Route path="fashion">
            <Route index element={<Fashion />} />
            <Route
              path="edit/:fashionCategoryId"
              element={<FashionForm edit />}
            />
            <Route path="blog">
              <Route index element={<Blog />} />
              <Route path="edit/:fashionBlogId" element={<BlogForm edit />} />
              <Route path="approvals" element={<Approvals />} />
            </Route>
          </Route>
          <Route path="customers" element={<Customers />} />
          <Route path="customer/:userId" element={<Customer />} />
          <Route path="business-setup" element={<BusinessSetup />} />
          <Route path="gallery">
            <Route index element={<Gallery />} />
            <Route path="add" element={<GalleryForm />} />
            <Route path="edit/:bannerId" element={<GalleryForm edit />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AnimatedRoutes;
