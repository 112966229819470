import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";

const OTPSetup = () => {
  return (
    <section>
      <div className="OTP-SETUP bg-white rounded-xl shadow-pop">
        <div className="flex items-center gap-2 py-4 px-6 border-b-2 border-secondary">
          <p className="font-medium">OTP and Login Setup</p>
        </div>
        <div className="px-6 py-4">
          <div className="grid grid-cols-3 gap-x-16 gap-y-3">
            <InputBox label="maximum OTP hit" name="companyName" value="" />
            <InputBox label="OTP resend time" />
            <InputBox label="temporary block time" />
            <InputBox label="maximum login hit" />
            <InputBox label="temporary login block time" />
          </div>
          <div className="pt-4 w-max ml-auto">
            <Button>save</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTPSetup;
