import { motion } from "framer-motion";
import { getColorForStatus, orderStatus } from "../../utils/constants";

const OrderStatus = ({ currentStatus, onStatusChange, statusCount }) => {
  const totalOrders = Object.values(statusCount || {}).reduce(
    (sum, count) => sum + count,
    0
  );

  return (
    <div className="flex overflow-x-auto bg-white rounded-md shadow-md p-2 mb-6 border w-full relative left-0 scrollbar-thin scrollbar-thumb-primeRed scrollbar-track-gray-200">
      {orderStatus.map((item) => (
        <div
          key={item}
          onClick={() => onStatusChange(item)}
          className={`relative flex flex-row items-center justify-center px-4 py-2 cursor-pointer transition-all duration-300 ${
            item === currentStatus || (item === "All Orders" && !currentStatus)
              ? "text-white text-m font-bold bg-primeRed rounded-lg"
              : "text-gray-600 hover:bg-slate-200 rounded-lg"
          }`}
        >
          {/* Status Label */}
          <span className="text-sm capitalize">{item} </span>

          {/* Count Badge */}
          <span
            className={`text-xs font-semibold mt-1 ${
              item === currentStatus || (item === "All Orders" && !currentStatus)
                ? "text-white"
                : "text-gray-500"
            }`}
          >
            ({item === "All Orders" ? totalOrders : statusCount?.[item] || 0})
          </span>
        </div>
      ))}
    </div>
  );
};

export default OrderStatus;
