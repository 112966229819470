import axiosInstance from "./axiosInstance";

const getMembershipPlan = async () => {
  const response = await axiosInstance.get("/getMembershipPlan");
  return response.data?.planDetails;
};

const updateMembershipPlan = (planData) => {
  return axiosInstance.post("/addMembershipPlan", planData);
};

const getAllMembers = async (params) => {
  const response = await axiosInstance.get("/getAllMemberList", { params });
  return response.data?.memberList;
};

const getMemberById = async (userId) => {
  const response = await axiosInstance.get(`/getMemberById/${userId}`);
  return response.data;
};

const getAllWalletTransactions = async () => {
  const response = await axiosInstance.get(`/getAllWalletTransaction`);
  return response.data?.detailsTransaction;
};

const getUtsavOrders = async (params) => {
  const response = await axiosInstance.get(`/totalOrderOfUtsav`, { params });
  return response.data;
};

const getAllDesigners = async () => {
  const response = await axiosInstance.get("/getAllApprovedLeader");
  return response.data?.membersWithDetails;
};

const getAllApprovals = async () => {
  const response = await axiosInstance.get("/getAllLeader");
  return response.data?.membersWithDetails;
};

const approveDesignerById = (userId, approval) => {
  return axiosInstance.post(`/approveLeaderRequest/${userId}`, { approval });
};

const getBorrowRequests = async () => {
  const response = await axiosInstance.get(`/getAllBorrowLIst`);
  return response.data;
};

const approveBorrowRequest = (borrowId, approval) => {
  return axiosInstance.post(`/approveBorrowRequest/${borrowId}`, { approval });
};

const makeUtsavMember = (userId, isUtsav) => {
  return axiosInstance.post(`/makeUtsabMember/${userId}`, {
    utsavStatus: isUtsav,
  });
};

const getDesigner = async (userId) => {
  const response = await axiosInstance.get(`/getLeaderDetails/${userId}`);
  return response.data?.leaderDetails;
};

const makeDesigner = async (userId, status) => {
  const response = await axiosInstance.post(`/addLeader/${userId}`, { status });
  return response.data;
};

const getAllGalleryBanners = async () => {
  const response = await axiosInstance.get("/getAllGallery");
  return response.data?.galleryDetails;
};

const getGalleryBannerById = async (bannerId) => {
  const response = await axiosInstance.get(
    `/getGalleryDetailsById/${bannerId}`
  );
  return response.data?.galleryDetails;
};

const createGalleryBanner = (bannerData) => {
  return axiosInstance.post("/createUtsavGallery", bannerData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateGalleryBanner = (bannerId, bannerData) => {
  return axiosInstance.post(`/editGalleryDetails/${bannerId}`, bannerData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteGalleryBanner = (bannerId) => {
  return axiosInstance.delete(`/deleteGalleryById/${bannerId}`);
};

const makePublish = (bannerId, isPublished) => {
  return axiosInstance.post(`/publishGalleryById/${bannerId}`, {
    is_published: isPublished,
  });
};

export {
  getMembershipPlan,
  updateMembershipPlan,
  getAllMembers,
  getMemberById,
  getAllWalletTransactions,
  getUtsavOrders,
  getAllDesigners,
  getAllApprovals,
  getDesigner,
  makeDesigner,
  approveDesignerById,
  getBorrowRequests,
  approveBorrowRequest,
  makeUtsavMember,
  getAllGalleryBanners,
  getGalleryBannerById,
  createGalleryBanner,
  updateGalleryBanner,
  deleteGalleryBanner,
  makePublish,
};
