import FlashDealForm from "./FlashDealForm";
import FlashDealList from "./FlashDealList";

const FlashDeals = () => {
  return (
    <section className="p-4 space-y-16">
      <FlashDealForm />
      <FlashDealList />
    </section>
  );
};

export default FlashDeals;
