import { useEffect, useState } from "react";
import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import TextareaBox from "../../components/TextareaBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import SelectBox from "../../components/SelectBox";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCategories,
  useCreateProductType,
  useProductTypeById,
  useSubcategoriesByCategory,
  useUpdateProductType,
} from "../../services/categoryServices";
import { useToast } from "../../contexts/toastContext";
import { BsImageAlt } from "react-icons/bs";
import TagsInput from "../../components/TagsInput";

const ProductTypeForm = ({ edit = false }) => {
  const { productTypeId } = useParams();
  const {
    data: productType,
    isLoading,
    error,
  } = useProductTypeById(productTypeId);
  const createMutation = useCreateProductType();
  const updateMutation = useUpdateProductType(productTypeId);
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitHandler = (productTypeData) => {
    const { name, description, categoryId, subCategoryId, features, logo } =
      productTypeData;
    const payload = {
      name,
      description,
      categoryId,
      subCategoryId,
      variationFeatures: features,
      logo: logo ? logo[0] : productType?.logoUrl,
    };

    if (edit) {
      updateMutation.mutate(
        { productTypeId, productTypeData: payload },
        {
          onSuccess: () => {
            toast.success("Product-Type updated successfully");
            navigate(-1);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Failed tp update product-type");
          },
        }
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Product-Type created successfully");
          navigate(-1);
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed tp create product-type");
        },
      });
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && productType) {
      setFormData({
        name: productType.name,
        description: productType.description,
        categoryId: productType.categoryId,
        subCategoryId: productType.subCategoryId,
        features: productType.variationFeatures,
        logo: null,
      });
      if (productType.logoUrl) {
        setSelectedImage(productType.logoUrl);
      }
    }
  }, [edit, productType, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  const { data: categories, isLoading: categoryLoading } = useCategories();
  const { data: subcategories, isLoading: subcategoryLoading } =
    useSubcategoriesByCategory(formData.categoryId);

  if (isLoading || (edit && !productType)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Product Type" : "Add Product Type"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[65%] space-y-4">
          <InputBox
            label="product type name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <SelectBox
            label="category"
            name="categoryId"
            value={formData.categoryId}
            onChange={handleChange}
            options={categories.map((category) => ({
              label: category.name,
              value: category._id,
            }))}
            disabled={categoryLoading}
          />
          <SelectBox
            label="sub category"
            name="subCategoryId"
            value={formData.subCategoryId}
            onChange={handleChange}
            options={subcategories.map((subcategory) => ({
              label: subcategory.name,
              value: subcategory._id,
            }))}
            disabled={
              !formData.categoryId ||
              subcategoryLoading ||
              subcategories.length === 0
            }
          />
          <FileInput
            label="product type logo ratio (1:1)"
            name="logo"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2"
          />
          <TextareaBox
            label="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />

          <div className="space-y-3">
            <h3 className="text-xl text-mainBlue">Variation Features</h3>
            <TagsInput
              label="features"
              name="features"
              value={formData.features}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-[30%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div className="size-60 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="productType-logo"
                  className="size-full object-cover object-center"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>

            <p className="text-sm font-medium">Dimensions: 900 x 900 pixels</p>
          </div>

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              Reset
            </Button>
            <Button
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              submit
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ProductTypeForm;
