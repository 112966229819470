import { MdOutlineAddBusiness } from "react-icons/md";
import RadioInput from "../../../components/RadioInput";
import SelectBox from "../../../components/SelectBox";
import InputBox from "../../../components/InputBox";

const BusinessInfo = () => {
  return (
    <div className="BUSINESS-INFORMATION bg-white rounded-xl shadow-pop">
      <div className="flex items-center gap-2 p-4 border-b-2 border-secondary">
        <MdOutlineAddBusiness />
        <p className="font-medium">Business Information</p>
      </div>
      <div className="grid grid-cols-3 gap-x-16 gap-y-3 p-4">
        <div className="RADIO-OPTIONS space-y-2">
          <p className="text-sm font-medium">Currency Position</p>
          <div className="flex justify-between gap-4">
            <RadioInput
              label="₹ left"
              name="currencyPosition"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
            <RadioInput
              label="right ₹"
              name="currencyPosition"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
          </div>
        </div>
        <SelectBox
          label="Forgot password verification by"
          options={["Email", "Phone"].map((value) => ({
            label: value,
            value,
          }))}
        />
        <div className="RADIO-OPTIONS space-y-2">
          <p className="text-sm font-medium">OTP verification</p>
          <div className="flex justify-between gap-4">
            <RadioInput
              label="on"
              name="OTPVerification"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
            <RadioInput
              label="off"
              name="OTPVerification"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
          </div>
        </div>
        <div className="RADIO-OPTIONS space-y-2">
          <p className="text-sm font-medium">Email Verification</p>
          <div className="flex justify-between gap-4">
            <RadioInput
              label="on"
              name="emailVerification"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
            <RadioInput
              label="off"
              name="emailVerification"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
          </div>
        </div>
        <div className="RADIO-OPTIONS space-y-2">
          <p className="text-sm font-medium">Order verification</p>
          <div className="flex justify-between gap-4">
            <RadioInput
              label="on"
              name="orderVerification"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
            <RadioInput
              label="off"
              name="orderVerification"
              align="left"
              containerClassName="border border-secondary px-4 py-2 rounded-md bg-offWhite justify-between flex-grow"
            />
          </div>
        </div>
        <InputBox label="Company Copyright Text" />
        <InputBox label="Pagination Settings" />
        <InputBox label="Digit after decimal point ( ex: 0.00)" />
      </div>
    </div>
  );
};

export default BusinessInfo;
