const ToggleSwitch = ({
  label,
  name,
  onChange,
  checked = false,
  disabled = false,
  align = "left",
  required = false,
  className = "w-10 h-5",
  containerClassName = "gap-2",
}) => {
  const id = name?.replace(" ", "-").toLowerCase();

  return (
    <div className={`flex items-center ${containerClassName}`}>
      {label && align === "left" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}
      <div
        className={`relative rounded-full shadow-inner ${
          disabled ? "bg-lightGray" : checked ? "bg-primeRed" : "bg-mainGray"
        } ${className}`}
      >
        <span
          className={`absolute left-1 top-1/2 -translate-y-1/2 bg-white size-3 rounded-full shadow transition-transform ${
            checked ? "translate-x-5" : "translate-x-0"
          }`}
        />
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          required={required}
          className={`block absolute inset-0 appearance-none ${
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        />
      </div>
      {label && align === "right" && (
        <label htmlFor={id} className="capitalize text-sm font-medium">
          {label}
        </label>
      )}
    </div>
  );
};

export default ToggleSwitch;
