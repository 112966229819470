import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import useDeal from "../../hooks/useDeal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../contexts/toastContext";
import { format } from "date-fns";
import { BsImageAlt } from "react-icons/bs";
import { useFlashDealById } from "../../services/dealServices";

const FlashDealForm = ({ edit = false }) => {
  const { dealId } = useParams();
  const { data: deal, isLoading, error } = useFlashDealById(dealId);
  const { addDeal, editDeal, loading } = useDeal("flash", dealId);
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitHandler = (dealData) => {
    const { banner } = dealData;
    try {
      if (edit) {
        editDeal(dealId, { ...dealData, banner: banner[0] }).then(() => {
          toast.success("Deal updated successfully");
          navigate(-1);
        });
      } else {
        addDeal({ ...dealData, banner: banner[0] }).then(() => {
          toast.success("Deal created successfully");
          clearForm();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && deal) {
      setFormData({
        ...deal,
        start_Date: format(new Date(deal.start_Date), "yyyy-MM-dd"),
        end_Date: format(new Date(deal.end_Date), "yyyy-MM-dd"),
      });
      if (deal.banner) {
        setSelectedImage(deal.banner);
      }
    }
  }, [edit, deal, setFormData]);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  if (isLoading || (edit && !deal)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className={`space-y-4 ${edit && "p-4"}`}>
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Flash Deal" : "Add Flash Deal"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        <div className="w-[45%] space-y-4">
          <InputBox
            label="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          <FileInput
            label="upload image (ratio 3:4)"
            name="banner"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2"
          />
          <InputBox
            type="date"
            label="start date"
            name="start_Date"
            value={formData.start_Date}
            onChange={handleChange}
          />
          <InputBox
            type="date"
            label="end date"
            name="end_Date"
            value={formData.end_Date}
            onChange={handleChange}
          />
        </div>
        <div className="w-[50%] flex flex-col items-end gap-16">
          <div className="space-y-4 flex flex-col items-end">
            <div className="w-60 h-80 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="deal-logo"
                  className="size-full object-cover object-center"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>

            <p className="text-sm font-medium">Dimensions: 1280 x 960 pixels</p>
          </div>

          <div className="flex gap-8 justify-end">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={loading}
            >
              Reset
            </Button>
            <Button disabled={loading}>submit</Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default FlashDealForm;
