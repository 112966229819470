import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import PropTypes from "prop-types";
import { BiError } from "react-icons/bi";

const InputBox = ({
  value = "",
  onChange,
  name,
  label,
  type,
  placeholder,
  align = false,
  disabled = false,
  readOnly = false,
  min = 0,
  max = Infinity,
  autoComplete,
  autoCorrect,
  order,
  required = false,
  className = "w-full px-4 py-2 rounded-md text-xs border border-secondary bg-offWhite",
  containerClassName = "gap-2",
  validation,
}) => {
  const id = label?.replace(" ", "-").toLowerCase();
  const [showPassword, setShowPassword] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBlur = () => {
    if (!validation) return;
    else if (!validation(value).success) {
      setErrMessage(validation(value).message);
    } else if (validation(value).success) {
      setErrMessage(null);
    }
  };

  return (
    <div className={"relative flex flex-col" + " ".concat(containerClassName)}>
      {label && (
        <label
          htmlFor={id}
          className="capitalize font-medium text-sm"
          style={align ? { textAlign: "center" } : {}}
        >
          {label}
        </label>
      )}

      {type !== "file" && (
        <input
          id={id}
          type={showPassword ? "text" : type}
          name={name}
          value={value}
          placeholder={placeholder || label}
          onChange={onChange}
          onBlur={handleBlur}
          onWheel={(e) => e.target.blur()}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          min={type === "number" ? min : undefined}
          max={type === "number" ? max : undefined}
          className={`outline-none placeholder:capitalize ${
            type === "number" &&
            "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          } ${disabled && "opacity-50"} ${className}`}
        />
      )}

      {errMessage && (
        <div className="absolute top-full p-1 flex justify-center items-start gap-1 text-primeRed ml-1 z-10 bg-white rounded-lg">
          <BiError size={12} />
          <p className="text-xxs max-w-44 leading-tight">{errMessage}</p>
        </div>
      )}

      {type === "password" && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-4 bottom-3 text-secondary text-sm"
        >
          {showPassword ? <FiEye /> : <FiEyeOff />}
        </button>
      )}
    </div>
  );
};

export default InputBox;

InputBox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};
