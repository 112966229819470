import { BsImageAlt } from "react-icons/bs";
import FileInput from "../../../components/FileInput";
import { FaRegImage } from "react-icons/fa";
import { useState } from "react";

const FooterLogo = ({ handleChange }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (!image) {
      setSelectedImage(null);
    } else {
      setSelectedImage(URL.createObjectURL(image));
    }
    handleChange(event);
  };

  return (
    <div className="WEBSITE-FOOTER-LOGO bg-white rounded-xl shadow-pop">
      <div className="flex items-center justify-between gap-2 p-4 border-b-2 border-secondary">
        <div className="flex items-center gap-2">
          <FaRegImage />
          <p className="font-medium">Website Footer Logo</p>
        </div>
        <p className="text-xxs text-mainBlue font-medium">( 500x120 px )</p>
      </div>
      <div className="space-y-8 p-4">
        <div className="w-[350px] h-[84px] bg-lightGray mx-auto flex items-center justify-center flex-shrink-0">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt={selectedImage}
              className="size-full object-cover object-center"
            />
          ) : (
            <BsImageAlt size={32} className="text-primary" />
          )}
        </div>
        <FileInput
          align="top"
          name="logo"
          onChange={handleFileChange}
          dynamic
          button
        />
      </div>
    </div>
  );
};

export default FooterLogo;
