import { IoMdStar, IoMdStarHalf, IoMdStarOutline } from "react-icons/io";

const Rating = ({ rating, maxRating = 5, size = 16 }) => {
  const renderStars = () => {
    const stars = [];
    const ceilRating = Math.ceil(rating);
    const hasHalfStar = Math.floor(rating) !== Math.ceil(rating);

    for (let i = 1; i <= maxRating; i++) {
      if (i <= rating)
        stars.push(
          <span key={i} className="text-yellow-500 text-lg">
            <IoMdStar size={size} />
          </span>
        );
      else if (i === ceilRating && hasHalfStar)
        stars.push(
          <span key={i} className="text-yellow-500 text-lg">
            <IoMdStarHalf size={size} />
          </span>
        );
      else
        stars.push(
          <span key={i} className="text-gray-300 text-lg">
            <IoMdStarOutline size={size} />
          </span>
        );
    }

    return stars;
  };

  return <div className="flex">{renderStars()}</div>;
};

export default Rating;
