import {
  MdOutlineTrendingFlat,
  MdOutlineTrendingUp,
  MdTrendingDown,
} from "react-icons/md";
import { Link } from "react-router-dom";

const Tab = ({ title, icon, body, url, growth, accent }) => {
  const boxShadow = `0 4px 10px ${accent}40`;

  return (
    <div
      className="w-full sm:w-60 flex flex-col justify-between rounded-lg p-3 sm:p-5 shadow-lg transition-transform transform hover:scale-105"
      style={{
        background: `linear-gradient(135deg, ${accent}20, ${accent}05)`,
        boxShadow,
      }}
    >
      <div className="flex items-center gap-1 sm:gap-2 mb-4 sm:mb-6">
        {icon && (
          <span className="text-xl sm:text-2xl" style={{ color: accent }}>
            {icon}
          </span>
        )}
        <h2 className="text-base sm:text-lg font-semibold" style={{ color: accent }}>
          {title}
        </h2>
      </div>

      <div className="BODY flex justify-between items-start">
        <p className="text-lg sm:text-xl font-semibold">{body}</p>
        <div className="GROWTH bg-white flex items-center gap-1 sm:gap-2 mt-1 px-2 py-1 rounded-lg shadow-md">
          <p className="text-xs sm:text-sm font-medium">{growth}%</p>
          <span
            className={`px-1 text-sm border rounded-full ${
              Number(growth) === 0
                ? "border-gray-400 text-gray-500"
                : Number(growth) > 0
                ? "border-green-400 text-green-500"
                : "border-red-400 text-red-500"
            }`}
          >
            {Number(growth) === 0 ? (
              <MdOutlineTrendingFlat />
            ) : Number(growth) > 0 ? (
              <MdOutlineTrendingUp />
            ) : (
              <MdTrendingDown />
            )}
          </span>
        </div>
      </div>

      <Link
        to={url}
        className="text-xs sm:text-sm text-blue-500 hover:text-blue-600 transition-all underline font-medium mt-2"
      >
        View full list
      </Link>
    </div>
  );
};

export default Tab;
