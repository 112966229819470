import { FaUser } from "react-icons/fa";
import { FiShoppingBag } from "react-icons/fi";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";

const Details = ({ user, numOfOrders }) => {
  return (
    <div className="border border-primary rounded-xl bg-white shadow-pop">
      <div className="HEAD flex items-center gap-4 px-4 py-2 border-b border-primary">
        <span className="bg-mainRose p-2 rounded-full text-primeRed">
          <FaUser size={14} />
        </span>
        <h2 className="font-medium">Customer</h2>
      </div>

      <div className="BODY flex items-start gap-4 px-4 py-2">
        <div className="size-16 rounded-full">
          <img
            src={user.imgUrl}
            alt={user.fullName}
            className="size-full object-cover object-center rounded-full"
          />
        </div>
        <div className="text-sm space-y-1">
          <p className="font-medium text-lg">{user.fullName}</p>
          <span className="flex items-center gap-2">
            <FiShoppingBag />
            <p>{numOfOrders} Orders</p>
          </span>
          <span className="flex items-center gap-2">
            <MdOutlinePhone />
            <p>{user.phone}</p>
          </span>
          <span className="flex items-center gap-2">
            <MdOutlineEmail />
            <p>{user.email}</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Details;
