function trimFilename(filename, offset = 10) {
  const lastDotIndex = filename.lastIndexOf(".");
  // Handle the case where there is no extension
  if (lastDotIndex === -1) {
    return filename.length > offset * 2
      ? `${filename.slice(0, offset)}...${filename.slice(-offset)}`
      : filename;
  }

  const namePart = filename.substring(0, lastDotIndex);
  const extension = filename.substring(lastDotIndex);

  // If the name part is shorter than the combined offset length, return it as is
  if (namePart.length <= offset * 2) {
    return filename;
  }

  const shortenedName = `${namePart.slice(0, offset)}...${namePart.slice(
    -offset
  )}`;

  return `${shortenedName}${extension}`;
}

export { trimFilename };
