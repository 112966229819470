import { MdFileDownload } from "react-icons/md";
import Button from "../../components/Button";
import SearchBox from "../../components/SearchBox";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductList from "./ProductList";
import useForm from "../../hooks/useForm";
import { useVariants } from "../../services/productServices";

const Products = () => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const handleSearch = (formData) => {
    const { query } = formData;
    setSearchParams({ query, page: 1 });
  };

  const {
    data: { totalCount = 0 } = {},

    refetch,
  } = useVariants({ });

  const { formData, handleChange, handleSubmit } = useForm({}, handleSearch);

  return (
    <section className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Products <span className="bg-mainGray text-xs font-medium px-2 py-1 rounded-full mt-1">
          {totalCount}
        </span></h1>
      <div className="HEADING flex justify-between items-center">
        <SearchBox
          name="query"
          value={formData.query}
          onChange={handleChange}
          onSubmit={handleSubmit}
          placeHolder="search product name"
        />
        
        <div className="flex gap-4">
          <Button
            reverse
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <MdFileDownload className="mt-[3px]" />
            <p className="text-sm">export</p>
          </Button>
          <Button
            onClick={() => navigate("/product/add")}
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <FiPlus />
            <p className="text-sm">add product</p>
          </Button>
        </div>
      </div>

      <ProductList />
    </section>
  );
};

export default Products;
