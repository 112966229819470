import HeaderLogo from "./HeaderLogo";
import useForm from "../../../hooks/useForm";
import FooterLogo from "./FooterLogo";
import WebsiteColor from "./WebsiteColor";
import WebsiteFavicon from "./WebsiteFavicon";
import LoadingGif from "./LoadingGif";
import AppLogo from "./AppLogo";
import BusinessInfo from "./BusinessInfo";
import CompanyInfo from "./CompanyInfo";
import Maintenance from "./Maintenance";
import Button from "../../../components/Button";

const General = () => {
  const { handleChange } = useForm({});

  return (
    <section className="space-y-4">
      <Maintenance />
      <CompanyInfo />
      <BusinessInfo />
      <section className="grid grid-cols-3 gap-4">
        <WebsiteColor />
        <HeaderLogo handleChange={handleChange} />
        <FooterLogo handleChange={handleChange} />
        <WebsiteFavicon handleChange={handleChange} />
        <LoadingGif handleChange={handleChange} />
        <AppLogo handleChange={handleChange} />
      </section>
      <div className="w-max ml-auto">
        <Button>Submit</Button>
      </div>
    </section>
  );
};

export default General;
