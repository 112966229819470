import BlogForm from "./BlogForm";
import BlogList from "./BlogList";

const Blog = () => {
  return (
    <section className="p-4 space-y-16">
      <BlogForm />
      <BlogList />
    </section>
  );
};

export default Blog;
