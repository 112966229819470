import { MdFileDownload } from "react-icons/md";
import Button from "../../components/Button";
import SearchBox from "../../components/SearchBox";
import CustomerList from "./CustomerList";
import { useCustomers } from "../../services/customerServices";
import { useSearchParams } from "react-router-dom";
import useForm from "../../hooks/useForm";

const Customers = () => {
  const [, setSearchParams] = useSearchParams();
  const {
    data: { totalCount = 0 } = {},
    isLoading,
    error
  } = useCustomers({}); 

  const handleSearch = (formData) => {
    const { query } = formData;
    setSearchParams({ query, page: 1 });
  };

  const { formData, handleChange, handleSubmit } = useForm({}, handleSearch);

  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching All Customers
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className="p-4 space-y-4">
      <div className="flex items-center gap-4">
        <h1 className="text-3xl font-medium">All Customers</h1>
        <span className="bg-mainGray text-xs font-medium px-2 py-1 rounded-full mt-1">
          {totalCount}
        </span>
      </div>
      <div className="HEADING flex justify-between items-center">
        <SearchBox
          name="query"
          value={formData.query}
          onChange={handleChange}
          onSubmit={handleSubmit}
          placeHolder="search by name"
        />
        <div className="flex gap-4">
          <Button
            reverse
            className="rounded-md px-3 py-2 font-medium flex items-center gap-2"
          >
            <MdFileDownload className="mt-[3px]" />
            <p className="text-sm">export</p>
          </Button>
        </div>
      </div>
      <CustomerList />
    </section>
  );
};

export default Customers;
