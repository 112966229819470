import { FaRegEdit, FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import ToggleSwitch from "../../components/ToggleSwitch";
import Pagination from "../../components/Pagination";
import DeleteProduct from "./DeleteProduct";
import { useModal } from "../../contexts/modalContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useProduct from "../../hooks/useProduct";
import { useVariants } from "../../services/productServices";
import { useMemo } from "react";
import useForm from "../../hooks/useForm";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import Loading from "../../components/Loading";
import { motion } from "framer-motion";

const ProductList = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [page, setPage] = useSearchParamsState("page", 1);
  const limit = 16;
  const {
    data: { variants = [], totalCount = 0 } = {},
    isLoading,
    isFetching,
    error,
    refetch,
  } = useVariants({ query, page, limit });
  const { removeVariant, updateFeature, setNewArrivals, setIsActive } =
    useProduct();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { formData, setFormData } = useForm({});


  const handleDelete = (variantId) => {
    removeVariant(variantId).then(() => {
      closeModal();
    });
  };

  const handleIsFeatured = async (variantId, e) => {
    const isFeatured = e.target.checked;
  
    try {
      await updateFeature(variantId, isFeatured);
  
      setFormData((prevData) => ({
        ...prevData,
        [variantId]: {
          ...(prevData[variantId] || {}), // Ensure the variantId key exists
          is_featured: isFeatured,
        },
      }));
    } catch (error) {
      console.error("Failed to update feature status:", error);
      // Optionally, show a user-friendly error message here
    }
  };
  

  const handleIsNewArrival = async (variantId, e) => {
    const newArrival = e.target.checked;
  
    try {
      // Update the newArrival state optimistically
      setFormData((prevData) => ({
        ...prevData,
        [variantId]: { ...prevData[variantId], newArrival },
      }));
  
      // Call setNewArrivals to handle the server update
      const updatedArrivalStatus = await setNewArrivals(variantId, newArrival);
  
      // Update formData with the confirmed response, in case server response differs
      setFormData((prevData) => ({
        ...prevData,
        [variantId]: { ...prevData[variantId], newArrival: updatedArrivalStatus },
      }));
    } catch (error) {
      console.error("Failed to set new arrival status:", error);
      // Optionally handle the error by reverting the formData change or showing a message
    }
  };
  

  const handleIsActive = async (variantId, e) => {
    const is_active = e.target.checked;
  
    // Optimistically update formData
    setFormData((prevData) => ({
      ...prevData,
      [variantId]: { ...prevData[variantId], is_active },
    }));
  
    try {
      // Call setIsActive to handle the server update
      const updatedStatus = await setIsActive(variantId, is_active);
  
      // Optionally update the local state if the response differs
      if (updatedStatus !== is_active) {
        setFormData((prevData) => ({
          ...prevData,
          [variantId]: { ...prevData[variantId], is_active: updatedStatus },
        }));
      }
    } catch (error) {
      // If there's an error, revert the change in formData
      console.error("Failed to update active status:", error);
      setFormData((prevData) => ({
        ...prevData,
        [variantId]: { ...prevData[variantId], is_active: !is_active },
      }));
    }
  };
  
  if (isLoading) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Fetching Products
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error?.response?.data?.message || error.message}
        </h2>
      </div>
    );
  }

  return variants.length === 0 ? (
    <div className="w-full">
      <h2 className="text-center font-secondary font-medium text-xl mt-8">
        No Products Found
      </h2>
    </div>
  ) : (
    <div className="w-full min-h-[720px] overflow-hidden">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
        {variants?.map((product, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-xl shadow-md p-3"
          >
            <div onClick={() => navigate(`/product/${product._id}`)} className="relative group">
              <div className="w-full h-55 overflow-hidden rounded-lg">
                <img
                  src={product?.images[0]}
                  alt={product.name}
                  className="w-full h-full object-cover object-center group-hover:opacity-0 transition-opacity duration-300"
                />
                {product?.images[1] && (
                  <img
                    src={product?.images[1]}
                    alt={`${product.name} - next`}
                    className="absolute top-0 left-0 w-full h-full object-cover object-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  />
                )}
               
               <p
  className={`absolute top-2 right-2 p-1.5 shadow-lg rounded-full ${
    product?.quantity > 9 ? 'pr-2.5 pl-2.5' : 'pr-3 pl-3'
  }  text-center text-white text-sm ${
    product?.quantity > 3 ? 'bg-green-500' : 'bg-primeRed'
  }`}
>
   {product?.quantity}
</p>
                
              </div>
            </div>
            <div className="text-xs mt-4">
              <h3 className="font-medium">{product?.name}</h3>
              {product?.attributes &&
                Object.keys(product?.attributes)
                  .slice(0, 3)
                  .map((key) => (
                    <p key={key} className="capitalize">
                      {key}: {product?.attributes[key]}
                    </p>
                  ))}
            </div>
            <div className="flex justify-between items-center mt-4">
              <div>
                <ToggleSwitch
                  name="is_featured"
                  checked={formData[product._id]?.is_featured || product?.is_featured}
                  onChange={(e) => handleIsFeatured(product._id, e)}
                  className="mx-auto w-10 h-5"
                />
                <p className="text-center text-sm">Featured</p>
              </div>
              <div>
                <ToggleSwitch
                  name="newArrival"
                  checked={formData[product._id]?.newArrival || product?.newArrival}
                  onChange={(e) => handleIsNewArrival(product._id, e)}
                  className="mx-auto w-10 h-5"
                />
                <p className="text-center text-sm">New Arrival</p>
              </div>
              <div>
                <ToggleSwitch
                  name="is_active"
                  checked={formData[product._id]?.is_active || product?.is_active}
                  onChange={(e) => handleIsActive(product._id, e)}
                  className="mx-auto w-10 h-5"
                />
                <p className="text-center text-sm">Active</p>
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-4">

              <Link to={`/product/edit/${product.productGroup?._id}`} className="text-warning">
                <FaRegEdit />
              </Link>
              <button
                className="text-danger"
                onClick={() =>
                  openModal({
                    content: <DeleteProduct variant={product} />,
                    title: "Confirm Product Deletion",
                  })
                }
              >
                <FaRegTrashAlt />
              </button>
            </div>
          </motion.div>
        ))}
      </div>

      {isFetching && <Loading />}

      <Pagination
        currentPage={parseInt(page, 10)}
        totalCount={totalCount}
        pageSize={limit}
        onPageChange={(page) => setPage(page.toString())}
      />
    </div>
  );
};

export default ProductList;
