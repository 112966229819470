import Button from "../../components/Button";
import FileInputBox from "../../components/FileInputBox";
import InputBox from "../../components/InputBox";
import useForm from "../../hooks/useForm";
import { useEffect, useState } from "react";
import SelectBox from "../../components/SelectBox";
import { useVariants } from "../../services/productServices";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateFashionBlog,
  useFashionBlogById,
  useFashionCategories,
  useUpdateFashionBlog,
} from "../../services/fashionServices";
import { useToast } from "../../contexts/toastContext";
import { isVideoFile, isVideoUrl } from "../../utils/fileUtils";
import { MdCloudUpload, MdOutlineCloudUpload } from "react-icons/md";
import { FaTrash } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

const BlogForm = ({ edit = false }) => {
  const { fashionBlogId } = useParams();
  const {
    data: fashionBlog,
    isLoading,
    error,
  } = useFashionBlogById(fashionBlogId);
  const { data: variants = [], isLoading: productsLoading } = useVariants();
  const { data: fashionCategories = [], isLoading: fashionCategoriesLoading } =
    useFashionCategories();
  const createMutation = useCreateFashionBlog();
  const updateMutation = useUpdateFashionBlog(fashionBlogId);
  const navigate = useNavigate();
  const toast = useToast();
  const [posts, setPosts] = useState([]);

  const onSubmitHandler = (blogData) => {
    const { userName, productList, fashionCategory, userLogo } = blogData;

    //Client side validations
    const warnings = [];

    if (!userName) {
      warnings.push("Please enter uploader name");
    }
    if (posts.length === 0) {
      warnings.push("Please upload at least one post");
    }
    if (!userLogo) {
      warnings.push("Please upload the uploader logo");
    }
    if (!productList && productList?.length === 0) {
      warnings.push("Please add at least one suggested product");
    }
    if (!fashionCategory) {
      warnings.push("Please select a fashion category");
    }

    // If there are warnings, display them and return
    if (warnings.length > 0) {
      warnings.forEach((warning) => toast.error(warning));
      // return;
    }

    const payload = {
      ...formData,
      logoUrl: posts,
      userLogo: userLogo ? userLogo[0] : "",
    };
    console.log(payload);

    if (edit) {
      updateMutation.mutate(
        { fashionBlogId, blogData: payload },
        {
          onSuccess: () => {
            toast.success("Blog updated successfully");
            clearForm();
            navigate(-1);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Failed to update blog");
          },
        }
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Blog created successfully");
          clearForm();
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to create blog");
        },
      });
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (edit && fashionBlog) {
      setFormData({ ...fashionBlog });
      if (fashionBlog.logoUrls) {
        setPosts(fashionBlog.logoUrls);
      }
    }
  }, [edit, fashionBlog, setFormData]);

  const handlePostUpload = (event) => {
    const newPosts = Array.from(event.target.files);
    setPosts((prevPosts) => [...prevPosts, ...newPosts]);
  };

  const handlePostRemove = (index) => {
    setPosts((prevPosts) => {
      const updatedPosts = [...prevPosts];
      updatedPosts.splice(index, 1);
      return updatedPosts;
    });
  };

  const renderMedia = (post) => {
    const isFile = post instanceof File;
    const fileUrl = isFile ? URL.createObjectURL(post) : post;
    const isVideo = isFile ? isVideoFile(post) : isVideoUrl(post);

    return isVideo ? (
      <video
        controls
        controlsList="nodownload"
        autoPlay
        loop
        className="size-full object-contain object-center rounded-sm"
      >
        <source src={fileUrl} type="video/mp4" />
        <source src={fileUrl} type="video/mkv" />
        <source src={fileUrl} type="video/avi" />
        <source src={fileUrl} type="video/webm" />
        <source src={fileUrl} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={fileUrl}
        alt="uploaded"
        className="size-full object-contain object-center rounded-sm"
      />
    );
  };

  if (isLoading || (edit && !fashionBlog)) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <section className={`space-y-4 ${edit && "p-4"}`}>
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Fashion Blog" : "Upload Fashion Blog"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 space-y-8"
      >
        <section className="TOP space-y-4">
          <div className="USER-DISPLAY">
            <p className="text-sm font-medium mb-2">Uploaded By</p>
            <div className="flex items-center gap-4">
              {!formData.userLogo ? (
                <FileInputBox
                  name="userLogo"
                  onChange={handleChange}
                  align="top"
                  className="size-20 p-1 border border-secondary rounded-full"
                >
                  <div className="bg-offWhite size-full rounded-full flex items-center justify-center">
                    <MdOutlineCloudUpload size={24} className="text-primary" />
                  </div>
                </FileInputBox>
              ) : (
                <div className="relative size-20 p-1 border border-secondary rounded-full">
                  <img
                    src={
                      typeof formData.userLogo[0] === "string"
                        ? formData.userLogo[0]
                        : URL.createObjectURL(formData.userLogo[0])
                    }
                    alt="user logo"
                    className="size-full object-cover object-center rounded-full"
                  />
                  <button
                    type="button"
                    className="absolute top-0 right-0 bg-white rounded-full p-1"
                    onClick={() => setFormData({ ...formData, userLogo: null })}
                  >
                    <IoMdClose
                      size={12}
                      strokeWidth={10}
                      className="text-primeRed"
                    />
                  </button>
                </div>
              )}

              <InputBox
                placeholder="Enter the name"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                className="w-80 px-6 py-3 border-b-2 border-primary text-sm placeholder:italic"
              />
            </div>
          </div>

          <div className="POST-DISPLAY w-full flex flex-wrap items-end gap-4">
            <FileInputBox
              label="upload image / video"
              onChange={handlePostUpload}
              align="top"
              multiple
              accept=".jpg, .jpeg, .png, .gif, .mp4, .mkv, .avi, .mov"
              className="size-60 p-1 border border-secondary rounded-md"
            >
              <div className="bg-offWhite size-full p-8 flex flex-col items-center justify-center rounded-sm">
                <MdCloudUpload size={48} className="text-primary" />
                <p className="text-sm font-medium text-primary text-center my-4">
                  Drag & drop or click here to upload image / video
                </p>
              </div>
            </FileInputBox>
            {posts.map((post, index) => (
              <div
                key={index}
                className="relative size-60 p-1 border border-secondary rounded-md"
              >
                {renderMedia(post)}
                <button
                  type="button"
                  className="absolute top-2 right-2 bg-white rounded-full p-1"
                  onClick={() => handlePostRemove(index)}
                >
                  <FaTrash size={12} className="text-primeRed" />
                </button>
              </div>
            ))}
          </div>

          <InputBox
            placeholder="write something..."
            name="caption"
            value={formData.caption}
            onChange={handleChange}
            className="px-6 py-3 border-b-2 border-primary text-sm placeholder:italic"
          />

          <div className="w-full grid grid-cols-2 gap-x-16">
            <SelectBox
              label="select products"
              name="productList"
              value={formData.productList}
              onChange={handleChange}
              options={variants?.map((variant) => ({
                label: variant.productGroup.name + " " + variant.sku,
                value: variant._id,
              }))}
              searchable
              multi
              disabled={productsLoading}
              className="px-6 py-3 border-b-2 border-primary text-sm"
            />
            <SelectBox
              label="Fashion Category"
              name="fashionCategory"
              value={formData.fashionCategory}
              onChange={handleChange}
              options={fashionCategories.map((category) => ({
                label: category.name,
                value: category._id,
              }))}
              searchable
              disabled={fashionCategoriesLoading}
              className="px-6 py-3 border-b-2 border-primary text-sm"
            />
          </div>
        </section>

        <section className="BTN-SECTION flex gap-8 justify-end">
          <Button
            disabled={createMutation.isPending || updateMutation.isPending}
            className="px-4 py-2 rounded-md text-sm"
          >
            post
          </Button>
        </section>
      </form>
    </section>
  );
};

export default BlogForm;
