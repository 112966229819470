import { useCallback, useState } from "react";
import { makeUtsavMember } from "../api/utsavApis";

const useMember = (userId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const makeMember = useCallback(async (userId, isUtsav) => {
    setLoading(true);
    setError(null);
    try {
      const response = await makeUtsavMember(userId, isUtsav);

      if (response?.status) {
        console.log(response.data);
        sessionStorage.removeItem("utsavMembers");
        sessionStorage.removeItem(`utsavMember_${userId}`);
        sessionStorage.removeItem("customers");
        sessionStorage.removeItem(`customer_${userId}`);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    makeMember,
    loading,
    error,
  };
};

export default useMember;
