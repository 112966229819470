import { MdCloudUpload } from "react-icons/md";
import Button from "../../components/Button";
import CheckBox from "../../components/CheckBox";
import FileInputBox from "../../components/FileInputBox";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import TextEditor from "../../components/TextEditor";
import ToggleSwitch from "../../components/ToggleSwitch";
import useForm from "../../hooks/useForm";
import { useEffect, useState } from "react";
import useProduct from "../../hooks/useProduct";
import { useProductById } from "../../services/productServices";
import {
  useCategories,
  useProductTypeById,
  useProductTypesBySubcategory,
  useSubcategoriesByCategory,
} from "../../services/categoryServices";
import TagsInput from "../../components/TagsInput";
import { FaTrash } from "react-icons/fa";
import { useBrands } from "../../services/brandServices";
import { useToast } from "../../contexts/toastContext";
import { useParams } from "react-router-dom";

const EditProduct = () => {
  const [variation, setVariation] = useState({});
  const [variants, setVariants] = useState([]);
  const [description, setDescription] = useState("");
  const [otherImages, setOtherImages] = useState([]);
  const { productId } = useParams();
  const [duplicateCheckboxes, setDuplicateCheckboxes] = useState(
    Array(variants?.length).fill(false)
  );
  const toast = useToast();
  const { data: product, isLoading, error } = useProductById(productId);
  const { editProduct, editVariant, addVariant } = useProduct();

  const onSubmitHandler = (productData) => {
    try {
      const {
        name,
        category,
        subCategory,
        productType,
        brand,
        unit,
        productCode,
        thumbnail,
      } = productData;

      // Client side validations
      const warnings = [];

      if (!name) {
        warnings.push("Please enter product name");
      }
      if (!category) {
        warnings.push("Please select a category");
      }
      if (!subCategory) {
        warnings.push("Please select a sub category");
      }
      if (!productType) {
        warnings.push("Please select a product type");
      }
      if (!brand) {
        warnings.push("Please select a brand");
      }
      if (!unit) {
        warnings.push("Please select an unit");
      }
      if (!productCode) {
        warnings.push("Please enter a unique product code");
      }
      if (description.length === 0) {
        warnings.push("Please enter product description");
      }
      if (Object.keys(variation).length === 0 || variants.length === 0) {
        warnings.push("Product can't be created without variation");
      }
      if (!thumbnail) {
        warnings.push("Please upload a thumbnail");
      }
      if (otherImages.length === 0) {
        warnings.push("Please upload at least one other images");
      }

      // If there are errors, display them and return
      if (warnings.length > 0) {
        warnings.forEach((warning) => toast.error(warning));
        return;
      }

      const payload = {
        ...formData,
        thumbnail: formData.thumbnail[0],
        description,
        otherImages,
        variation,
      };
      console.log(JSON.stringify(payload));

      editProduct(productId, payload);
    } catch (error) {
      console.error(error);
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name,
        hasExpiry: product.hasExpiry,
        isExpirySaleable: product.isExpirySaleable,
        isCustomizable: product.isCustomizable,
        hasColorShade: product.hasColorShade,
        category: product.categoryId,
        subCategory: product.subCategoryId,
        productType: product.productTypeId,
        brand: product.brand,
        unit: product.unit,
        totalQuantity: product.totalQuantity,
        productCode: product.productCode,
        variationAttributes: product.variationAttributes,
        thumbnail: product.thumbnail,
      });
      setDescription(product.description);
      setOtherImages(product.otherImages);
      setVariation(product.variation);
      setVariants(product.variants);
    }
  }, [product, setFormData]);

  const handleChangeAndClearVariation = (e) => {
    handleChange(e);
    if (e.target.name === "variationAttributes") {
      const selectedAttributes = e.target.value;
      const newVariation = { ...variation };

      Object.keys(variation).forEach((key) => {
        if (!selectedAttributes.includes(key)) {
          delete newVariation[key];
        }
      });

      setVariation(newVariation);
    }
  };

  const handleVariationChange = (event) => {
    const { name, value } = event.target;
    setVariation({ ...variation, [name]: value });
  };

  const generateVariants = () => {
    const keys = Object.keys(variation).filter(
      (key) => variation[key].length > 0
    );
    if (keys.length === 0) {
      setVariants([]);
      return;
    }

    const combinations = keys.reduce((acc, key) => {
      if (!acc.length) {
        return variation[key].map((value) => ({ [key]: value }));
      }
      return acc.flatMap((combination) =>
        variation[key].map((value) => ({
          ...combination,
          [key]: value,
        }))
      );
    }, []);

    const newVariants = combinations.map((combination) => {
      const sku = keys.map((key) => combination[key]).join("-");

      return {
        attributes: combination,
        sku,
        quantity: 1,
        taxModel: "include",
        taxPercent: 0,
        discountType: "percent",
        utsavDiscountType: "percent",
        minOrderQuantity: 1,
        shippingCost: 0,
        isUtsav: false,
        cod: true,
        variantDetails: {},
      };
    });

    setVariants((prevVariants) => {
      const existingVariantsMap = prevVariants.reduce((map, variant) => {
        map[variant.sku] = variant;
        return map;
      }, {});

      const mergedVariants = newVariants.map((newVariant) => {
        const existingVariant = existingVariantsMap[newVariant.sku];
        return existingVariant
          ? { ...newVariant, ...existingVariant }
          : newVariant;
      });

      return mergedVariants;
    });
  };

  const handleVariantChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const newVariants = [...variants];

    if (type === "checkbox") {
      newVariants[index][name] = checked;
    } else {
      newVariants[index][name] = value;
    }

    // Calculate selling price
    if (
      name === "unitPrice" ||
      name === "discount" ||
      name === "discountType"
    ) {
      const { unitPrice, discount, discountType } = newVariants[index];
      newVariants[index].sellingPrice = calculateSellingPrice(
        unitPrice,
        discount,
        discountType
      );
    }
    // Calculate utsav price
    if (
      name === "unitPrice" ||
      name === "discount" ||
      name === "discountType" ||
      name === "utsavDiscountType" ||
      name === "utsavDiscount"
    ) {
      const { sellingPrice, utsavDiscount, utsavDiscountType } =
        newVariants[index];
      newVariants[index].utsavPrice = calculateUtsavPrice(
        sellingPrice,
        utsavDiscount,
        utsavDiscountType
      );
    }

    setVariants(newVariants);
  };
const handleDuplicateFromPrevious = (index, event) => {
  const isChecked = event.target.checked;
  setDuplicateCheckboxes((prev) => {
    const newCheckboxes = [...prev];
    newCheckboxes[index] = isChecked;
    return newCheckboxes;
  });

  if (isChecked) {
    setVariants((prevVariants) => {
      const newVariants = [...prevVariants];
      const previousVariant = prevVariants[index - 1];
      const currentVariant = newVariants[index];

      // Copy each feature detail from the previous variant
      if (previousVariant && previousVariant.variantDetails) {
        currentVariant.variantDetails = {
          ...previousVariant.variantDetails,
        };
      }

      return newVariants;
    });
  }
};
 const handleVariantDetailsChange = (index, event) => {
   const { name, value } = event.target;
   setVariants((prevVariants) => {
     const updatedVariants = [...prevVariants];
     updatedVariants[index].variantDetails = {
       ...updatedVariants[index].variantDetails,
       [name]: value,
     };
     return updatedVariants;
   });
 };

  const handleVariantImageChange = (index, event) => {
    const newFiles = Array.from(event.target.files);
    setVariants((prevVariants) => {
      const updatedVariants = prevVariants.map((variant, idx) => {
        if (idx === index) {
          const updatedImages = variant.images
            ? [...variant.images, ...newFiles]
            : newFiles;
          return { ...variant, images: updatedImages };
        }
        return variant;
      });
      return updatedVariants;
    });
  };

  const removeVariantImage = (variantIndex, imageIndex) => {
    setVariants((prevVariants) => {
      const updatedVariants = prevVariants.map((variant, idx) => {
        if (idx === variantIndex) {
          return {
            ...variant,
            images: variant.images.filter((_, i) => i !== imageIndex),
          };
        }
        return variant;
      });
      return updatedVariants;
    });
  };

  const handleColorImageChange = (index, event) => {
    const newFile = event.target.files[0];
    setVariants((prevVariants) => {
      const updatedVariants = prevVariants.map((variant, idx) => {
        if (idx === index) {
          return { ...variant, colorImage: newFile };
        }
        return variant;
      });
      return updatedVariants;
    });
  };

  const removeColorImage = (index) => {
    setVariants((prevVariants) => {
      const updatedVariants = prevVariants.map((variant, idx) => {
        if (idx === index) {
          return { ...variant, colorImage: null };
        }
        return variant;
      });
      return updatedVariants;
    });
  };

  const saveVariant = (index) => {
    const selectedVariant = variants[index];
    const identity = selectedVariant.sku;
    const errors = [];

    // Validate selectedVariant fields
    if (!selectedVariant.purchasePrice) {
      errors.push(`Purchase Price is required for ${identity}`);
    }
    if (!selectedVariant.unitPrice) {
      errors.push(`Unit Price is required for ${identity}`);
    }
    if (!selectedVariant.discountType) {
      errors.push(`Discount type is required for ${identity}`);
    }
    if (selectedVariant.discount < 0) {
      errors.push(`Discount should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.utsavDiscountType) {
      errors.push(`Utsav discount type is required for ${identity}`);
    }
    if (selectedVariant.utsavDiscount < 0) {
      errors.push(`Utsav discount should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.taxModel) {
      errors.push(`Tax model is required for ${identity}`);
    }
    if (selectedVariant.taxPercent < 0) {
      errors.push(`Tax percent should not be less than 0 for ${identity}`);
    }
    if (selectedVariant.minOrderQuantity < 1) {
      errors.push(
        `Minimum order quantity should not be less than 1 for ${identity}`
      );
    }
    if (selectedVariant.shippingCost < 0) {
      errors.push(`Shipping cost should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.basicReward) {
      errors.push(`Reward is required for ${identity}`);
    }
    if (!selectedVariant.utsavReward) {
      errors.push(`Utsav reward is required for ${identity}`);
    }
    if (!identity) {
      errors.push(`SKU is required for ${identity}`);
    }
    if (!selectedVariant.barCode) {
      errors.push(`Barcode is required for ${identity}`);
    }
    if (selectedVariant.quantity < 0) {
      errors.push(`Quantity should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.images || selectedVariant.images.length === 0) {
      errors.push(`Please upload at least one image for ${identity}`);
    }

    // If there are errors, display them and return
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    // console.log(selectedVariant);
    editVariant(selectedVariant._id, { ...selectedVariant, productId });
  };

  const createVariant = (index) => {
    const selectedVariant = variants[index];
    const identity = selectedVariant.sku;
    const errors = [];

    // Validate selectedVariant fields
    if (!selectedVariant.purchasePrice) {
      errors.push(`Purchase Price is required for ${identity}`);
    }
    if (!selectedVariant.unitPrice) {
      errors.push(`Unit Price is required for ${identity}`);
    }
    if (!selectedVariant.discountType) {
      errors.push(`Discount type is required for ${identity}`);
    }
    if (selectedVariant.discount < 0) {
      errors.push(`Discount should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.utsavDiscountType) {
      errors.push(`Utsav discount type is required for ${identity}`);
    }
    if (selectedVariant.utsavDiscount < 0) {
      errors.push(`Utsav discount should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.taxModel) {
      errors.push(`Tax model is required for ${identity}`);
    }
    if (selectedVariant.taxPercent < 0) {
      errors.push(`Tax percent should not be less than 0 for ${identity}`);
    }
    if (selectedVariant.minOrderQuantity < 1) {
      errors.push(
        `Minimum order quantity should not be less than 1 for ${identity}`
      );
    }
    if (selectedVariant.shippingCost < 0) {
      errors.push(`Shipping cost should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.basicReward) {
      errors.push(`Reward is required for ${identity}`);
    }
    if (!selectedVariant.utsavReward) {
      errors.push(`Utsav reward is required for ${identity}`);
    }
    if (!identity) {
      errors.push(`SKU is required for ${identity}`);
    }
    if (!selectedVariant.barCode) {
      errors.push(`Barcode is required for ${identity}`);
    }
    if (selectedVariant.quantity < 0) {
      errors.push(`Quantity should not be less than 0 for ${identity}`);
    }
    if (!selectedVariant.images || selectedVariant.images.length === 0) {
      errors.push(`Please upload at least one image for ${identity}`);
    }

    // If there are errors, display them and return
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    addVariant({ ...selectedVariant, productId, variation });
  };

  useEffect(() => {
    generateVariants();
    // eslint-disable-next-line
  }, [variation]);

  const handleOtherImages = (event) => {
    const newFiles = Array.from(event.target.files);
    setOtherImages((prevImages) => [...prevImages, ...newFiles]);
  };

  const removeOtherImage = (index) => {
    setOtherImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const calculateTotalQuantity = () => {
    let total = 0;
    variants.forEach((variant) => {
      const quantity = parseInt(variant.quantity, 10);
      if (!isNaN(quantity)) {
        total += quantity;
      }
    });
    return total;
  };

  useEffect(() => {
    const totalQuantity = calculateTotalQuantity();
    setFormData((prevFormData) => ({
      ...prevFormData,
      totalQuantity,
    }));
    // eslint-disable-next-line
  }, [variants]);

  const calculateSellingPrice = (unitPrice, discount, discountType) => {
    const validUnitPrice = parseFloat(unitPrice) || 0;
    const validDiscount = parseFloat(discount) || 0;

    if (discountType === "percent") {
      const sellingPrice =
        validUnitPrice - (validUnitPrice * validDiscount) / 100;
      return Math.round(sellingPrice);
    } else if (discountType === "flat") {
      const sellingPrice = validUnitPrice - validDiscount;
      return Math.round(sellingPrice);
    }
    return Math.round(validUnitPrice);
  };

  const calculateUtsavPrice = (
    sellingPrice,
    utsavDiscount,
    utsavDiscountType
  ) => {
    const validSellingPrice = parseFloat(sellingPrice) || 0;
    const validUtsavDiscount = parseFloat(utsavDiscount) || 0;

    if (utsavDiscountType === "percent") {
      const utsavPrice =
        validSellingPrice - (validSellingPrice * validUtsavDiscount) / 100;
      return Math.round(utsavPrice);
    } else if (utsavDiscountType === "flat") {
      const utsavPrice = validSellingPrice - validUtsavDiscount;
      return Math.round(utsavPrice);
    }
    return Math.round(validSellingPrice);
  };

  const generateRandomCode = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const generateProductCode = () => {
    const randomCode = generateRandomCode();
    setFormData({ ...formData, productCode: randomCode });
  };

  const generateBarCode = (index) => {
    const randomCode = generateRandomCode();
    const newVariants = [...variants];
    newVariants[index].barCode = randomCode;
    setVariants(newVariants);
  };

  const {
    data: categories,
    isLoading: categoriesLoading,
    // error: categoriesError,
  } = useCategories();

  const {
    data: subcategories,
    isLoading: subcategoriesLoading,
    // error: subcategoriesError,
  } = useSubcategoriesByCategory(formData.category);

  const {
    data: productTypes,
    isLoading: productTypesLoading,
    // error: productTypesError,
  } = useProductTypesBySubcategory(formData.subCategory);

  const { data: selectedProductType, isLoading: selectedProductTypeLoading } =
    useProductTypeById(formData.productType);

  const { data: brands, isLoading: brandsLoading } = useBrands();

  if (isLoading || !product) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          Loading...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full">
        <h2 className="text-center font-secondary font-medium text-xl mt-8">
          {error.message}
        </h2>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-4">
      <h1 className="text-3xl font-medium">Edit Product</h1>

      <div className="GENERAL-DETAILS bg-white p-8 rounded-xl shadow-pop">
        <h2 className="text-mainBlue text-lg">General Details</h2>

        <div className="mt-4 space-y-3">
          <InputBox
            label="product name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <CheckBox
            label="has expiry"
            name="hasExpiry"
            checked={formData.hasExpiry}
            onChange={handleChange}
          />
          <CheckBox
            label="is expiry product saleable"
            name="isExpirySaleable"
            checked={formData.isExpirySaleable}
            onChange={handleChange}
            disabled={formData.hasExpiry === false}
          />
          <CheckBox
            label="is customizable"
            name="isCustomizable"
            checked={formData.isCustomizable}
            onChange={handleChange}
          />
          <CheckBox
            label="has color shade"
            name="hasColorShade"
            checked={formData.hasColorShade}
            onChange={handleChange}
          />
          <div className="grid grid-cols-3 gap-x-16 gap-y-2">
            <SelectBox
              label="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              options={categories.map((category) => ({
                label: category.name,
                value: category._id,
              }))}
              disabled={categoriesLoading}
              searchable
            />
            <SelectBox
              label="sub category"
              name="subCategory"
              value={formData.subCategory}
              onChange={handleChange}
              options={subcategories.map((subcategory) => ({
                label: subcategory.name,
                value: subcategory._id,
              }))}
              disabled={!formData.category || subcategoriesLoading}
              searchable
            />
            <SelectBox
              label="product type"
              name="productType"
              value={formData.productType}
              onChange={handleChange}
              options={productTypes.map((productType) => ({
                label: productType.name,
                value: productType._id,
              }))}
              disabled={!formData.subCategory || productTypesLoading}
              searchable
            />
            <SelectBox
              label="brand"
              name="brand"
              value={formData.brand}
              onChange={handleChange}
              options={brands.map((brand) => ({
                label: brand.name,
                value: brand._id,
              }))}
              disabled={brandsLoading}
              searchable
            />
            <SelectBox
              label="unit"
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              options={[
                { label: "mg", value: "mg" },
                { label: "gram", value: "gram" },
                { label: "kg", value: "kg" },
                { label: "ml", value: "ml" },
                { label: "litre", value: "litre" },
                { label: "piece", value: "piece" },
              ]}
            />
            <InputBox
              type="number"
              label="total quantity"
              name="totalQuantity"
              value={formData.totalQuantity}
              readOnly
            />
            <div className="relative">
              <InputBox
                label="product code"
                name="productCode"
                value={formData.productCode}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={generateProductCode}
                className="absolute top-1 right-0"
              >
                <p className="text-xs text-mainBlue">Generate Code</p>
              </button>
            </div>
          </div>
          <TextEditor
            label="description"
            value={description}
            onChange={(value) => setDescription(value)}
          />
        </div>
      </div>

      <div className="MEDIA bg-white p-8 rounded-xl shadow-pop">
        <h2 className="text-mainBlue text-lg">Media</h2>
        <div className="mt-4 flex items-start gap-32">
          <div className="flex items-end gap-8 flex-wrap">
            {!formData.thumbnail ? (
              <FileInputBox
                label="upload thumbnail (ratio 1:1)"
                name="thumbnail"
                onChange={handleChange}
                align="top"
                className="size-80 p-2 border-2 border-dashed border-secondary rounded-md"
              >
                <div className="bg-offWhite size-full p-8 flex flex-col items-center justify-center">
                  <MdCloudUpload size={48} className="text-primary" />
                  <p className="text-sm font-medium text-primary text-center my-4">
                    Drag & drop or click here to upload image
                  </p>
                  <p className="text-xs text-primary text-center">
                    (Supports .jpg, .jpeg, .png)
                  </p>
                </div>
              </FileInputBox>
            ) : (
              <div className="relative size-80 p-2 border-2 border-dashed border-secondary rounded-md">
                <img
                  src={
                    typeof formData.thumbnail[0] === "string"
                      ? formData.thumbnail[0]
                      : URL.createObjectURL(formData.thumbnail[0])
                  }
                  alt="thumbnail"
                  className="size-full object-contain object-center"
                />
                <button
                  type="button"
                  className="absolute top-2 right-2 bg-white rounded-full p-1"
                  onClick={() => setFormData({ ...formData, thumbnail: null })}
                >
                  <FaTrash className="text-primeRed" />
                </button>
              </div>
            )}
          </div>

          <div className="flex items-end gap-8 flex-wrap">
            <FileInputBox
              label="upload other images (ratio 1:1)"
              onChange={handleOtherImages}
              align="top"
              multiple
              className="size-80 p-2 border-2 border-dashed border-secondary rounded-md"
              containerClassName="gap-2"
            >
              <div className="bg-offWhite size-full p-8 flex flex-col items-center justify-center">
                <MdCloudUpload size={48} className="text-primary" />
                <p className="text-sm font-medium text-primary text-center my-4">
                  Drag & drop or click here to upload image
                </p>
                <p className="text-xs text-primary text-center">
                  (Supports .jpg, .jpeg, .png)
                </p>
              </div>
            </FileInputBox>
            {otherImages.map((img, index) => (
              <div
                key={index}
                className="relative size-80 p-2 border-2 border-dashed border-secondary rounded-md"
              >
                <img
                  src={typeof img === "string" ? img : URL.createObjectURL(img)}
                  alt={index}
                  className="size-full object-contain object-center"
                />
                <button
                  type="button"
                  className="absolute top-2 right-2 bg-white rounded-full p-1"
                  onClick={() => removeOtherImage(index)}
                >
                  <FaTrash className="text-primeRed" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex gap-8 justify-end">
        <Button type="button" onClick={clearForm} reverse>
          Reset
        </Button>
        <Button>update</Button>
      </div>

      <h1 className="text-3xl font-medium">Edit Variants</h1>

      <div className="VARIATION bg-white p-8 rounded-xl shadow-pop">
        <h2 className="text-mainBlue text-lg">Variation</h2>

        <div className="mt-4 space-y-3">
          <div className="grid grid-cols-2 gap-x-16">
            <SelectBox
              label="variation attributes"
              name="variationAttributes"
              value={formData.variationAttributes}
              onChange={handleChangeAndClearVariation}
              options={[
                { label: "color", value: "color" },
                { label: "size", value: "size" },
                { label: "storage", value: "storage" },
                { label: "RAM", value: "RAM" },
                { label: "battery", value: "battery" },
                { label: "display", value: "display" },
                { label: "weight", value: "weight" },
              ]}
              disabled
              multi
            />
          </div>
          <div className="grid grid-cols-2 gap-x-16 gap-y-2">
            {formData.variationAttributes?.map((variant, index) => (
              <TagsInput
                key={`${variant}-${index}`}
                label={variant}
                name={variant}
                value={variation[variant]}
                onChange={handleVariationChange}
              />
            ))}
          </div>
        </div>
      </div>

      {variants?.map((variant, index) => (
        <div
          key={index}
          className="VARIANT-FORM relative bg-white p-8 rounded-xl shadow-pop space-y-8"
        >
          <div className="HEADING flex items-center gap-4 text-xl font-medium">
            {Object.entries(variant.attributes).map(([key, value]) => (
              <p key={key}>
                {key}: {value}
              </p>
            ))}
          </div>

          <div className="space-y-3">
            <ToggleSwitch
              label="utsav"
              name="isUtsav"
              onChange={(e) => handleVariantChange(index, e)}
              checked={variant.isUtsav}
            />
            <ToggleSwitch
              label="cash on delivery"
              name="cod"
              onChange={(e) => handleVariantChange(index, e)}
              checked={variant.cod}
            />
            {!formData.hasColorShade ? null : !variant.colorImage ? (
              <FileInputBox
                label="upload color image (ratio 1:1)"
                name="colorImage"
                onChange={(e) => handleColorImageChange(index, e)}
                align="top"
                className="size-20 p-1 border-2 border-dashed border-secondary rounded"
              >
                <div className="bg-offWhite size-full p-1 flex flex-col items-center justify-center">
                  <MdCloudUpload size={24} className="text-primary" />
                </div>
              </FileInputBox>
            ) : (
              <div className="relative size-20 p-1 border-2 border-dashed border-secondary rounded">
                <img
                  src={
                    typeof variant?.colorImage === "string"
                      ? variant.colorImage
                      : URL.createObjectURL(variant.colorImage)
                  }
                  alt={`product-${index}`}
                  className="size-full object-contain object-center"
                />
                <button
                  type="button"
                  className="absolute top-2 right-2 bg-white rounded-full p-1"
                  onClick={() => removeColorImage(index)}
                >
                  <FaTrash size={8} className="text-primeRed" />
                </button>
              </div>
            )}
          </div>

          <div className="GENERAL-INFORMATION space-y-4">
            <h2 className="text-mainBlue text-lg">General Information</h2>

            <InputBox
              label="title"
              name="name"
              value={variant.name}
              onChange={(e) => handleVariantChange(index, e)}
            />
            {formData.hasExpiry && (
              <InputBox
                type="date"
                label="expiry date"
                name="expiryDate"
                value={variant.expiryDate}
                onChange={(e) => handleVariantChange(index, e)}
                containerClassName="w-60 gap-2"
              />
            )}
          </div>

          <div className="VARIANT-PRICING-DETAILS">
            <h2 className="text-mainBlue text-lg">Pricing Details</h2>

            <div className="mt-4 space-y-3">
              <div className="grid grid-cols-4 gap-x-16 gap-y-2">
                <InputBox
                  type="number"
                  label="purchase price"
                  name="purchasePrice"
                  value={variant.purchasePrice}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <InputBox
                  type="number"
                  label="unit price"
                  name="unitPrice"
                  value={variant.unitPrice}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <InputBox
                  type="number"
                  label="selling price"
                  name="sellingPrice"
                  value={variant.sellingPrice}
                  onChange={(e) => handleVariantChange(index, e)}
                  readOnly
                />
                <InputBox
                  type="number"
                  label="utsav price"
                  name="utsavPrice"
                  value={variant.utsavPrice}
                  onChange={(e) => handleVariantChange(index, e)}
                  readOnly
                />
                <SelectBox
                  label="discount type"
                  name="discountType"
                  value={variant.discountType}
                  onChange={(e) => handleVariantChange(index, e)}
                  options={[
                    { label: "Flat", value: "flat" },
                    { label: "Percent", value: "percent" },
                  ]}
                />
                <InputBox
                  type="number"
                  label="discount"
                  name="discount"
                  value={variant.discount}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <SelectBox
                  label="utsav discount type"
                  name="utsavDiscountType"
                  value={variant.utsavDiscountType}
                  onChange={(e) => handleVariantChange(index, e)}
                  options={[
                    { label: "Flat", value: "flat" },
                    { label: "Percent", value: "percent" },
                  ]}
                />
                <InputBox
                  type="number"
                  label="utsav discount"
                  name="utsavDiscount"
                  value={variant.utsavDiscount}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <SelectBox
                  label="tax model"
                  name="taxModel"
                  value={variant.taxModel}
                  onChange={(e) => handleVariantChange(index, e)}
                  options={[
                    { label: "Include", value: "include" },
                    { label: "Exclude", value: "exclude" },
                  ]}
                />
                <InputBox
                  type="number"
                  label="tax percent (%)"
                  name="taxPercent"
                  value={variant.taxPercent}
                  onChange={(e) => handleVariantChange(index, e)}
                  disabled={!variant.taxModel}
                />
                <InputBox
                  type="number"
                  label="minimum order quantity"
                  name="minOrderQuantity"
                  value={variant.minOrderQuantity}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <InputBox
                  type="number"
                  label="shipping cost"
                  name="shippingCost"
                  value={variant.shippingCost}
                  onChange={(e) => handleVariantChange(index, e)}
                />
              </div>
            </div>
          </div>

          <div className="VARIANT-REWARDS">
            <h2 className="text-mainBlue text-lg">Rewards</h2>

            <div className="mt-4 space-y-3">
              <div className="grid grid-cols-3 gap-x-16 gap-y-2">
                <InputBox
                  type="number"
                  label="reward (FLAT)"
                  placeholder="Enter basic reward amount"
                  name="basicReward"
                  value={variant.basicReward}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <InputBox
                  type="number"
                  label="utsav reward (FLAT)"
                  placeholder="Enter utsav reward amount"
                  name="utsavReward"
                  value={variant.utsavReward}
                  onChange={(e) => handleVariantChange(index, e)}
                />
              </div>
            </div>
          </div>

          <div className="VARIANT-INVENTORY">
            <h2 className="text-mainBlue text-lg">Inventory</h2>

            <div className="mt-4 space-y-3">
              <div className="grid grid-cols-3 gap-x-16 gap-y-2">
                <InputBox
                  label="SKU"
                  name="sku"
                  value={variant.sku}
                  onChange={(e) => handleVariantChange(index, e)}
                />
                <div className="BARCODE relative">
                  <InputBox
                    label="barcode"
                    name="barCode"
                    value={variant.barCode}
                    onChange={(e) => handleVariantChange(index, e)}
                  />
                  <button
                    type="button"
                    onClick={() => generateBarCode(index)}
                    className="absolute top-1 right-0"
                  >
                    <p className="text-xs text-mainBlue">Generate Code</p>
                  </button>
                </div>
                <InputBox
                  type="number"
                  label="quantity"
                  name="quantity"
                  value={variant.quantity}
                  onChange={(e) => handleVariantChange(index, e)}
                />
              </div>
            </div>
          </div>

          {selectedProductTypeLoading ? null : !selectedProductType ||
            selectedProductType?.variationFeatures?.length === 0 ? null : (
            <div className="VARIANT-SPECIFICATIONS">
              <h2 className="text-mainBlue text-lg">Specifications</h2>
              {index > 0 && (
                <div className="mt-2">
                  <CheckBox
                    label="Duplicate previous variant details"
                    checked={duplicateCheckboxes[index]}
                    onChange={(e) => handleDuplicateFromPrevious(index, e)}
                  />
                </div>
              )}
              <div className="mt-4 space-y-3">
                <div className="grid grid-cols-3 gap-x-16 gap-y-2">
                  {selectedProductType?.variationFeatures?.map((feature) => (
                    <InputBox
                      key={feature}
                      label={feature}
                      name={feature}
                      value={variants[index]?.variantDetails?.[feature]}
                      onChange={(e) => handleVariantDetailsChange(index, e)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="VARIANT-MEDIA">
            <h2 className="text-mainBlue text-lg">Media</h2>

            <div className="mt-4 flex items-end gap-8 flex-wrap">
              <FileInputBox
                label="upload images (ratio 1:1)"
                onChange={(e) => handleVariantImageChange(index, e)}
                align="top"
                multiple
                className="size-80 p-2 border-2 border-dashed border-secondary rounded-md"
              >
                <div className="bg-offWhite size-full p-8 flex flex-col items-center justify-center">
                  <MdCloudUpload size={48} className="text-primary" />
                  <p className="text-sm font-medium text-primary text-center my-4">
                    Drag & drop or click here to upload image
                  </p>
                  <p className="text-xs text-primary text-center">
                    (Supports .jpg, .jpeg, .png)
                  </p>
                </div>
              </FileInputBox>
              {variant.images?.map((img, imgIndex) => (
                <div
                  key={imgIndex}
                  className="relative size-80 p-2 border-2 border-dashed border-secondary rounded-md"
                >
                  <img
                    src={
                      typeof img === "string" ? img : URL.createObjectURL(img)
                    }
                    alt={`product-${imgIndex}`}
                    className="size-full object-contain object-center"
                  />
                  <button
                    type="button"
                    className="absolute top-2 right-2 bg-white rounded-full p-1"
                    onClick={() => removeVariantImage(index, imgIndex)}
                  >
                    <FaTrash className="text-primeRed" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {variant._id ? (
            <Button
              type="button"
              reverse
              onClick={() => saveVariant(index)}
              className="absolute top-0 right-8 px-4 py-2 rounded-lg"
            >
              save
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => createVariant(index)}
              className="absolute top-0 right-8 px-4 py-2 rounded-lg"
            >
              create
            </Button>
          )}
        </div>
      ))}
    </form>
  );
};

export default EditProduct;
